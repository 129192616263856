// import { ANOTATION } from '../edit/annotation';
// import {Config} from '../define/AppConfig';
// import { Convert } from '.././../convert';
// import {PdfHistory, TYPE_HISTORY, TYPE_OBJECT} from '../edit/undo_redo'
// export const TextHelper = {   
//     addHightLight(selection,pdfViewer){
//         if (selection.toString().length>0) {

//           var range = selection.getRangeAt(0).cloneRange();
//           //https://stackoverflow.com/questions/667951/how-to-get-nodes-lying-inside-a-range-with-javascript
//           var nodes = range.cloneContents().querySelectorAll("*");
//           var elem;
//           if(nodes.length>0){
//             for(var i=0;i<nodes.length;i++)
//             {
//               elem = getElemOrigin(nodes[i]);
//               if(elem && elem.tagName == "SPAN"){
//                 addElemeHightLight(nodes[i],i==0,i==nodes.length-1,elem,pdfViewer);
//                 // addSingleElemeHightLight(nodes[i],pdfViewer,elem);
//               }
//             }  
//           }   
//           else{
//             var elem = range.startContainer.parentElement;
//             addSingleElemeHightLight(range,pdfViewer,elem);
//           }
//         }
//     }
// }
// function addSingleElemeHightLight(range,pdfViewer,elem){     
//     var currentPageDiv = elem.closest('.page');
//     var pageIndex = parseInt(currentPageDiv.getAttribute("data-page-number")); 
//     var page = pdfViewer.file.pages[pageIndex-1];
//     var rotate = (page.rotate + page.viewRotate) % 360;  

//     var annotationLayer = currentPageDiv.querySelector(".annotationLayer");  
//     var div = document.createElement('div');
//     div.className = "annotation-added";  
//     // div.setAttribute("style", elem.style);
//     div.setAttribute("kind", pdfViewer.current_anotation);
//     div.style.position = "absolute";         
//     var rectangleSelection = range.getBoundingClientRect();
//     var rectanglePage = annotationLayer.getBoundingClientRect();
//     var bound = calculateBounded(rectangleSelection,rectanglePage,rotate);  
//     div.style.top = bound.y + "px";
//     div.style.left = bound.x + "px";
//     div.style.width = bound.width + "px";
//     div.style.height = bound.height + "px";
//     setStyle(pdfViewer.current_anotation,div);  
//     div.setAttribute('id-object',random_string(10));
//     div.id = random_string(10);
//     pdfViewer.addHistory(new PdfHistory(pageIndex,pdfViewer.current_anotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,div));  
//     annotationLayer.append(div);
//     div.addEventListener("click", show_tool_text_format);
//     div.addEventListener("focusout", hide_tool_text_format);
// }


// function show_tool_text_format(e){
//   var element = e.target; 
//   $("#text-anno-format").css("display","block");
//   var currentPageDiv = element.parentElement.parentNode;
//   var page = currentPageDiv.querySelector(".textLayer");
//   var rect = page.getBoundingClientRect();

//   $("#text-anno-format").css("left",rect.left + parseFloat(element.style.left) + "px");
//   $("#text-anno-format").css("top",rect.top + parseFloat(element.style.top) - $("#text-anno-format").height() + "px");
//   setDisplayToolTextFormat(element);
//  }

//  function hide_tool_text_format(){
//   $("#text-anno-format").css("display","none"); 
//  }

//  function setDisplayToolTextFormat(obj){
//   document.getElementById("text-format-color").value = Convert.rgbToHex( obj.style.backgroundColor);
// }
// function addElemeHightLight(node,first,end,elem,pdfViewer){  
//     if(node.tagName != "SPAN") return;
    
//     var currentPageDiv = elem.closest('.page');
//     var annotationLayer = currentPageDiv.querySelector(".annotationLayer");
//     var rectPage = annotationLayer.getBoundingClientRect();
//     var div = document.createElement('div');
//     var x,y,width,height;
//     var pageIndex = parseInt(currentPageDiv.getAttribute("data-page-number")) ; 
//     var page = pdfViewer.file.pages[pageIndex-1]; 
//     var rotate = (page.rotate + page.viewRotate) % 360;  
//     annotationLayer.append(node); 
//     div.className = "annotation-added";
//     // div.setAttribute("style", elem.style);
//     div.setAttribute("kind", pdfViewer.current_anotation);
//     div.style.position = "absolute";   
  
//     div.style.opacity = 0.3;
    
//     // if(node.textContent){
//     //   node.textContent = node.textContent.trim();
//     // }
   
//     var infor = getInforNode(node,rectPage,elem,rotate,pdfViewer,first,end);
//     x = infor.x;
//     y = infor.y;
//     width = infor.width;
//     height = infor.height;
//     var bound = calculateByRotation(rectPage,rotate,x,y,width,height);
//     div.style.top = Math.abs(bound.y) + "px";
//     div.style.left = Math.abs(bound.x) + "px";
//     div.style.width = Math.abs(bound.width) + "px";
//     div.style.height = Math.abs(bound.height) + "px";
//     setStyle(pdfViewer.current_anotation,div);
//     div.setAttribute('id-object',random_string(10));
//     div.id = random_string(10);
//     pdfViewer.addHistory(new PdfHistory(pageIndex,pdfViewer.current_anotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,div));  
//     annotationLayer.append(div);
    
//     div.addEventListener("click", show_tool_text_format);
//     div.addEventListener("focusout", hide_tool_text_format);
//     node.remove();
//    }
//    function getInforNode(node,rectPage,elem,rotate,pdfViewer,first,end){
//     var x,y,width,height,rect;
//     switch(rotate){
//       case 0:
//       case 360:
//         if(first){
//           x = pdfViewer.pointMouseDown.clientX - rectPage.x;
//         }
//         else{
//           x = parseFloat(node.style.left);
//         }
//         rect = getBoundingRect(elem);
//         if(end){
//           width = pdfViewer.pointMouseUp.clientX - rectPage.x - parseInt(node.style.left);
//         }
//         else{      
//           if(first){
//             width = parseFloat(elem.style.left) +  rect.width  - x;
//           }
//           else{
//             width = rect.width;
//           }   
//         }   
        
//         y = parseFloat(node.style.top); 
//         height = elem.getBoundingClientRect().height;      
//         break;
//       case 90:
//       case -270:
//         rect = getBoundingRect(elem);
//         if(first){
//           y = Math.abs(pdfViewer.pointMouseDown.clientY - rectPage.y) ;
//         }
//         else{
//           y = parseFloat(node.style.top) ;
//         }
        
//         if(end){
//           height = pdfViewer.pointMouseUp.clientY - rectPage.y - parseInt(node.style.top);
//         }
//         else{      
//           if(first){
//             height = parseFloat(elem.style.top) + rect.height  - y;
//           }
//           else{
//             height = rect.height;
//           }   
//         }   
        
//         x = parseFloat(node.style.left); 
//         width = elem.getBoundingClientRect().width;
//         y-= width;
//         break;
//       case 180:
//       case -180: 
//         rect = getBoundingRect(elem);
//         if(first){
//           x =  pdfViewer.pointMouseUp.clientX -  rectPage.x;
          
//         }
//         else{
//           x =  parseFloat(node.style.left);
//         }
//         x = rectPage.width - x;
//         rect = getBoundingRect(elem);
//         if(end){
//           width = pdfViewer.pointMouseDown.clientX - rectPage.x - parseFloat(node.style.left);
//         }
//         else{      
//           if(first){
//             width =  rect.width  - x + Math.abs(rect.right - rectPage.right              );//rect.width - x  + rect.left;
//           }
//           else{
//             width = rect.width ;
//           }   
//         }   
        
//         y = rectPage.height -  parseFloat(node.style.top); 
      
//         height = elem.getBoundingClientRect().height;   
//         break;
//       case 270:
//       case -90:       
//       rect = getBoundingRect(elem);
//       if(first){
//         y = Math.abs(pdfViewer.pointMouseDown.clientY - rectPage.y) ;
//       }
//       else{
//         y = parseFloat(node.style.top) ;
//       }
      
//       if(end){
//         height = pdfViewer.pointMouseUp.clientY - rectPage.y - parseInt(node.style.top);
//       }
//       else{      
//         if(first){
//           height = parseFloat(elem.style.top) + rect.height  - y;
//         }
//         else{
//           height = rect.height;
//         }   
//       }   
      
//       x = parseFloat(node.style.left); 
//       width = elem.getBoundingClientRect().width;
//       y-= width;
//         break;
//     }
//     return {x:x,y:y,width:width,height:height}
//    }
//    function calculateBounded(rectangleSelection,rectanglePage,rotate){
//     var x,y,width,height;
//     switch(rotate){
//       case 0:
//       case 360:
//         x = rectangleSelection.left  - rectanglePage.x;
//         y = rectangleSelection.top - rectanglePage.y;
//         width = rectangleSelection.width;
//         height = rectangleSelection.height;
//         break;
//       case 90:
//       case -270:
//         y = rectanglePage.width - (rectangleSelection.left  - rectanglePage.x) - rectangleSelection.width;
//         x = rectangleSelection.top - rectanglePage.y ;
//         width = rectangleSelection.height;
//         height = rectangleSelection.width;
//         break;
//       case 180:
//       case -180:
//         x = rectanglePage.width - (rectangleSelection.left  - rectanglePage.x) - rectangleSelection.width;
//         y = rectanglePage.height - (rectangleSelection.top - rectanglePage.y) - rectangleSelection.height;
//         width = rectangleSelection.width;
//         height = rectangleSelection.height;
//         break;
//       case 270:
//       case -90:  
//         y = (rectangleSelection.left  - rectanglePage.x) ;
//         x = rectanglePage.height - (rectangleSelection.top - rectanglePage.y) - rectangleSelection.height ;
//         width = rectangleSelection.height;
//         height = rectangleSelection.width;
//         break;
//     }
//     return {x:x,y:y,width:width,height:height}
//   }
//    function calculateByRotation(rectPage,rotate,x_input,y_input,width_input,height_input){
//     var x, y, width,height;    
//     switch(rotate){
//       case 0:
//       case 360:
//         x = x_input ;
//         y = y_input;
//         width = width_input;
//         height = height_input;
//         break;
//       case 90:
//       case -270:
//         x = y_input + width_input;
//         y = rectPage.width - x_input;
//         width = height_input;
//         height = width_input;
//         break;
//       case 180:
//       case -180:
//         x = x_input ;
//         y = y_input;
//         width = width_input;
//         height = height_input;
//         break;
//       case 270:
//       case -90:  
//         x = x_input ;
//         y = x_input;
//         width = width_input;
//         height = height_input;
//         break;
//     }
//     return {x:x,y:y,width:width,height:height}
//    }
//    function setStyle(current_anotation,div){
//     if(current_anotation == ANOTATION.TEXT_HIGHLIGHT){
//         div.style.opacity = 0.3;
//         div.style.backgroundColor = Config.text.highlight.color;
//         div.setAttribute('name','highlight');
//       }
//       if(current_anotation == ANOTATION.TEXT_UNDERLINE){
//         div.style.height = pdfViewer.currentScaleValue + "px";
//         // div.style.top = bound.y + (elem.getBoundingClientRect().height - parseFloat(div.style.height)) + "px";   
//         div.style.opacity = 1;  
//         div.style.backgroundColor = Config.text.underline.color;
//         div.setAttribute('name','underline');
//       }
//       if(current_anotation == ANOTATION.TEXT_STROKEOUT){
//         // div.style.height = pdfViewer.currentScaleValue + "px";
//         // div.style.top = parseFloat(elem.style.top) + (elem.getBoundingClientRect().height - parseFloat(div.style.height))/2 + "px";   
//         div.style.opacity = 1;  
//         div.style.backgroundColor = Config.text.strokeout.color;
//         div.setAttribute('name','strokeout');
//       }
//    }


//    function getElemOrigin(node){
//     var listChildSpan = document.body.querySelectorAll("span");    
//     for (let index = 0; index < listChildSpan.length; index++) {
//       const element = listChildSpan[index];
//       if(parseInt(element.style.left) == parseInt(node.style.left) && parseInt(element.style.top) == parseInt(node.style.top) && element.textContent.includes(node.textContent))
//       {
//         return element;
//       }
//     }
//     return null;
//   }

//   function getBoundingRect(element) {
//     var style = window.getComputedStyle(element); 
//     var margin = {
//         left: parseInt(style["margin-left"]),
//         right: parseInt(style["margin-right"]),
//         top: parseInt(style["margin-top"]),
//         bottom: parseInt(style["margin-bottom"])
//     };
//     var padding = {
//         left: parseInt(style["padding-left"]),
//         right: parseInt(style["padding-right"]),
//         top: parseInt(style["padding-top"]),
//         bottom: parseInt(style["padding-bottom"])
//     };
//     var border = {
//         left: parseInt(style["border-left"]),
//         right: parseInt(style["border-right"]),
//         top: parseInt(style["border-top"]),
//         bottom: parseInt(style["border-bottom"])
//     };    
    
//     var rect = element.getBoundingClientRect();
//     rect = {
//         left: rect.left - margin.left,
//         right: rect.right - margin.right - padding.left - padding.right,
//         top: rect.top - margin.top,
//         bottom: rect.bottom - margin.bottom - padding.top - padding.bottom - border.bottom  
//     };
//     rect.width = rect.right - rect.left;
//     rect.height = rect.bottom - rect.top;
//     return rect;
    
//   };

import { ANOTATION } from '../edit/annotation';
import {Config} from '../define/AppConfig';
import { Convert } from '.././../convert';
import {PdfHistory, TYPE_HISTORY, TYPE_OBJECT} from '../edit/undo_redo'
export const TextHelper = {   
    addHightLight(selection,pdfViewer){
        if (selection.toString().length>0) {
          var range = selection.getRangeAt(0).cloneRange();
          //https://stackoverflow.com/questions/667951/how-to-get-nodes-lying-inside-a-range-with-javascript
          var nodes = range.cloneContents().querySelectorAll("*");
          var elem;
          if(nodes.length>0){
            for(var i=0;i<nodes.length;i++)
            {
              elem = getElemOrigin(nodes[i]);
              if(elem){
                addElemeHightLight(nodes[i],i==0,i==nodes.length-1,elem,pdfViewer);
              }
            }  
          }   
          else{
            addSingleElemeHightLight(range.startContainer.parentElement,pdfViewer)
          }
        }
    }
}
function addSingleElemeHightLight(elem,pdfViewer){
    var currentPageDiv = elem.closest('.page');
    
    var annotationLayer = currentPageDiv.querySelector(".annotationLayer");  
    var div = document.createElement('div');
    div.className = "annotation-added";  
    div.setAttribute("style", elem.style);
    div.setAttribute("kind", pdfViewer.current_anotation);
    div.style.position = "absolute";      
    div.style.left = pdfViewer.pointMouseDown.clientX - annotationLayer.getBoundingClientRect().x + "px";  
    div.style.width = pdfViewer.pointMouseUp.clientX - pdfViewer.pointMouseDown.clientX + "px";    
    setStyle(pdfViewer,elem,div);  
    div.setAttribute('id-object',random_string(10));
    div.id = random_string(10);
    pdfViewer.addHistory(new PdfHistory(parseInt(currentPageDiv.getAttribute('data-page-number')),pdfViewer.current_anotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,div));  


    annotationLayer.append(div);

    div.addEventListener("click", show_tool_text_format);
    div.addEventListener("focusout", hide_tool_text_format);
}
function show_tool_text_format(e){
  var element = e.target; 
  $("#text-anno-format").css("display","block");
  var currentPageDiv = element.parentElement.parentNode;
  var page = currentPageDiv.querySelector(".textLayer");
  var rect = page.getBoundingClientRect();

  $("#text-anno-format").css("left",rect.left + parseFloat(element.style.left) + "px");
  $("#text-anno-format").css("top",rect.top + parseFloat(element.style.top) - $("#text-anno-format").height() + "px");
  setDisplayToolTextFormat(element);
 }

 function hide_tool_text_format(){
  $("#text-anno-format").css("display","none"); 
 }

 function setDisplayToolTextFormat(obj){
  document.getElementById("text-format-color").value = Convert.rgbToHex( obj.style.backgroundColor);
}
function addElemeHightLight(node,first,end,elem,pdfViewer){  
    if(node.tagName != "SPAN") return;
    var currentPageDiv = elem.closest('.page');
    var annotationLayer = currentPageDiv.querySelector(".annotationLayer");
    var div = document.createElement('div');
    var rect;
    annotationLayer.append(node); 
    div.className = "annotation-added";
    div.setAttribute("style", elem.style);
    div.setAttribute("kind", pdfViewer.current_anotation);
    div.style.position = "absolute";   
  
    div.style.top = node.style.top; 
    div.style.opacity = 0.3;
    
    if(node.textContent){
      node.textContent = node.textContent.trim();
    }
    if(first){
      div.style.left = pdfViewer.pointMouseDown.clientX - annotationLayer.getBoundingClientRect().x + "px";
    }
    else{
      div.style.left = node.style.left;
    }
    
    if(end){
      div.style.width = pdfViewer.pointMouseUp.clientX - annotationLayer.getBoundingClientRect().x - parseInt(node.style.left) + "px";
    }
    else{
      rect = getBoundingRect(elem);
      if(first){
        div.style.width = parseFloat(elem.style.left) +  rect.width  - parseFloat(div.style.left) + "px";
      }
      else{
        div.style.width = rect.width + "px";
      }   
    }   
    setStyle(pdfViewer,elem,div);
    div.setAttribute('id-object',random_string(10));
    div.id = random_string(10);
    pdfViewer.addHistory(new PdfHistory(parseInt(currentPageDiv.getAttribute('data-page-number')),pdfViewer.current_anotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,div));  
    annotationLayer.append(div);
    
    div.addEventListener("click", show_tool_text_format);
    div.addEventListener("focusout", hide_tool_text_format);
    node.remove();
   }

   function setStyle(pdfViewer,elem,div){
    if(pdfViewer.current_anotation == ANOTATION.TEXT_HIGHLIGHT){
        div.style.top = elem.style.top;   
        div.style.height = elem.getBoundingClientRect().height + "px";
        div.style.opacity = 0.3;
        div.style.backgroundColor = Config.text.highlight.color;
        div.setAttribute('name','highlight');
      }
      if(pdfViewer.current_anotation == ANOTATION.TEXT_UNDERLINE){
        div.style.height = 1*pdfViewer.currentScaleValue + "px";
        div.style.top = parseFloat(elem.style.top) + (elem.getBoundingClientRect().height - parseFloat(div.style.height)) + "px";   
        div.style.opacity = 1;  
        div.style.backgroundColor = Config.text.underline.color;
        div.setAttribute('name','underline');
      }
      if(pdfViewer.current_anotation == ANOTATION.TEXT_STROKEOUT){
        div.style.height = 1*pdfViewer.currentScaleValue + "px";
        div.style.top = parseFloat(elem.style.top) + (elem.getBoundingClientRect().height - parseFloat(div.style.height))/2 + "px";   
        div.style.opacity = 1;  
        div.style.backgroundColor = Config.text.strokeout.color;
        div.setAttribute('name','strokeout');
      }
   }


   function getElemOrigin(node){
    var listChildSpan = document.body.querySelectorAll("span");    
    for (let index = 0; index < listChildSpan.length; index++) {
      const element = listChildSpan[index];
      if(parseInt(element.style.left) == parseInt(node.style.left) && parseInt(element.style.top) == parseInt(node.style.top) && element.textContent.includes(node.textContent))
      {
        return element;
      }
    }
    return null;
  }

  function getBoundingRect(element) {
    var style = window.getComputedStyle(element); 
    var margin = {
        left: parseInt(style["margin-left"]),
        right: parseInt(style["margin-right"]),
        top: parseInt(style["margin-top"]),
        bottom: parseInt(style["margin-bottom"])
    };
    var padding = {
        left: parseInt(style["padding-left"]),
        right: parseInt(style["padding-right"]),
        top: parseInt(style["padding-top"]),
        bottom: parseInt(style["padding-bottom"])
    };
    var border = {
        left: parseInt(style["border-left"]),
        right: parseInt(style["border-right"]),
        top: parseInt(style["border-top"]),
        bottom: parseInt(style["border-bottom"])
    };    
    
    var rect = element.getBoundingClientRect();
    rect = {
        left: rect.left - margin.left,
        right: rect.right - margin.right - padding.left - padding.right,
        top: rect.top - margin.top,
        bottom: rect.bottom - margin.bottom - padding.top - padding.bottom - border.bottom  
    };
    rect.width = rect.right - rect.left;
    rect.height = rect.bottom - rect.top;
    return rect;
    
  };