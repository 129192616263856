// class create Container contain content of file
export class PDFThumnail{
    thumbnailContainer
    eventBus
    constructor(thumnail,evBus){
        this.thumbnailContainer = thumnail
        this.eventBus = evBus
    }
    create(pages){
        this.thumbnailContainer.replaceChildren();
        var li,i;
        for (i = 0; i < pages.length; i++) {  
           // Get desired page     
           li = makeThumbDocs(pages[i],this.eventBus);
           this.thumbnailContainer.appendChild(li);
        }      
    }
    setPageNumber(pageNumber){ 
        this.thumbnailContainer.querySelectorAll('.thumbnailImage').forEach(element => {
            element.classList.remove('selected');
        });
        this.thumbnailContainer.querySelector(`#canvas_thump_${pageNumber-1}`).classList.add('selected');
        this.thumbnailContainer.querySelector(`#canvas_thump_${pageNumber-1}`).scrollIntoViewIfNeeded();
    }

    rotate(page){
        var rotate = page.viewRotate % 360;  
        var canvas = this.thumbnailContainer.querySelector("#canvas_thump_"+ page._pageIndex);
        canvas.style.transform       = 'rotate('+rotate+'deg)'; 
    }
}

function makeThumbDocs(page,eventBus) {
    var li = document.createElement("li");
    li.style="float:none; clear:both;width: 100%;";
    li.id="thumbnail-container";
    li.className= "justify-content-center align-items-center";
    var dv = document.createElement("div");
    dv.id="thumbanil-frame-"+ page._pageIndex;
    dv.className="thumbnail-frame";
    // draw page to fit into 96x96 canvas
    var vp = page.getViewport({scale:1});
    var canvas = create_Thumb(page,eventBus);
    var scale_thum = Math.min(canvas.width / vp.width, canvas.height / vp.height);
    page.render({canvasContext: canvas.getContext("2d"), viewport: page.getViewport({scale:scale_thum})}).promise;

    // var lBel = document.createElement("label");
    // lBel.textContent=(page._pageIndex+1);
    // lBel.style.padding=0;
    // lBel.style.width="100%";
    // lBel.style.textAlign="center";
    // lBel.style.color="green";
    var span = document.createElement("span");
    span.className="thumbnailNumber"
    span.textContent=(page._pageIndex+1);
    dv.appendChild(canvas);
    dv.appendChild(span);
    li.appendChild(dv);
    return li;
  }

  function create_Thumb(page,eventBus)
  { 
      var canvas = document.createElement("canvas");
      canvas.id = "canvas_thump_"+ page._pageIndex ;
      canvas.className="thumbnailImage";
      var viewPort = page.getViewport({scale:0.5})
      var scale_thum = Math.min(canvas.width / viewPort.width, canvas.height / viewPort.height);     

      canvas.width = viewPort.width * scale_thum;
      canvas.height= viewPort.height * scale_thum;
      canvas.padding=0;
    
      canvas.addEventListener('click', CanvasClick, false); 
      function CanvasClick(event)
      {
        eventBus.dispatch("pagechanging", {
            source: this,
            pageNumber: page._pageIndex + 1,        
        });   
      }
                  
    
      return canvas;
  }