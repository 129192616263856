import { PDFBaseViewer } from './PDFBaseViewer';
import { ANOTATION,FORM_STATUS } from '../edit/annotation';
import {TextHelper} from '../edit/text_helper';
import {fabricUltil,convertArray} from '../edit/fabric_helper';
import {toolBarHelper} from '../edit/toolbar_helper';
import {Config} from '../define/AppConfig';
import {PdfHistory,TYPE_HISTORY,TYPE_OBJECT} from '../edit/undo_redo';

export class PDFViewer extends PDFBaseViewer{   
    current_anotation = ANOTATION.NONE
    pointMouseDown = null
    pointMouseUp = null
    _activeObject = null
    _activeCanvas = null
    _activeElement = null
    mouseDown    
    origX 
    origY
    objectDrawing   
    image_selected
    set_anotation(anotation){
        this.current_anotation = anotation
        if(this.current_anotation == ANOTATION.NONE 
          || this.current_anotation == ANOTATION.TEXT_HIGHLIGHT 
          || this.current_anotation == ANOTATION.TEXT_STROKEOUT 
          || this.current_anotation == ANOTATION.TEXT_UNDERLINE
          ){
            $('.textLayer').removeClass("no-display");    
            $('.formLayer').css("pointer-events","none");           
          }
          else{    
            $('.textLayer').addClass("no-display");   
            $('.formLayer').css("pointer-events","none");
            // $(".with-tooltip").css("background-color","white");
        }  
        toolBarHelper.setdisplay(this.current_anotation );
    }

    get activeObject(){
      return this._activeObject;
    }
    set activeObject(value){
      this._activeElement = null;
      this._activeObject = value;
    }

    get activeElement(){
      return this._activeElement;
    }
    set activeElement(value){
      this._activeObject = null;
      this._activeElement = value;
    }
    
    async reloadData(url){     
      await this.reload(url);        
    }  
    page_change(num){
      this.activeCanvas = getFabricItem(this.fabricpageAnnotations,num-1);
    }
    select_text(){
      this.set_anotation(ANOTATION.NONE);
    }
    select_annotation(){
      this.set_anotation(ANOTATION.MOVE);
    }
    mousedown(e){
      this.pointMouseDown = {clientX: e.clientX, clientY : e.clientY};   
    }
    mouseup(e){
      this.pointMouseUp = {clientX: e.clientX, clientY : e.clientY}; 
      var selection;    
      if (window.getSelection) {
        selection = window.getSelection();
      } else if (document.selection) {
        selection = document.selection.createRange();
      }    
      if (selection.rangeCount) {       
        if(this.current_anotation == ANOTATION.TEXT_HIGHLIGHT 
          || this.current_anotation == ANOTATION.TEXT_STROKEOUT 
          || this.current_anotation == ANOTATION.TEXT_UNDERLINE){
          TextHelper.addHightLight(selection,this);     
        }
          
      }
    }
  // start action annotation
    add_text(){
      this.set_anotation(ANOTATION.ADD_TEXT);
    }
    highlight(){
      this.set_anotation(ANOTATION.TEXT_HIGHLIGHT);
    }
    strikeout(){
      this.set_anotation(ANOTATION.TEXT_STROKEOUT);
    }
    underline(){
      this.set_anotation(ANOTATION.TEXT_UNDERLINE);
    }
    draw_line(){
      this.set_anotation(ANOTATION.DRAW_LINE);; 
    }
    draw_arrow(){
      this.set_anotation(ANOTATION.DRAW_ARROW);
    }
    draw_ellipse(){
      this.set_anotation(ANOTATION.DRAW_ELLIPSE);
    }
    draw_rectangle(){
      this.set_anotation(ANOTATION.DRAW_RECTANGLE);
    }
    mark_x(data){

      this.image_selected = data.element.closest('.edit-button').querySelector('IMG');
      this.set_anotation(ANOTATION.ADD_XMARK);
    }
    mark_v(data){
      this.image_selected = data.element.closest('.edit-button').querySelector('IMG');
      this.set_anotation(ANOTATION.ADD_CHECKMARK);
    }
    mark_o(data){
      this.image_selected = data.element.closest('.edit-button').querySelector('IMG');
      this.set_anotation(ANOTATION.ADD_DOT);
    }
    form_textbox(){
      this.set_anotation(ANOTATION.ADD_TEXTBOX);
    }
    form_textarea(){
      this.set_anotation(ANOTATION.ADD_TEXT_AREA);
    }
    form_radio(){
      this.set_anotation(ANOTATION.ADD_RADIO); 
    }
    form_checkbox(){
      this.set_anotation(ANOTATION.ADD_CHECKBOX);
    }
    form_dropdown(){
      this.set_anotation(ANOTATION.ADD_DROPDOWN); 
    }
    add_image(){
      this.set_anotation(ANOTATION.ADD_IMAGE);
      showModal(window.location.origin + "/pdf/personal_image");

    }
    pen(){
      this.set_anotation(ANOTATION.PENCIL);
    }
    free_draw(){
      this.set_anotation(ANOTATION.FREE_DRAW); 
    }
    erase(){
      this.set_anotation(ANOTATION.ERASE);
    }
    link(){
      this.set_anotation(ANOTATION.ADD_LINK);
    }
    customize_tool(){
      document.getElementById('pop-up-edit-tool').style.display= 'block';
    }
    close_tool(){
      document.getElementById('pop-up-edit-tool').style.display= 'none';
    }
    undo(){      
      const state = this.undoRedoHandler.getPrevState();    
      if (state) {
        this.applyPreview(state);
      }
      this.updateStatusUndoRedo();
    }
    applyPreview(state){
      switch(state.typeHistory){
        case TYPE_HISTORY.ADD:
          state.typeHistory = TYPE_HISTORY.DELETE;
          this.undo_delete(state);          
          break;
        case TYPE_HISTORY.DELETE:
          state.typeHistory = TYPE_HISTORY.ADD;
          this.redo_add(state);          
          break;
        
      }     
    }
    redo(){      
      const state = this.undoRedoHandler.getNextState();    
      if (state) {
        this.applyPreview(state);
      }
      this.updateStatusUndoRedo();
    }   
    
    download(){
      var name_file = $("#name_file").val();
      download(this.file.bytes, `${name_file}.pdf`, "application/pdf"); 
    }
   

    
    // end action annotation

    // fabric Events
    mouseDownEvent(data){
      const {canvas,ev} = data;
      this.activeCanvas = canvas;
      this.activeObject = canvas.getActiveObject();
      toolBarHelper.hideToolProperties(); 
      if(this.activeObject){
         
        toolBarHelper.showtoolObject(this.activeObject);           
      }
      else{      
        fabricUltil.mouseDown(ev,canvas,this);    
      }    
  }


  formLayerClick(ev){
    // this.set_anotation(this.current_anotation);
  }
  object_added(data){
    this.form_status = FORM_STATUS.EDITTING;
    //this.updateStatusUndoRedo();this.updateStatusUndoRedo();
    // const {canvas,ev} = data;
    // fabricUltil.objectModified(ev,this.current_anotation);    
  }
  itemannotation_click(data){
    var elem = data.element;
    var pageNum = parseInt(elem.getAttribute('page-num'));
    this.currentPageNumber = pageNum;
    if(elem.getAttribute('type') == 'canvas'){
      var canvas = getFabricItem(this.fabricpageAnnotations,pageNum-1);
      if(canvas){
        canvas.getObjects().forEach((obj) => {
          if(obj.id === elem.id){
            setFabricItemDeselectAll(this.fabricpageAnnotations);
            canvas.setActiveObject(obj);
            canvas.renderAll();
            return;
          }            
        });
      }
      
    }
    else{
      var currentPageDiv = document.getElementById("page-"+ pageNum);
      var item = currentPageDiv.querySelector(`[id-object='${elem.id}']`);
      if(item){
        if(item.className.includes('ff-editable-inner')){
          item.querySelector('.f-input').focus();
        }
        else{
          item.focus();
        }
      }
      
      
    }
  }
  object_modified(data){
    const {canvas,ev} = data;
    fabricUltil.objectModified(ev,this.current_anotation);
    
  }
  path_created(data){
    fabricUltil.pathCreated(data.ob,this.current_anotation,this)
  }
  object_moving(data){
    fabricUltil.objectMoving(data.canvas,data.ev,this);
  }
  before_render(data){
    data.canvas.isDrawingMode = false;
  }
  mouse_move(data){
    fabricUltil.mouseMove(this,data.canvas,data.ev);
  }
  mouse_down_before(data){
    fabricUltil.mouse_down_before(this.current_anotation,data.canvas);
  }
  mouse_up(data){
    fabricUltil.mouseUp(this);
  }
  object_selected(data){
    fabricUltil.objectSelected(data.canvas,data.ev,this);
  }
  selection_cleared(data){
    fabricUltil.selectionCleared(data.canvas,data.ev,this);
  }
  //
  // start properties
  increaseSizeText(){
    if(this.activeObject){
      this.activeObject.set("fontSize", this.activeObject.fontSize + 1);    
      this.activeCanvas.renderAll();    
    }
  }
  decreaseSizeText(){
    if(this.activeObject){
      this.activeObject.set("fontSize", this.activeObject.fontSize - 1);    
      this.activeCanvas.renderAll();    
    }
  }
  boldtext(){
    if(this.activeObject){
      if(this.activeObject.fontWeight=="bold")
        {
          this.activeObject.set("fontWeight", 'normal');
        }
      else
      {
        this.activeObject.set("fontWeight", 'bold');
      }    
      this.activeCanvas.renderAll();
    }
  }
  italictext(){
    if(this.activeObject){
      if(this.activeObject.fontStyle!="italic"){
        this.activeObject.set("fontStyle", 'italic');
      }
      else
      {
        this.activeObject.set("fontStyle", 'normal');
      }
      
      this.activeCanvas.renderAll();
    }
  }
  fontTextChange(data){
    if(this.activeObject){
      this.activeObject.set("fontFamily", data.value);
      this.activeCanvas.renderAll();
    }
  }
  textColor(data){
    if(this.activeObject){
      this.activeObject.set("fill", data.value);
      this.activeCanvas.renderAll();
    }
  }
  savelink(data){
      this.activeObject.link_type = data.link;
      Config.link.radio = data.link.link_type;
      Config.link.url = data.link.url;
      Config.link.mail = data.link.email;
      Config.link.phone = data.link.phone;
      Config.link.page = data.link.page;
      toolBarHelper.hideToolProperties();   
  }
  commonDeleteObject(data){
    toolBarHelper.hideToolProperties();   
    if(this.activeObject){    
      if(this.activeObject.annotation == ANOTATION.ADD_TEXT && !this.activeObject.selected) return;      
      this.deleteElementInCanvas(this.activeCanvas,this.activeObject);        
    }
  }
  deleteElementInCanvas(canvas,element){
    deleteElementInCanvas(canvas,element);    
    this.undoRedoHandler.removeItemStack(element);
    toolBarHelper.updateItemStack(this.undoRedoHandler._undoStack); 
    this.updateStatusUndoRedo();
    toolBarHelper.hideToolProperties();   
    setFabricItemDeselectAll(this.fabricpageAnnotations);
    
  }

  
  selectStamp(data){
    this.image_selected = data.element;
    this.set_anotation(ANOTATION.ADD_STAMP);
  }
  common_select_img(data){
    this.image_selected = data.element;
  }
  toolButton(data){
    var button = data.element;
    var toolItem = button.closest('.tool-item');
    var selected = toolItem.getAttribute('selected');
    var tool_type = toolItem.getAttribute('type');
    var containnerleftButton =  document.querySelector('.top-col-left');
    var button = containnerleftButton.querySelector(`.edit-button[type="${tool_type}"]`);
    if(selected=='true'){
      toolItem.setAttribute('selected',false);  
      button.setAttribute('display',false); 
      save_status_button(tool_type,false);
    }
    else{
      toolItem.setAttribute('selected',true);         
      button.setAttribute('display',true); 
      save_status_button(tool_type,true);
    }
    toolBarHelper.setHiddenButton();
  }
  brushChangeStrokeWidth(data){
    if(this.current_anotation == ANOTATION.PENCIL){
      Config.pencil.strokeWidth = data.strokeWidth;
    }
    if(this.current_anotation == ANOTATION.FREE_DRAW){
      Config.freedraw.strokeWidth = data.strokeWidth;
    } 

    if(this.current_anotation == ANOTATION.DRAW_LINE){
      Config.shape.line.strokeWidth = data.strokeWidth
    }
    if(this.current_anotation == ANOTATION.DRAW_ELLIPSE){
      Config.shape.ellipse.strokeWidth = data.strokeWidth;
    } 

    if(this.current_anotation == ANOTATION.DRAW_RECTANGLE){
      Config.shape.rectangle.strokeWidth = data.strokeWidth;
    } 



  }
  brushChangeStrokeColor(data){
    if(this.current_anotation == ANOTATION.PENCIL){
      Config.pencil.color = data.color;
    }
    if(this.current_anotation == ANOTATION.FREE_DRAW){
      Config.freedraw.color = data.color;
    } 
  }

  markItemWidth(data){
      var scale,origin_width;
      if(this.current_anotation == ANOTATION.ADD_XMARK){
        Config.form.xmark.width = data.value;
        origin_width = Config.form.xmark.origin_width;
      } 
      if(this.current_anotation == ANOTATION.ADD_CHECKMARK){
        Config.form.checkmark.width = data.value;
        origin_width = Config.form.checkmark.origin_width;
      } 
      if(this.current_anotation == ANOTATION.ADD_DOT){
        Config.form.dot.width = data.value;
        origin_width = Config.form.dot.origin_width;
      }        
      var ob =  this.activeObject;
      if( ob){
        scale = parseFloat(data.value)/parseFloat(origin_width);
        ob.set("scaleX", scale);
        this.activeCanvas.renderAll();
      } 
  }

  markItemHeight(data){
    var origin_height;
      if(this.current_anotation == ANOTATION.ADD_XMARK){
        Config.form.xmark.height = data.value;
        origin_height = Config.form.xmark.origin_height;
      } 
      if(this.current_anotation == ANOTATION.ADD_CHECKMARK){
        Config.form.checkmark.height = data.value;
        origin_height = Config.form.checkmark.origin_height;
      } 
      if(this.current_anotation == ANOTATION.ADD_DOT){
        Config.form.dot.height = data.value;
        origin_height = Config.form.dot.origin_height;
      }  
      var ob =  this.activeObject;
      if(ob){
        ob.set("scaleY", data.value/origin_height);
        this.activeCanvas.renderAll();
      } 
  }

  markItemOpacity(data){
    if(this.current_anotation == ANOTATION.ADD_XMARK){
      Config.form.xmark.opacity = data.value;
    } 
    if(this.current_anotation == ANOTATION.ADD_CHECKMARK){
      Config.form.checkmark.opacity = data.value;
    } 
    if(this.current_anotation == ANOTATION.ADD_DOT){
      Config.form.dot.opacity = data.value;
    }   
    var ob =  this.activeObject;
    if(ob){
      ob.set("opacity", parseFloat(data.value));
      this.activeCanvas.renderAll();
    } 
  }

  updateLineWidth(data){
    var ob =  this.activeObject;
      if(ob){
        var widthElem = parseInt(data.width);
        var scaleDash = widthElem/ob.strokeWidth;
        var strokeDashArray = convertArray(ob.strokeDashArray,scaleDash);
        ob.set("strokeWidth", widthElem);
        ob.set("strokeDashArray", strokeDashArray);
        if(ob.name == 'arrow'){    
          ob.line1.set("strokeWidth", widthElem);          
          ob.line2.set("strokeWidth", widthElem);
          ob.line1.set("strokeDashArray", strokeDashArray);
          ob.line2.set("strokeDashArray", strokeDashArray);
          if(ob.position_arrow == 2){
            ob.line3.set("strokeWidth", widthElem);
            ob.line4.set("strokeWidth", widthElem);
            ob.line3.set("strokeDashArray", strokeDashArray);
            ob.line4.set("strokeDashArray", strokeDashArray);
          }  
          ob.circle1.set('radius', widthElem);
          ob.circle2.set('radius', widthElem);
          fabricUltil.setMovingArrow(ob,this.activeCanvas,this);
        }     
        this.activeCanvas.renderAll();      
      } 
  }
  updateLineOpacity(data){
    var ob =  this.activeObject;
    if(ob){    
      var opa = parseFloat(data.opacity);
      ob.set("opacity", parseFloat(opa));
      if(ob.name == 'arrow'){
        ob.line1.set("opacity", opa);
        ob.line2.set("opacity", opa);
        if(ob.position_arrow == 2){
          ob.line3.set("opacity", opa);
          ob.line4.set("opacity", opa);
        }  
        ob.circle1.set('opacity', opa);
        ob.circle2.set('opacity', opa);
      }
      this.activeCanvas.renderAll();
    } 
  }

  updateLineColor(data){
    var ob =  this.activeObject;
      if(ob){      
        var color = data.color;
        ob.set("stroke", color);
        if(ob.name == 'arrow'){
          ob.line1.set("stroke", color);
          ob.line2.set("stroke", color);
          ob.line1.set("fill", color);
          ob.line2.set("fill", color);
          if(ob.position_arrow == 2){
            ob.line3.set("stroke", color);
            ob.line4.set("stroke", color);
            ob.line3.set("fill", color);
            ob.line4.set("fill", color);
          }  
          ob.circle1.set('fill', color);
          ob.circle2.set('fill', color);
          ob.circle1.set('stroke', color);
          ob.circle2.set('stroke', color);
          ob.set("fill", color);     
        }        
        this.activeCanvas.renderAll();
      } 
  }
  close_dialog(data){
    toolBarHelper.hideToolProperties();  
  }
  updateBackgroundColor(data){
    var ob =  this.activeObject;
    if(ob){
      ob.set("fill", data.color);
      this.activeCanvas.renderAll();
    }  
  }

  changeformcontrolwidth(data){
    if(this.current_anotation == ANOTATION.ADD_TEXTBOX){      
      Config.form.textfield.width = data.width;    
    }    
    if(this.current_anotation == ANOTATION.ADD_TEXT_AREA){      
      Config.form.textarea.width = data.width;     
    }    
    if(this.current_anotation == ANOTATION.ADD_RADIO){      
      Config.form.radio.width = data.width;     
    }   
    if(this.current_anotation == ANOTATION.ADD_CHECKBOX){      
      Config.form.checkbox.width = data.width;      
    }   
    if(this.current_anotation == ANOTATION.ADD_DROPDOWN){      
      Config.form.dropdown.width = data.width;   
    }   
  
    if(this.activeElement){
      this.activeElement.parentElement.style.width = data.width*this.currentScaleValue + 'px';
    }
  }
  changeformcontrolborderwidth(data){
    if(this.current_anotation == ANOTATION.ADD_TEXTBOX){
      Config.form.textfield.borderWidth = data.width;
    }    
    if(this.current_anotation == ANOTATION.ADD_TEXT_AREA){      
      Config.form.textarea.borderWidth = data.width; 
    }    
    if(this.current_anotation == ANOTATION.ADD_RADIO){      
      Config.form.radio.borderWidth = data.width;    
    }   
    if(this.current_anotation == ANOTATION.ADD_CHECKBOX){      
      Config.form.checkbox.borderWidth = data.width;
    }   
    if(this.current_anotation == ANOTATION.ADD_DROPDOWN){      
      Config.form.dropdown.borderWidth = data.width; 
    }   
  
    if(this.activeElement){
      this.activeElement.style.borderWidth = data.width*this.currentScaleValue + 'px';
    }

  }

  changeformcontrolHeight(data){
    if(this.current_anotation == ANOTATION.ADD_TEXTBOX){      
      Config.form.textfield.height = data.height;    
    }    
    if(this.current_anotation == ANOTATION.ADD_TEXT_AREA){      
      Config.form.textarea.height = data.height;     
    }    
    if(this.current_anotation == ANOTATION.ADD_RADIO){      
      Config.form.radio.height = data.height;     
    }   
    if(this.current_anotation == ANOTATION.ADD_CHECKBOX){      
      Config.form.checkbox.height = data.height;      
    }   
    if(this.current_anotation == ANOTATION.ADD_DROPDOWN){      
      Config.form.dropdown.height = data.height;   
    }   
  
    if(this.activeElement){
      this.activeElement.parentElement.style.height = data.height*this.currentScaleValue + 'px';
    }
  }

  changeformcontrolColor(data){
    if(this.current_anotation == ANOTATION.ADD_TEXTBOX){
      Config.form.textfield.color = data.color;   
    }    
    if(this.current_anotation == ANOTATION.ADD_TEXT_AREA){      
      Config.form.textarea.color = data.color;         
    }    
    if(this.current_anotation == ANOTATION.ADD_RADIO){      
      Config.form.radio.color = data.color;  
    }   
    if(this.current_anotation == ANOTATION.ADD_CHECKBOX){      
      Config.form.checkbox.color = data.color;    
    }   
    if(this.current_anotation == ANOTATION.ADD_DROPDOWN){      
      Config.form.dropdown.color = data.color;   
    }
  }
  changeformcontrolBorderColor(data){
    if(this.current_anotation == ANOTATION.ADD_TEXTBOX){
      Config.form.textfield.borderColor = data.color;   
    }    
    if(this.current_anotation == ANOTATION.ADD_TEXT_AREA){      
      Config.form.textarea.borderColor = data.color;         
    }    
    if(this.current_anotation == ANOTATION.ADD_RADIO){      
      Config.form.radio.borderColor = data.color;  
    }   
    if(this.current_anotation == ANOTATION.ADD_CHECKBOX){      
      Config.form.checkbox.borderColor = data.color;    
    }   
    if(this.current_anotation == ANOTATION.ADD_DROPDOWN){      
      Config.form.dropdown.borderColor = data.color;   
    }
  }
  selectElement(data){
    this.activeElement = data.element;
  }
  // end properties

  // element
  updateBackgroundColorDiv(data){
    this.activeElement.style.backgroundColor = data.color;
  }
  delete_element(){   
    if(!this.activeElement) return;
    this.deleteElementInHTML(this.activeElement);    
  }
  deleteElementInHTML(htmlObject){
    deleteElementInHTML(htmlObject);
    this.undoRedoHandler.removeItemStack(htmlObject);
    toolBarHelper.updateItemStack(this.undoRedoHandler._undoStack); 
    this.updateStatusUndoRedo();
    toolBarHelper.hideToolProperties();  
  }
  
  keyboardDeleteElement(){    
    if(!this.activeElement) return;
    if(validateBeforeDelete(this.activeElement)){
      this.deleteElementInHTML(this.activeElement);    
    }
  }

  showtoolElement(data){
    this.activeElement = data.source;
    toolBarHelper.showtoolElement(data,this );
  }

  updateElementColor(data){
    this.activeElement.style.color = data.color;
  }
  updateBorderColor(data){
    this.activeElement.style.borderColor = data.color;;
  }

  updateBoderWidth(data){
    this.activeElement.style.borderWidth = data.width + "px";
  }
  updateCenterColorElem(data){
    if(this.activeElement){
      var kind = this.activeElement.getAttribute('kind')
      if(kind == 'radio'){
        updateRadioCenterColor(data.color,this.activeElement);  
      }
      if(kind == 'checkbox'){
        updateCheckboxCenterColor(data.color,this.activeElement);
      }        
    }    
  }

  changeFieldName(data){
    this.activeElement.setAttribute("name",data.fieldName);
  }
  updateOptionSelect(data){
    this.activeElement.replaceChildren()
    var options = data.strOption.split("\n")

    if(options){
      for (let index = 0; index < options.length; index++) {
        const element = options[index];
        if(element && element.trim() != ""){
          this.activeElement.append(new Option(element.trim()));
        }
        
      }
    }  
  }
  delete_annotation(data){
    if(data.typeObject == 'html'){
      var object = document.querySelector(`[id-object=${data.objectId}]`);  
      this.deleteElementInHTML(object);
    }
    else{
      var canvas = this.fabricpageAnnotations[data.pageNum-1][1];
      if(canvas){
        canvas.getObjects().forEach((obj) => {
          if(obj.id == data.objectId)
            this.deleteElementInCanvas(canvas,obj);
            return;
        });
      }    
    }
  }
  undo_delete(pdfHistory){    
    if(pdfHistory.typeObject == TYPE_OBJECT.OBJECT_HTML){
      deleteElementInHTML(pdfHistory.htmlObject);
    }
    else{
      var canvas = getFabricItem(this.fabricpageAnnotations,pdfHistory.pageNumber-1);
      if(canvas){   
        deleteElementInCanvas(canvas,pdfHistory.canvasObject);
      }    
    }
    toolBarHelper.updateItemStack(this.undoRedoHandler._undoStack);   
  }
  redo_add(addHistory){
    this.set_anotation(addHistory.annotation);
    if(addHistory.typeObject == TYPE_OBJECT.OBJECT_HTML){
      var parent;     
      var currentPageDiv = document.getElementById("page-"+ addHistory.pageNumber);
      if(addHistory.htmlObject.className.includes('ff-editable-inner')){   
        parent = currentPageDiv.querySelector(".formLayer");     
        parent.style.pointerEvents = "auto";
        $('.anotation-buttons .anotation-popup a').removeClass("active");    
      }
      else{
        parent = currentPageDiv.querySelector(".annotationLayer"); 
      }
      parent.appendChild(addHistory.htmlObject);
    }
    else{
      var canvas = getFabricItem(this.fabricpageAnnotations,addHistory.pageNumber-1);
      if(canvas){
        canvas.add(addHistory.canvasObject);
        if(addHistory.canvasObject.name == "arrow"){
          canvas.add(addHistory.canvasObject.circle1);
          canvas.add(addHistory.canvasObject.circle2);
          canvas.add(addHistory.canvasObject.line1);
          canvas.add(addHistory.canvasObject.line2);
          if(addHistory.canvasObject.position_arrow == 2){
            canvas.add(addHistory.canvasObject.line3);
            canvas.add(addHistory.canvasObject.line4);
          }
        }
        canvas.setActiveObject(addHistory.canvasObject);
        canvas.renderAll();  
      }
      
    }
    toolBarHelper.updateItemStack(this.undoRedoHandler._undoStack);
  }
  change_status_form(){
    this.set_anotation(ANOTATION.NONE);
  // set state form
  if(this.currentStackIndex>= 0){  
    var object = this.histoyStack[this.arrayState[this.currentStackIndex]];
    this.set_anotation(object.annotation);
    if(object.typeObject == TYPE_OBJECT.OBJECT_HTML){
      var parent;     
      var currentPageDiv = document.getElementById("page-"+ object.pageNumber);
      var item = currentPageDiv.querySelector(`[id='${object.htmlObject.id}']`);
      if(object.htmlObject.className.includes('ff-editable-inner')){   
        parent = currentPageDiv.querySelector(".formLayer");     
        parent.style.pointerEvents = "auto";
        $('.anotation-buttons .anotation-popup a').removeClass("active");    
        item.querySelector('.f-input').focus();
      }   
      else{
        item.focus();
      }
    }  
    else{
      setFabricItemDeselectAll(this.fabricpageAnnotations);
      if(object.canvasObject && object.canvasObject.canvas){
        object.canvasObject.canvas.setActiveObject(object.canvasObject);
        object.canvasObject.canvas.renderAll();
      }
      
    }
    this.currentPageNumber = parseInt(object.pageNumber);
    
  }
  }
  addHistory(pdfHistory){   
    this.undoRedoHandler.insert(pdfHistory);
    toolBarHelper.updateItemStack(this.undoRedoHandler._undoStack);
    this.updateStatusUndoRedo();
  }
}


function updateRadioCenterColor(color,elem){
  addRule(`input[name="${elem.getAttribute('name')}"]:before`,`background-color: ${color} !important`);  
}
function updateCheckboxCenterColor(color,elem){
  addRule(`input[name="${elem.getAttribute('name')}"]:before`,`background-color: ${color} !important`); 
  addRule(`input[name="${elem.getAttribute('name')}"]:after`,`background-color: ${color} !important`);  
}

function getFabricItem(fabricpageAnnotations,idx){
  for (let index = 0; index < fabricpageAnnotations.length; index++) {
      const element = fabricpageAnnotations[index];
      if(element[0] == idx)
      {
          return element[1];
      }      
  } 
}


function setFabricItemDeselectAll(fabricpageAnnotations){
  for (let index = 0; index < fabricpageAnnotations.length; index++) {
      const element = fabricpageAnnotations[index];
      element[1].discardActiveObject().renderAll();
      
  } 
}

function deleteElementInCanvas(canvas,element){
  if(element._objects){
    element._objects.forEach((obj) => {
      deleteOneElement(canvas,obj)
    });
  }
  else{
    deleteOneElement(canvas,element);
  }
 

}
function deleteOneElement(canvas,element){
  if(element.annotation == ANOTATION.DRAW_ARROW){
    canvas.remove(element.circle1,element.circle2,element.line1,element.line2);
    if(element.position_arrow == 2){
      canvas.remove(element.line3,element.line4);
    }
  }
  canvas.remove(element);
  canvas.renderAll();
}
function deleteElementInHTML(object){   
  if(object){
    if(object.className.includes('f-input')){
      object.parentElement.remove();
    }
    else{
      object.remove();  
    }
  }
  
}
function validateBeforeDelete(obj){
  var candelete = true;
  var annotation = obj.getAttribute("annotation");
  if(annotation == ANOTATION.ADD_TEXTBOX || annotation == ANOTATION.ADD_TEXT_AREA){
    candelete = false;
  }
  return candelete;
}

function save_status_button(tool_type, status){
  var objectDataSubmit = {tool_type: tool_type,status:status};
  AjaxSubmit("/pdf/save_status_button",'GET',objectDataSubmit);  
}

// function removeAll(canvas) {
//   getSelection(canvas).forEach(function (object) {
//   canvas.remove(object);
//   o.removeWithUpdate(object);
//   })
// }

function getSelection(canvas){
  return canvas.getActiveObject() == null ? canvas.getActiveGroup() : canvas.getActiveObject()
}