import { ANOTATION,link_enum } from '../edit/annotation';
import {Config} from '../define/AppConfig';
import { Convert } from '.././../convert';
import {PdfHistory,TYPE_HISTORY,TYPE_OBJECT} from '../edit/undo_redo';
export const toolBarHelper = {    
    setdisplay(annotation){
      hideAllBarRibbon(); 
        switch(annotation){
          case ANOTATION.NONE:
            document.getElementById('btnSelectPoint').click();
            break;
          case ANOTATION.SELECT_OBJECT:       
            $('.anotation-buttons .anotation-popup a').removeClass("active");
            break;
          case ANOTATION.ADD_TEXT:       
            document.querySelector('.text-properties').style = "display: flex !important";
            break;
          case ANOTATION.TEXT_HIGHLIGHT:
            document.querySelector('.text-highlight-properties').style = "display: flex !important";
            break;
          case ANOTATION.TEXT_STROKEOUT:
            document.querySelector('.text-strokeout-properties').style = "display: flex !important";
            break;
          case ANOTATION.TEXT_UNDERLINE:
            document.querySelector('.text-underline-properties').style = "display: flex !important";
            break;
          case ANOTATION.ADD_XMARK:
          case ANOTATION.ADD_CHECKMARK:
          case ANOTATION.ADD_DOT:
            document.querySelector('.form-xmark-properties').style = "display: flex !important"; 
            var width,height,opacity;
            if(annotation == ANOTATION.ADD_XMARK){
              width = Config.form.xmark.width;
              height = Config.form.xmark.height;
              opacity = Config.form.xmark.opacity;
            }
            if(annotation == ANOTATION.ADD_CHECKMARK){
              width = Config.form.checkmark.width;
              height = Config.form.checkmark.height;
              opacity = Config.form.checkmark.opacity;
            }
            if(annotation == ANOTATION.ADD_DOT){
              width = Config.form.dot.width;
              height = Config.form.dot.height;
              opacity = Config.form.dot.opacity;
            }
            document.querySelector('.form-xmark-properties').querySelector('#markItemWidth').value = width;     
            document.querySelector('.form-xmark-properties').querySelector('#markItemHeight').value = height;
            document.querySelector('.form-xmark-properties').querySelector('#markItemOpacity').value = opacity;
            break;  
          case ANOTATION.ADD_TEXTBOX:
          case ANOTATION.ADD_TEXT_AREA:
          case ANOTATION.ADD_RADIO:  
          case ANOTATION.ADD_CHECKBOX: 
          case ANOTATION.ADD_DROPDOWN:  
              var width,height,color,borderWidth,borderColor;
              var parent = document.querySelector('.form-textfield-properties');
              if(annotation == ANOTATION.ADD_TEXTBOX){
                width = Config.form.textfield.width;
                height = Config.form.textfield.height;
                color = Config.form.textfield.color;
                borderWidth = Config.form.textfield.borderWidth;
                borderColor = Config.form.textfield.borderColor;
                parent = document.querySelector('.form-textfield-properties')                
              }
              if(annotation == ANOTATION.ADD_TEXT_AREA){
                width = Config.form.textarea.width;
                height = Config.form.textarea.height;
                color = Config.form.textarea.color;
                borderWidth = Config.form.textarea.borderWidth;
                borderColor = Config.form.textarea.borderColor;
                parent = document.querySelector('.form-textarea-properties')    
              }
              if(annotation == ANOTATION.ADD_RADIO){
                width = Config.form.radio.width;
                height = Config.form.radio.height;
                color = Config.form.radio.color;
                borderWidth = Config.form.radio.borderWidth;
                borderColor = Config.form.radio.borderColor;
                parent = document.querySelector('.form-radio-properties')    
              }
              if(annotation == ANOTATION.ADD_CHECKBOX){
                width = Config.form.checkbox.width;
                height = Config.form.checkbox.height;
                color = Config.form.checkbox.color;
                borderWidth = Config.form.checkbox.borderWidth;
                borderColor = Config.form.checkbox.borderColor;
                parent = document.querySelector('.form-checkbox-properties')    
              }
              if(annotation == ANOTATION.ADD_DROPDOWN){
                width = Config.form.dropdown.width;
                height = Config.form.dropdown.height;
                color = Config.form.dropdown.color;
                borderWidth = Config.form.dropdown.borderWidth;
                borderColor = Config.form.dropdown.borderColor;
                parent = document.querySelector('.form-dropdown-properties')    
              }
              parent.style = "display: flex !important";       
              parent.querySelector('#textFieldWidth').value = width;    
              parent.querySelector('#textFieldHeight').value = height;
              parent.querySelector('#textFieldBorderWidth').value = borderWidth;
              parent.querySelector('#textFieldColor').style.backgroundColor = color;
              parent.querySelector('#textFieldBoderColor').style.backgroundColor = borderColor;                
              break;  
            case ANOTATION.DRAW_LINE:
              document.querySelector('.shape-line-properties').style = "display: flex !important";
              document.querySelector(`.shape-line-properties .linewidthitem[data-item="${Config.shape.line.strokeWidth}"]`).classList.add('selected');
              el('lineItemColor').style.backgroundColor = Config.shape.line.backGroundColor;
              document.querySelector('.shape-line-properties').querySelector('#lineItemOpacity').value = Config.shape.line.opacity;
              break;
            case ANOTATION.DRAW_ELLIPSE:
              document.querySelector('.shape-ellipse-properties').style = "display: flex !important";
              document.querySelector(`.shape-ellipse-properties .linewidthitem[data-item="${Config.shape.ellipse.strokeWidth}"]`).classList.add('selected');
              el('ellipsetemColor').style.backgroundColor = Config.shape.line.backGroundColor;
              document.querySelector('.shape-ellipse-properties').querySelector('#ellipseItemOpacity').value = Config.shape.ellipse.opacity;
              break;
            case ANOTATION.DRAW_RECTANGLE:
              document.querySelector('.shape-rectangle-properties').style = "display: flex !important";
              document.querySelector(`.shape-rectangle-properties .linewidthitem[data-item="${Config.shape.rectangle.strokeWidth}"]`).classList.add('selected');
              el('rectangleItemColor').style.backgroundColor = Config.shape.rectangle.stroke;
              document.querySelector('.shape-rectangle-properties').querySelector('#rectangleItemOpacity').value = Config.shape.rectangle.opacity;
              break;
            case ANOTATION.PENCIL:
              document.querySelector('.pencil-properties').style = "display: flex !important";
              break;
            case ANOTATION.FREE_DRAW:
              document.querySelector('.freedraw-properties').style = "display: flex !important";             
              break;     
            case ANOTATION.DRAW_ARROW:        
              document.querySelector('.shape-arrow-properties').style = "display: flex !important";       
              break;   
              
        }
    }, 
    showtoolObject(object){  
      var tool = null;
      switch(object.annotation)
      {
        case ANOTATION.NONE:
          break;    
        case ANOTATION.ADD_TEXT:
          tool = el('text-editable-menu') ;
          tool.querySelector("#text-color").value = object.fill;
          tool.querySelector("#fontTextChange").value = object.fontFamily;
          var fontWeight = object.fontWeight;
          var selected = "selected";
          if(fontWeight === 'bold'){
            tool.querySelector("#text-bold").classList.add(selected);
          }
          else{
            tool.querySelector("#text-bold").classList.remove(selected);
          }
          var fontStyle = object.fontStyle;
          if(fontStyle === 'italic'){
            tool.querySelector("#text-italic").classList.add(selected);
          }
          else{
            tool.querySelector("#text-italic").classList.remove(selected);
          }
          break;
        case ANOTATION.ADD_STAMP:     
          tool = el('image-editable-menu') ;     
          tool.querySelector("#img-opacity").value = object.opacity;
          break;
       case ANOTATION.PENCIL:   
       case ANOTATION.FREE_DRAW:  
          tool = el('free-draw-editable-menu') ;
          tool.querySelector("#free-draw-stroke-width").value = object.strokeWidth;
          tool.querySelector("#free-draw-opacity").value = object.opacity;
          tool.querySelector("#free-draw-color").value = object.stroke;
          break;
       case ANOTATION.ADD_LINK:       
           tool = el('link-editable-menu') ;  
           var link = object.link_type;
           switch(link.link_type){
            case link_enum.url:
              setCheckedRadio("rd-url",true);
              break;
            case link_enum.mail:
              setCheckedRadio("rd-mail",true);
              break;
            case link_enum.phone:
              setCheckedRadio("rd-phone",true);
              break;
            case link_enum.page:
              setCheckedRadio("rd-page",true);
              break;
          }
          setValueTextBox("targetURL",link.url);
          setValueTextBox("targetEmailAddress",link.email);
          setValueTextBox("targetTel",link.phone);
          setValueTextBox("targetPage",link.page);
          break;       
      case ANOTATION.DRAW_ARROW:
       case ANOTATION.DRAW_LINE:   
          tool = el('line-editable-menu') ;
          tool.querySelector("#line-stroke-width").value = object.strokeWidth;
          tool.querySelector("#line-opacity").value = object.opacity;
          tool.querySelector("#line-color").value = object.stroke;
          break;      
       case ANOTATION.DRAW_RECTANGLE:      
           tool = el('rectangle-editable-menu') ;
           tool.querySelector("#rectangle-stroke-width").value = object.strokeWidth;
           tool.querySelector("#rectangle-opacity").value = object.opacity;
           tool.querySelector("#rectangle-color").value = object.stroke; 
           
           var colorDefault = "#FFFFFF";
           if(object.fill !== ""){
            colorDefault = object.fill;
           }
           tool.querySelector("#rectangle-background-color").value = colorDefault; 
           break;
       case ANOTATION.DRAW_ELLIPSE:   
           tool = el('ellipse-editable-menu') ;     
           tool.querySelector("#ellipse-stroke-width").value = object.strokeWidth;
           tool.querySelector("#ellipse-opacity").value = object.opacity;
           tool.querySelector("#ellipse-color").value = object.stroke; 
           var colorDefault = "#FFFFFF";
           if(object.fill !== ""){
            colorDefault = object.fill;
           }
           tool.querySelector("#ellipse-background-color").value = colorDefault; 
           break;  
       case ANOTATION.ADD_TEXTBOX:
           tool = el('textbox-editable-menu') ;      
           break;
       case ANOTATION.ADD_TEXT_AREA:
           tool = el('textbox-editable-menu') ; 
           break;
       case ANOTATION.ADD_RADIO:           
       case ANOTATION.ADD_CHECKBOX:
          tool = el('radio-editable-menu') ; 
          break;;
       case ANOTATION.ADD_DROPDOWN:
            tool = el('select-editable-menu') ; 
            break;
       case ANOTATION.ERASE:
         break;
       case ANOTATION.ADD_XMARK:   
       case ANOTATION.ADD_CHECKMARK:     
       case ANOTATION.ADD_DOT: 
       case ANOTATION.ADD_IMAGE:      
        tool = el('image-editable-menu') ;  
        tool.querySelector("#img-opacity").value = object.opacity;         
        break;     
      }  
      
      if(tool){
        tool.style.display = 'block';
        var page = object.canvas.getSelectionElement();
        var rect = page.getBoundingClientRect();    
        tool.style.left = rect.left + getMinLeft(object) + "px";
        tool.style.top = rect.top + getMaxBottom(object) + 10 + "px";
      }
    }   ,
    hideToolProperties(){
      hideToolsPeroperties();
    },
    showtoolElement(data,viewer ){
      switch(data.type){
        case "textbox":
        case "textarea":
          show_tool_textbox(data.source,viewer); 
          break;
        case "radio":
          show_tool_radio(data.source,viewer);
          break;
        case "checkbox":
          show_tool_radio(data.source,viewer); 
          break;
        case "dropdown":
          show_tool_select(data.source,viewer);  
          break;
      }
    },
    updateStatusUndoRedo(viewer){
      var statusUndo = false,statusRedo = false;
      if(viewer.undoRedoHandler._undoStack.length > 0){
        statusUndo = true;
      }
      if(viewer.undoRedoHandler._redoStack.length > 0){
        statusRedo = true;
      }
      document.getElementById("btnUndoEdit").setAttribute('can_click',statusUndo);
      document.getElementById("btnRedoEdit").setAttribute('can_click',statusRedo);
    },
    updateItemStack(historyStacks){
      var list = document.querySelector('.list-anotation');
      list.replaceChildren();
      for (let index = 0; index < historyStacks.length; index++) {
        const element = historyStacks[index];
        this.addItemStack(element);
        
      }

    },
    addItemStack(historyStack){
      var list = document.querySelector('.list-anotation');
      var item = document.createElement("div");  
      item.setAttribute("class", "item-anotation");  
      item.setAttribute("page-num", historyStack.pageNumber);
      if(historyStack.canvasObject){
        item.setAttribute("id", historyStack.canvasObject.id);
        item.setAttribute("type", 'canvas');
        
      }
      else{
        item.setAttribute("id", historyStack.htmlObject.getAttribute('id-object'));
        item.setAttribute("type", 'html');
      }
        
      list.appendChild(item);

      var row = document.createElement("div");  
      row.setAttribute("class", "ms-1");  
      item.appendChild(row);

      var colPage = document.createElement("div");  
      colPage.setAttribute("class", "col text-start p-0");  
      colPage.innerHTML = "Page " + historyStack.pageNumber;
      row.appendChild(colPage);

      var colAction = document.createElement("div");  
      colAction.setAttribute("class", "col ms-1 text-start p-0 fw-bold");  
      var labelAction = 'Add ';
      if(historyStack.typeHistory == TYPE_HISTORY.DELETE){
        labelAction = "Delete " ;
      }
      
      if(historyStack.canvasObject){
        colAction.innerHTML = labelAction + historyStack.canvasObject.name;
      }
      else
      {
        colAction.innerHTML = labelAction + historyStack.htmlObject.getAttribute('name');
      }
      
      item.appendChild(colAction);

      var row2 = document.createElement("div");  
      row2.setAttribute("class", "text-end");  
      item.appendChild(row2);

      var buttonDellete = document.createElement("button");  
      buttonDellete.setAttribute("class", "btn button-delete-annotation");  
      buttonDellete.setAttribute("type", "button");  
      
      var iDellete = document.createElement("i");  
      iDellete.setAttribute("class", "mdi mdi-window-close");  
      buttonDellete.appendChild(iDellete);

      row2.appendChild(buttonDellete);

     
      // <button type="button" class="btn btn-danger"><i class="mdi mdi-window-close"></i> </button>
      //<input type="hidden" name="id" id="id" value="117" autocomplete="off">
    },
    removeItemStack(historyStack){
      var list = document.querySelector('.list-anotation');
      var item;
      if(historyStack.canvasObject){
        item = list.querySelector(`[id='${historyStack.canvasObject.id}']`);
      }
      else{
        item = list.querySelector(`[id='${historyStack.htmlObject.getAttribute("id-object")}']`);
      }
     if(item){
      item.remove();
     }
    },
    activeItem(historyStack){
      var active = 'active';
      $('.list-anotation .item-anotation').removeClass(active);
      var list = document.querySelector('.list-anotation');
      var item;
      if(historyStack.canvasObject){
        item = list.querySelector(`[id='${historyStack.canvasObject.id}']`);
      }
      else{
        item = list.querySelector(`[id='${historyStack.htmlObject.getAttribute("id-object")}']`);
      }
      if(item){
        item.classList.add(active);
        item.scrollIntoViewIfNeeded();
      }
      
    },
    clearListAnotations(){
      var list = document.querySelector('.list-anotation');
      list.replaceChildren();
    },
    setHiddenButton(){
      var currentUrl = window.location.pathname;
      if (currentUrl.includes(`/pdf/`) && currentUrl.includes(`/edit`)) { 
          var containerButton = document.querySelector('.top-page');
          var containerWidth = containerButton.offsetWidth;
          var right = document.querySelector('.top-col-right');
          var rightWidth = right.offsetWidth;
  
          var containnerleftButton =  document.getElementById('sortable');
          var listButton = containnerleftButton.querySelectorAll(`.edit-button[display="${true}"`);
          if(listButton && listButton.length > 0){
              var width = listButton[0].offsetWidth;
              var maxButton = (containerWidth - rightWidth - 56 * 3 - 25*2 )/width - 2;
              for (let index = 0; index < listButton.length; index++) {
                  const element = listButton[index];
                  if(index < maxButton){
                      element.setAttribute('show-response',true); 
                  }
                  else
                  {
                      element.setAttribute('show-response',false);      
                  }                
              }
          }
      }
      
  }
}
function getMinLeft(element){
  var min = element.aCoords.bl.x;
  if(min > element.aCoords.br.x){
    min = element.aCoords.br.x;
  }
  if(min > element.aCoords.tl.x){
    min = element.aCoords.tl.x;
  }
  if(min > element.aCoords.tr.x){
    min = element.aCoords.tr.x;
  }
  return min;
 }
 function getMaxBottom(element){
  var max = element.aCoords.bl.y;
  if(max < element.aCoords.br.y){
    max = element.aCoords.br.y;
  }
  if(max < element.aCoords.tl.y){
    max = element.aCoords.tl.y;
  }
  if(max < element.aCoords.tr.y){
    max = element.aCoords.tr.y;
  }
  return max;
}
function hideAllBarRibbon(){
  let children = document.querySelectorAll(".toolbars .toolbarItem");
  children.forEach(child => {
    child.style = "display: none !important";
  })  
}
function hideToolsPeroperties(){
  $(".toolbars .dropdown-toolbar").css("display","none"); 
}


function colorIsRGBA(color){
  if (!color || color.indexOf("rgba") < 0) {
    return false;
  }
  return true;
}

function show_tool_textbox(element,viewer){
  $("#textbox-editable-menu").css("display","block");
  var currentPageDiv = document.getElementById("page-"+ viewer.currentPageNumber);
  var formLayer = currentPageDiv.querySelector(".formLayer");
  var rect = formLayer.getBoundingClientRect();

  $("#textbox-editable-menu").css("left",rect.left + element.parentElement.offsetLeft + "px");
  $("#textbox-editable-menu").css("top",rect.top + element.parentElement.offsetTop + element.parentElement.offsetHeight + 20 + "px");
  setDisplayTooltextbox(element);
 }
 function hide_tool_textbox(){
  $("#textbox-editable-menu").css("display","none"); 
 }
 function setDisplayTooltextbox(obj){
  document.getElementById("textbox-border-color").value = Convert.rgbToHex( window.getComputedStyle(obj).borderColor);
  document.getElementById("textbox-border-width").value = parseInt(window.getComputedStyle(obj).borderWidth);
  document.getElementById("textbox-color").value = Convert.rgbToHex(window.getComputedStyle(obj).color);
}

function show_tool_radio(element,viewer){
  $("#radio-editable-menu").css("display","block");
  var currentPageDiv = document.getElementById("page-"+ viewer.currentPageNumber);
  var formLayer = currentPageDiv.querySelector(".formLayer");
  var rect = formLayer.getBoundingClientRect();

  $("#radio-editable-menu").css("left",rect.left + element.parentElement.offsetLeft + "px");
  $("#radio-editable-menu").css("top",rect.top + element.parentElement.offsetTop + element.parentElement.offsetHeight + 20 + "px");
  setDisplayToolRadio(element);
 }
 function hide_tool_radio(){
  $("#radio-editable-menu").css("display","none"); 
 }
 function setDisplayToolRadio(obj){
  document.getElementById("radio-border-color").value = Convert.rgbToHex( window.getComputedStyle(obj).borderColor);
  document.getElementById("radio-border-width").value = parseInt(window.getComputedStyle(obj).borderWidth);

  var backgroundColor = getPropertyBeforeElement(obj,'background-color') ;

  if(colorIsRGBA(backgroundColor)){
    var rgb = Convert.rGBAToRGB(backgroundColor);
    var color = 'rgb(' + rgb.r + ',' + rgb.g + ',' +  rgb.b +')';
    document.getElementById("radio-color").value = Convert.rgbToHex(color);
  }
  else{
    document.getElementById("radio-color").value = Convert.rgbToHex(backgroundColor);
  }
}

function show_tool_select(element,viewer){
  $("#select-editable-menu").css("display","block");
  var currentPageDiv = document.getElementById("page-"+ viewer.currentPageNumber);
  var formLayer = currentPageDiv.querySelector(".formLayer");
  var rect = formLayer.getBoundingClientRect();

  $("#select-editable-menu").css("left",rect.left + element.parentElement.offsetLeft + "px");
  $("#select-editable-menu").css("top",rect.top + element.parentElement.offsetTop + element.parentElement.offsetHeight + 20 + "px");
  setDisplayToolSelect(element);
 }
 function hide_tool_select(){
  $("#select-editable-menu").css("display","none"); 
 }
 function setDisplayToolSelect(obj){
  document.getElementById("select-border-color").value = Convert.rgbToHex( window.getComputedStyle(obj).borderColor);
  document.getElementById("select-text-color").value = Convert.rgbToHex( window.getComputedStyle(obj).color);
  document.getElementById("textbox-border-width").value = parseInt(window.getComputedStyle(obj).borderWidth);
  document.getElementById("select_fieldName").value = obj.getAttribute("name");
  var ops =  obj.querySelectorAll("option");
  var opString = ""
  if(ops){
    for (let index = 0; index < ops.length; index++) {
      const element = ops[index];
      if(index == 0)
        opString += element.text
      else 
        opString = opString +  "\n" + element.text      

    }
    
  }
  document.getElementById("fieldOptions").value = opString

}

function setCheckedRadio(id,value){
  $(`#${id}`).prop("checked", value);   
 }
 function setValueTextBox(id,value){
  var txt = document.getElementById(id);
  txt.value = value; 
 }