window.showModal = function(url,method = 'GET'){
    $.ajax({
        type: method,
        url: url,
        dataType: 'script',
        success: function(data, textStatus, jqXHR){
        },
        error:function(jqXHR, textStatus, errorThrown){
            console.log("AJAX Error: #{textStatus}")
        }
    });
}
window.AjaxSubmit = function(url,method = 'GET',data = null){
    if(!url.includes(window.location.origin)) url = window.location.origin + url;
    $.ajax({
        type: method,
        url: url,
        dataType: 'script',
        data: data,
        success: function(data, textStatus, jqXHR){
        },
        error:function(jqXHR, textStatus, errorThrown){
            console.log("AJAX Error: #{textStatus}")
        }
    });        
}

window.loadTouchSpin = function() {
    const minusButton = document.getElementsByClassName('btn-minus');
    const plusButton = document.getElementsByClassName('btn-plus');

    for (var i = 0; i < minusButton.length; i++) {
      var clonedElement = minusButton[i].cloneNode(true);
      minusButton[i].parentNode.replaceChild(clonedElement, minusButton[i]);
    }
  
    for (var i = 0; i < plusButton.length; i++) {
      var clonedElement = plusButton[i].cloneNode(true);
      plusButton[i].parentNode.replaceChild(clonedElement, plusButton[i]);
    }
    
    if(minusButton && minusButton){
        for (var i=0; i < minusButton.length; i++) {
            minusButton[i].addEventListener("click", function(){
                var current_input = this.parentElement.children[1];               
                var current_value = parseInt(current_input.value.replace(/,/g, ""));
                parseInt(current_value - 1) < 0.0 ? current_input.value = parseInt(0) : current_input.value = parseInt(current_value - 1).toLocaleString('en-US');
                caculate_and_show_result_measurement_detail(this, current_input);
              });
            }
        
            for (var i=0; i < plusButton.length; i++) {
            plusButton[i].addEventListener("click", function(){
                var current_input = this.parentElement.children[1];
                var current_value = parseInt(current_input.value.replace(/,/g, ""));
                current_input.value = parseInt(current_value + 1).toLocaleString('en-US');
                caculate_and_show_result_measurement_detail(this, current_input);
            });
        }
    }
}

function caculate_and_show_result_measurement_detail(current_sign, current_input){
  var new_current = current_input.value.replace(/,/g, "");
  var measurememt_type = $(current_sign).closest(".measurement-option-zone").data("item");
  var caculate_type_3_condition = "3,4,5";
  var measurement_row_value = 0;
  var dimensions_width = parseFloat(change_type_number_value($(current_sign).closest(".measurement-row").find(".dimensions-width-value").val()));
  if (caculate_type_3_condition.includes(measurememt_type)){
    measurement_row_value = parseFloat((dimensions_width*new_current/1000).toFixed(2)) ;
  }else{
    var dimensions_height = parseFloat(change_type_number_value($(current_sign).closest(".measurement-row").find(".dimensions-height-value").val()));
    if (measurememt_type === 1){
      measurement_row_value = parseFloat(((dimensions_width/1000 + dimensions_height/1000)*4*new_current).toFixed(2)) ;
    } else if (measurememt_type === 2){
      measurement_row_value = parseFloat(((dimensions_width/1000 + dimensions_height/1000)*2*new_current).toFixed(2)) ;
    } else {
      measurement_row_value = 0;
    }
  } 
  measurement_row_value = measurement_row_value.toLocaleString('en-US')
	var parts = measurement_row_value.split('.');
  if (parts[1] && parts[1].length === 1) {
    measurement_row_value += '0';
  }

  measurement_row_value = measurement_row_value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  var attr = $(current_sign).closest(".measurement-row").find(".measurement-row-value");
  attr.val(measurement_row_value);
  show_final_unit_price(attr);
  show_total_measurement_cost(current_sign);
}

window.loadMeasurementInfo = function(){
	var delete_measurement_info = $('.delete-new-measurement-info');
	delete_measurement_info.each(function(index, link) {
	$(link).on('click', function(event) {
			event.preventDefault();
			window.$('#modal_show_confirm_delete_measurement').modal('show');
			$('#confirm_delete_measurement_info').on('click', function(event) {
				window.$('#modal_show_confirm_delete_measurement').modal('hide')
        $(link).closest(".measurement-field").find("#remove_new_measurement_info").click();
			});
    });
	});
}
export function move_top_item(list, item) {
  var count = 0;
    $.each($(list + ' .selected_item'), function (i, row) {
      count = count + 1
    });
    if (count > 0){
      var first_elem = $(list + " " + item).first()[0];
      if(first_elem.classList.contains("selected_item") == true) return true;
      while($(list + " " + item).first()[0].classList.contains("selected_item") != true){
        $(list + ' .selected_item').each(function (i, selected) {
          if (this.previousElementSibling == null) return false;
          $(this).insertBefore(this.previousElementSibling);
          $(list + ' .selected_item')[0].scrollIntoView();
        });
      } 
    }
}


export function move_up_item(list) {
    $(list + ' .selected_item').each(function (i, selected) {
      if (this.previousElementSibling == null) return false;
      $(this).insertBefore(this.previousElementSibling);
      $(list + ' .selected_item')[0].scrollIntoView();
    });
  }
export function move_down_item(list) {
    $($(list + ' .selected_item').get().reverse()).each(function (i, selected) {
      if (this.nextElementSibling == null) return false;
      $(this).insertAfter(this.nextElementSibling); 
      $(list + " .selected_item").last()[0].scrollIntoView();    
    });   
  }

export function move_bottom_item(list, item) {
  var count = 0;
  $.each($(list + ' .selected_item'), function (i, row) {
    count = count + 1
  });
  if (count > 0){
    if($(list + " " + item).last().length != 0)
    var last_elem = $(list + " " + item).last()[0];
    if(last_elem.classList.contains("selected_item") == true) return true;
    while($(list + " " + item).last()[0].classList.contains("selected_item") != true){
      $($(list + ' .selected_item').get().reverse()).each(function (i, selected) {
        if (this.nextElementSibling == null) return false;
        $(this).insertAfter(this.nextElementSibling);
        $(list + " .selected_item").last()[0].scrollIntoView();
      });
    }
  }
}

export function call_sortable_list(list) {
  $(list).sortable({
    axis: "y",
    containment: "parent",
    cursor: "move",
    items: "li",
    tolerance: "pointer",
    helper:'clone',
  });
}

var lastChecked = null;
export function selected_item(item, item_this){
  if(!lastChecked) {
    lastChecked = item_this;
    // return;
  }
  if (window.event.ctrlKey) {
    item_this.classList.toggle("selected_item");
  } else if (window.event.shiftKey) {
    var start = $(item).index(item_this);
    var end = $(item).index(lastChecked);
    $(item).slice(Math.min(start,end), Math.max(start,end)+ 1).addClass('selected_item');
  } else {
    $(item_this).toggleClass("selected_item").siblings().removeClass('selected_item');
  }
  lastChecked = item_this;
}

export function update_index_item(list, url){
  var lst_item = [];
  $(list + " li").each(function () {
    lst_item.push($(this).val());
  });
  $.ajax({
    type: 'GET',
    url: url,
    dataType: 'script',
    data: {
      lst_item: lst_item,
    },
    success: function (data, textStatus, jqXHR) {
        
    },
    error: function (jqXHR, textStatus, errorThrown) {
      console.log("AJAX Error: #{textStatus}")
    }
  });
}

window.format_datepicker = function(id, text_field){
	var dt = document.querySelector(id);
	if(dt){
    $(id).datepicker({
      language: 'jp',
      autoclose: true,
      sideBySide: true,   
    }).on('changeDate', function(e) {
      document.querySelector(text_field).value = $(id).datepicker('getDate').toLocaleDateString("en-US");
    });
    var today = new Date();
    const textField = document.querySelector(text_field);
    if(textField && (textField.value === '' || textField.value === null)){				
    }
    else{
      var dt =new Date( Date.parse(document.querySelector(text_field).value));
      $(id).datepicker('setDate', dt);
    }
  }
}

window.format_datepicker_current_day = function(id, text_field){
	var dt = document.querySelector(id);
	if(dt){
    $(id).datepicker({
      language: 'jp',
      autoclose: true,
      sideBySide: true,   
    }).on('changeDate', function(e) {
      document.querySelector(text_field).value = $(id).datepicker('getDate').toLocaleDateString("en-US");
    });
    var today = new Date();
    const textField = document.querySelector(text_field);
    if(textField && (textField.value === '' || textField.value === null)){
      $(id).datepicker('setDate', today);			
    }
    else{
      var dt =new Date( Date.parse(document.querySelector(text_field).value));
      $(id).datepicker('setDate', dt);
    }
  }
}


window.open_extension_modal = function(value, url){
  $.ajax({
    type: 'GET',
    url: url,
    dataType: 'script',
    data: {
      value: value,
    },
    success: function (data, textStatus, jqXHR) {
        
    },
    error: function (jqXHR, textStatus, errorThrown) {
      console.log("AJAX Error: #{textStatus}")
    }
  });
}
 
window.bindItemDropdownEvents = function(dropdownSelector, textSelector, inputSelector, addNewSelector, deleteSelector, editSelector, deleteUrl, editUrl, addNewUrl) {
  $('body').delegate(dropdownSelector + ' li.item-container', 'click', function(e) {
      var labelText = $(this).find('span').text(); //lấy text trong thẻ span nằm trong thẻ li của dòng đang được click vào
      var checkboxValue = $(this).data('item-id'); //lấy item-id của thẻ li
      $(this).closest('.custom-dropdown').find(textSelector).text(labelText); //gán text vào trong thẻ div nằm bên trong thẻ a có class custom-dropdown
      $(inputSelector).val(checkboxValue); //gán value vào trong id của input ẩn
  });

  $("body").delegate(dropdownSelector + ' .icon-container button', 'click', function(e) {
      e.stopPropagation();
  });

  $("body").delegate(addNewSelector, 'click', function(e) {
      e.stopPropagation();
      open_extension_modal('', addNewUrl)
  });


  $("body").delegate(deleteSelector,'click', function(e) {
      e.stopPropagation();
      var selectedValues = $(this).data('item-id');
      if (selectedValues.length === 0) {
          return;
      } else {
        open_extension_modal(selectedValues, deleteUrl)
      }
  });

  $("body").delegate(editSelector,'click', function(e) {
      e.stopPropagation();
      var selectedValues = $(this).data('item-id');
      if (selectedValues.length === 0) {
          return;
      } else {
        open_extension_modal(selectedValues, editUrl)
      }
  });
}

window.format_jp_money_value_load = function() {
  $('.jpy-input-field:not(.measurement-row-value, .measurement-total, .final-price-value)').each(function() {
    set_jp_value(this, 1, "load");
  });  
  $('.measurement-row-value.jpy-input-field , .measurement-total.jpy-input-field, .final-price-value.jpy-input-field').each(function() {
    set_jp_value(this, 2, "load");
  });
  $('.quantity-value').each(function() {
    set_number_value(this, "load");
  });
}
window.format_jp_money_value_change = function() {
  $('.jpy-input-field:not(.measurement-row-value, .measurement-total, .final-price-value)').on('input', function() {
    set_jp_value(this, 1, "input");
  });
  $('.measurement-row-value.jpy-input-field , .measurement-total.jpy-input-field, .final-price-value.jpy-input-field').on('input', function() {
    set_jp_value(this, 2, "input");
  });
  $('.quantity-value').on('input', function() {
    set_number_value(this, "input");
  });
}


window.set_number_value = function(current, action) {
  var value = current.value.replace(/[^0-9]/g, '').replace(/^0+/,'');
      
      // Add commas every three digits
  var formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (action === "input") {
    $(current).val(formattedValue);
  } else {
    $(current).text(formattedValue);
  }
}

//type check that field contain class to calculate total like measurement-row-value
window.set_jp_value = function(element, type, action) {
  var input = $(element);
  var inputValue = input.val() || input.text();
  var formattedNumber = handle_float_number(input, inputValue, type, action);

  if (input.is('input')) {
    input.val(formattedNumber);
  } else {
    input.text(formattedNumber);
  }
}

function handle_float_number(input, inputValue, type, action){
  if (inputValue === '00') {
    input.text('0');
    return;
  }

  // Remove all characters except digits and a single dot
  var cleanedValue = inputValue.replace(/[^\d.]/g, '');

  // Remove extra consecutive dots
  cleanedValue = cleanedValue.replace(/\.{2,}/g, '.');

  // Split the value into integer and decimal parts
  var parts = cleanedValue.split('.');
  var integerPart = parts[0] || '';
  var decimalPart = parts[1] || '';

  // Enforce two decimal places only
  if (decimalPart.length > 2) {
    decimalPart = decimalPart.slice(0, 2);
  }

  // Format the integer part with commas
  var formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine the formatted integer and decimal parts
  var formattedNumber = formattedInteger + (decimalPart ? '.' + decimalPart : '');

  // Remove leading zeros before the integer part
  if (/^0+[1-9]/.test(inputValue)) {
    formattedNumber = inputValue.replace(/^0+/, '');
  }

  // Ensure there's a trailing dot if inputValue ends with a dot
  if (inputValue.endsWith('.') && decimalPart === '') {
    formattedNumber += '.';
  }

  // Convert the formatted input string to a numeric value
  var inputString = formattedNumber;
  var cleanedString = inputString.replace(/,/g, '');
  var numberValue = parseFloat(cleanedString);

  if (type === 2 && action === 'load') {
    // Format as 2 decimal places for type 2
    formattedNumber = numberValue.toFixed(2);
    formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    if (action === 'load') {
      // Check if the decimal part is '0', and if so, format as an integer
      var decimalPart = numberValue.toString().split('.')[1];
      decimalPart === '0' ? (formattedNumber = parseInt(numberValue).toString()) : (formattedNumber = numberValue.toString());
      formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  return formattedNumber;
}


window.change_type_number_value = function(val){
	var new_val = val.replace(/,/g, '');
	return new_val;
}

window.show_total_measurement_cost = function(current){
	// var total_all = $("#"+$(current).closest(".measurement-type-zone").find(".measurement-total").attr("id"));
	// var total_all_cost = $("#"+$(current).closest(".measurement-field").find(".measurement-total-cost").attr("id"));
	// var total = 0;
	// var total_all_rows_in_zone = 0;
	// var value = $(current).closest(".measurement-type-zone").find(".measurement-row-value");
	// value.each(function (index, elem) {
	// 	if($(elem).closest('.measurement-row:not([style*="display: none"])').length !== 0 ){
	// 		current_value = change_type_number_value(this.value);
	// 		if (!Number.isNaN(parseFloat(current_value, 10))) total_all_rows_in_zone += parseFloat(current_value, 10);
	// 		if (total_all_rows_in_zone <= 0){
	// 			total_all_cost.html("0");
	// 		}
	// 		total_all.html(check_number_type(parseFloat(total_all_rows_in_zone)).toLocaleString('en-US'));
	// 	}
	// });
	// var measurement_option_zone = $(current).closest(".measurement-list-option-field").find(".measurement-option-zone:not(.d-none)");
	// measurement_option_zone.each(function (index, zone) {
	// 	var total_each_type = parseFloat(change_type_number_value($("#"+$(zone).find(".measurement-total").attr("id")).html()));
	// 	total += total_each_type;
	// });
	// var unit_price_cost =  $(current).closest(".measurement-field").find(".unit-price-cost").val();
	// unit_price_cost = change_type_number_value(unit_price_cost);
	// if (unit_price_cost){
	// 	var sum_all_cost = check_number_type(parseFloat(total*parseFloat(unit_price_cost)).toFixed(2));
	// 	total_all_cost.html(sum_all_cost.toLocaleString('en-US'));
	// } else {
	// 	total_all_cost.html("0");
	// }
	// format_jp_money_value_change();
}

window.show_total_measurement_cost_load = function(current){
	// var total_all_cost = $("#"+$('#'+current).find(".measurement-total-cost").attr("id"));
	// var measurement_option_zone = $('#'+current).find(".measurement-option-zone:not(.d-none)");
	// var total = 0
	// measurement_option_zone.each(function (index, zone) {
	// 	var value = $(zone).find(".measurement-row-value");
	// 	var total_all = $("#"+$(zone).find(".measurement-total").attr("id"));
	// 	var total_all_rows_in_zone = 0;
	// 	value.each(function (index, elem) {
	// 		if($(elem).closest('.measurement-row:not([style*="display: none"])').length !== 0 ){
	// 			if (!Number.isNaN(parseFloat(this.value, 10))){
	// 				total_all_rows_in_zone += parseFloat(this.value, 10);
	// 				total += parseFloat(this.value, 10);;
	// 			}
	// 			if (total_all_rows_in_zone <= 0){
	// 				total_all_cost.html("0");
	// 			}
	// 			total_all.html(check_number_type(parseFloat(total_all_rows_in_zone)).toLocaleString('en-US'));
	// 		}
	// 	});
	// });
	// var unit_price_cost =  $('#'+current).closest(".measurement-field").find(".unit-price-cost").val();
	// unit_price_cost = change_type_number_value(unit_price_cost);
	// if (unit_price_cost){
	// 	var sum_all_cost = check_number_type(parseFloat(total*parseFloat(unit_price_cost)));
	// 	total_all_cost.html(sum_all_cost.toLocaleString('en-US'));
	// } else {
	// 	total_all_cost.html("0");
	// }
	// format_jp_money_value_change();
}

window.show_total_measurement_cost_after_delete = function(current){
	// var total_all = $("#"+$(current).closest(".measurement-type-zone").find(".measurement-total").attr("id"));
	// var total_all_cost = $("#"+$(current).closest(".measurement-field").find(".measurement-total-cost").attr("id"));
	// var total = 0;
	// var total_all_rows_in_zone = 0;
	// var value = $(current).closest(".measurement-type-zone").find(".measurement-row-value");
	// value.each(function (index, elem) {
	// 	if( !$(elem).closest(".measurement-row")[0].isEqualNode($(current).closest(".measurement-row")[0]) ){
	// 		if($(elem).closest('.measurement-row:not([style*="display: none"])').length !== 0 ){
	// 			current_value = change_type_number_value(this.value);
	// 			if (!Number.isNaN(parseFloat(current_value, 10))) total_all_rows_in_zone += parseFloat(current_value, 10);
	// 			if (total_all_rows_in_zone <= 0){
	// 				total_all_cost.html("0");
	// 			}
	// 			total_all.html(check_number_type(parseFloat(total_all_rows_in_zone)).toLocaleString('en-US'));
	// 		}
	// 	}
	// });
	// var measurement_option_zone = $(current).closest(".measurement-list-option-field").find(".measurement-option-zone:not(.d-none)");
	// measurement_option_zone.each(function (index, zone) {
	// 	var total_each_type = parseFloat(change_type_number_value($("#"+$(zone).find(".measurement-total").attr("id")).html()));
	// 	total += total_each_type;
	// });
	// var unit_price_cost =  $(current).closest(".measurement-field").find(".unit-price-cost").val();
	// unit_price_cost = change_type_number_value(unit_price_cost);
	// if (unit_price_cost){
	// 	var sum_all_cost = check_number_type(parseFloat(total*parseFloat(unit_price_cost)));
	// 	total_all_cost.html(sum_all_cost.toLocaleString('en-US'));
	// } else { 
	// 	total_all_cost.html("0");
	// }
	// format_jp_money_value_change();
}

window.show_final_unit_price = function(measurement_total) {
  var measurement_total_val = $(measurement_total).val();
  measurement_total_val = change_type_number_value(measurement_total_val);
  var product_value = $(measurement_total).closest(".measurement-row").find(".product-price-value").val();
  product_value = change_type_number_value(product_value);
  var final_value = parseFloat(measurement_total_val * product_value).toFixed(2);

  // Add trailing zeros if needed
  var parts = final_value.split('.');
  if (parts[1] && parts[1].length === 1) {
    final_value += '0';
  }

  final_value = final_value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  $(measurement_total).closest(".measurement-row").find(".final-price-value").val(final_value);
}
