$(document).on('turbolinks:load', function () {
    formatTableUser();
    formatTableWorkReport();
    formatTableSupplierCategories();
    formatTableLocations();
    formatTableDepositMethod();
    formatTableDepositMethod();
    formatTableCustomerCategories();
    formatTableProductCategories();
    formatTableUnits();
    formatTablePaymentMethod();
    formatTablePostcode();
    formatTableCustomer();
    formatTableSupplier();
    formatTableMasterProduct();
    formatTableOnsite();
});

window.formatTableUser = function(){
    var tblUser = document.getElementsByClassName("tbl_user");
    
    if (tblUser.length > 0  ) {
        tblUser = window.$('.tbl_user').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
           'ordering': false,
           'columnDefs': [
            { "width": "5%", "targets": 0 },
            { "width": "15%", "targets": 1 },
            { "width": "25%", "targets": 2 },
            { "width": "25%", "targets": 3 },
            { "width": "10%", "targets": 4 },
            { "width": "10%", "targets": 5 },
            { "width": "10%", "targets": 6 },
            ],
            drawCallback: function() {
            $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
            previous:"<i class='mdi mdi-chevron-left'>",
            next:"<i class='mdi mdi-chevron-right'>"}},
        });
        if (tblUser.rows().count() > 0) {
            $('.tbl_user tbody').on('click', 'tr', function () {
                var objectDataSubmit = {id: this.getAttribute('data-item-id')};
                AjaxSubmit( '/users/edit_user','GET',objectDataSubmit);
            });
        }
    }
    change_table_responsive("#tbl_user");
}

window.formatTableWorkReport = function(){
    var table = document.getElementsByClassName("tbl_work_report");    
    if (table.length > 0) {
        table = window.$('.tbl_work_report').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
           'ordering': false,
           'columnDefs': [
            { "width": "10%", "targets": 0 },
            {
                'targets': 1,
                'width': "15%",               
            },
            { "width": "15%", "targets": 2 },
            { "width": "20%", "targets": 3 },
            { "width": "10%", "targets": 4 }, 
            { "width": "10%", "targets": 5 },    
            { "width": "10%", "targets": 6 },    
            { "width": "10%", "targets": 7 },          
            ],
           
            'order': [],
            drawCallback: function() {
            $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
            previous:"<i class='mdi mdi-chevron-left'>",
            next:"<i class='mdi mdi-chevron-right'>"}},
        });
        if (table.rows().count() > 0) {
            $('.tbl_work_report tbody').on('click', 'tr td:nth-child(-n+6)', function(e) {
                if ($(e.target).is('select')) {
                    return;
                }
                var objectDataSubmit = { id: $(this).closest('tr').data('item') };
                $.ajax({
                    url: '/work_reports/show_work_report_detail',
                    type: 'GET',
                    data: objectDataSubmit,
                    success: function(response) {
                        window.$("#modal_show_work_report").modal('show');
                    },
                    error: function(xhr, status, error) {
                    }
                  });
            });
        }
    }
    change_table_responsive("#tbl_work_report");
}

window.formatTableSupplierCategories= function(){
    var tbl_supplier_category = document.getElementsByClassName("tbl_supplier_category");  
    if (tbl_supplier_category.length > 0) {
        tbl_supplier_category = window.$('.tbl_supplier_category').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
           'ordering': false,
           'columnDefs': [
                { "width": "10%", "targets": 0 },
                { "width": "10%", "targets": 1 },
                { "width": "80%", "targets": 2 },
                ],
               
           
            'order': [],
            drawCallback: function() {
            $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
            previous:"<i class='mdi mdi-chevron-left'>",
            next:"<i class='mdi mdi-chevron-right'>"}},
        });
        if (tbl_supplier_category.rows().count() > 0) {
            $('.tbl_supplier_category tbody').on('click', 'tr', function () {
                var objectDataSubmit = {id: this.getAttribute('data-item-id')};
                AjaxSubmit( '/supplier_categories/edit_supplier_category','GET', objectDataSubmit);
            });
        }
    } 
    change_table_responsive("#tbl_supplier_category");
}

window.formatTableLocations = function(){
    var tbl_location = document.getElementsByClassName("tbl_location");  
    if (tbl_location.length > 0) {
        tbl_location = window.$('.tbl_location').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
           'ordering': false,
           'columnDefs': [
                { "width": "10%", "targets": 0 },
                { "width": "10%", "targets": 1 },
                { "width": "80%", "targets": 2 },
                ],
               
           
            'order': [],
            drawCallback: function() {
            $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
            previous:"<i class='mdi mdi-chevron-left'>",
            next:"<i class='mdi mdi-chevron-right'>"}},
        });
        if (tbl_location.rows().count() > 0) {
            $('.tbl_location tbody').on('click', 'tr', function () {
                var objectDataSubmit = {id: this.getAttribute('data-item-id')};
                AjaxSubmit( '/locations/edit_location','GET', objectDataSubmit);
            });
        }
    } 
    change_table_responsive("#tbl_location");
}

window.formatTableDepositMethod = function(){
    var tbl_deposit_method = document.getElementsByClassName("tbl_deposit_method");  
    if (tbl_deposit_method.length > 0) {
        tbl_deposit_method = window.$('.tbl_deposit_method').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
           'ordering': false,
           'columnDefs': [
                { "width": "10%", "targets": 0 },
                { "width": "10%", "targets": 1 },
                { "width": "80%", "targets": 2 },
                ],
               
           
            'order': [],
            drawCallback: function() {
            $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
            previous:"<i class='mdi mdi-chevron-left'>",
            next:"<i class='mdi mdi-chevron-right'>"}},
        });
        if (tbl_deposit_method.rows().count() > 0) {
            $('.tbl_deposit_method tbody').on('click', 'tr', function () {
                var objectDataSubmit = {id: this.getAttribute('data-item-id')};
                AjaxSubmit( '/deposit_methods/edit_deposit_method','GET', objectDataSubmit);
            });
        }
    } 
    change_table_responsive("#tbl_deposit_method");
}

window.formatTableCustomerCategories = function(){
    var tbl_customer_category = document.getElementsByClassName("tbl_customer_category");  
    if (tbl_customer_category.length > 0) {
        tbl_customer_category = window.$('.tbl_customer_category').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
           'ordering': false,
           'columnDefs': [
                { "width": "10%", "targets": 0 },
                { "width": "10%", "targets": 1 },
                { "width": "80%", "targets": 2 },
                ],
               
           
            'order': [],
            drawCallback: function() {
            $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
            previous:"<i class='mdi mdi-chevron-left'>",
            next:"<i class='mdi mdi-chevron-right'>"}},
        });
        if (tbl_customer_category.rows().count() > 0) {
            $('.tbl_customer_category tbody').on('click', 'tr', function () {
                var objectDataSubmit = {id: this.getAttribute('data-item-id')};
                AjaxSubmit( '/customer_categories/edit_customer_category','GET', objectDataSubmit);
            });
        }
    } 
    change_table_responsive("#tbl_customer_category");

}

window.formatTableProductCategories = function(){
    var tbl_product_category = document.getElementsByClassName("tbl_product_category");  
    if (tbl_product_category.length > 0) {
        tbl_product_category = window.$('.tbl_product_category').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
           'ordering': false,
           'columnDefs': [
                { "width": "10%", "targets": 0 },
                { "width": "10%", "targets": 1 },
                { "width": "80%", "targets": 2 },
            ],
            'order': [],
            drawCallback: function() {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{
                paginate:{
                    previous:"<i class='mdi mdi-chevron-left'>",
                    next:"<i class='mdi mdi-chevron-right'>"
                }
            }
        });
        if (tbl_product_category.rows().count() > 0) {
            $('.tbl_product_category tbody').on('click', 'tr', function () {
                var objectDataSubmit = {id: this.getAttribute('data-item-id')};
                AjaxSubmit( '/product_categories/edit_product_category','GET', objectDataSubmit);
            });
        }
    } 
}

window.formatTableUnits = function(){
    var tbl_unit = document.getElementsByClassName("tbl_unit");  
    if (tbl_unit.length > 0) {
        tbl_unit = window.$('.tbl_unit').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
           'ordering': false,
           'columnDefs': [
                { "width": "10%", "targets": 0 },
                { "width": "10%", "targets": 1 },
                { "width": "80%", "targets": 2 },
                ],
               
           
            'order': [],
            drawCallback: function() {
            $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
            previous:"<i class='mdi mdi-chevron-left'>",
            next:"<i class='mdi mdi-chevron-right'>"}},
        });
        if (tbl_unit.rows().count() > 0) {
            $('.tbl_unit tbody').on('click', 'tr', function () {
                var objectDataSubmit = {id: this.getAttribute('data-item-id')};
                AjaxSubmit( '/units/edit_unit','GET', objectDataSubmit);
            });
        }
    }
}

window.formatTablePaymentMethod = function(){
    var tbl_payment_method = document.getElementsByClassName("tbl_payment_method");  
    if (tbl_payment_method.length > 0) {
        tbl_payment_method = window.$('.tbl_payment_method').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
           'ordering': false,
           'columnDefs': [
                { "width": "10%", "targets": 0 },
                { "width": "10%", "targets": 1 },
                { "width": "80%", "targets": 2 },
            ],
            'order': [],
            drawCallback: function() {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
                previous:"<i class='mdi mdi-chevron-left'>",
                next:"<i class='mdi mdi-chevron-right'>"
            }},
        });
        if (tbl_payment_method.rows().count() > 0) {
            $('.tbl_payment_method tbody').on('click', 'tr', function () {
                var objectDataSubmit = {id: this.getAttribute('data-item-id')};
                AjaxSubmit( '/payment_methods/edit_payment_method','GET', objectDataSubmit);
            });
        }
    }
}

window.formatTablePostcode = function(){
    var tbl_postcode = document.getElementsByClassName("tbl_postcode");  
    if (tbl_postcode.length > 0) {
        tbl_postcode = window.$('.tbl_postcode').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
           'ordering': false,
           'columnDefs': [
                { "width": "7%", "targets": 0 },
                { "width": "9%", "targets": 1 },
                { "width": "12%", "targets": 2 },
                { "width": "12%", "targets": 3 },
                { "width": "12%", "targets": 4 },
                { "width": "12%", "targets": 5 },
                { "width": "12%", "targets": 6 },
                { "width": "12%", "targets": 7 },
                { "width": "12%", "targets": 8 },
            ],
            'order': [],
            drawCallback: function() {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
                previous:"<i class='mdi mdi-chevron-left'>",
                next:"<i class='mdi mdi-chevron-right'>"
            }},
        });
        if (tbl_postcode.rows().count() > 0) {
            $('.tbl_postcode tbody').on('click', 'tr', function () {
                var postId = this.getAttribute('data-item-id');
                window.location.href = '/postcodes/' + postId + '/edit';
            });
        }
    }
}

window.formatTableCustomer = function(){
    var tbl_customer = document.getElementsByClassName("tbl_customer");  
    if (tbl_customer.length > 0) {
        tbl_customer = window.$('.tbl_customer').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
           'ordering': false,
            'columnDefs': [
                { "width": "5%", "targets": 0 },
                { "width": "12%", "targets": 1 },
                { "width": "7%", "targets": 2 },
                { "width": "5%", "targets": 3 },
                { "width": "12%", "targets": 4 },
                { "width": "7%", "targets": 5 },
                { "width": "8%", "targets": 6 },
                { "width": "7%", "targets": 7 },
                { "width": "5%", "targets": 8 },
                { "width": "7%", "targets": 9 },
                { "width": "7%", "targets": 10 },
                { "width": "5%", "targets": 11 },
                { "width": "6%", "targets": 12 },
                { "width": "7%", "targets": 13 },
            ],
            'order': [],
            drawCallback: function() {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
                previous:"<i class='mdi mdi-chevron-left'>",
                next:"<i class='mdi mdi-chevron-right'>"
            }},
        });
        if (tbl_customer.rows().count() > 0) {
            $('.tbl_customer tbody').on('click', 'tr', function () {
                var postId = this.getAttribute('data-item-id');
                window.location.href = '/customers/' + postId + '/edit';
            });
        }
    }
}


window.formatTableSupplier = function(){
    var tbl_supplier = document.getElementsByClassName("tbl_supplier");  
    if (tbl_supplier.length > 0) {
        tbl_supplier = window.$('.tbl_supplier').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
           'ordering': false,
            'columnDefs': [
                { "width": "5%", "targets": 0 },
                { "width": "12%", "targets": 1 },
                { "width": "7%", "targets": 2 },
                { "width": "5%", "targets": 3 },
                { "width": "12%", "targets": 4 },
                { "width": "7%", "targets": 5 },
                { "width": "8%", "targets": 6 },
                { "width": "7%", "targets": 7 },
                { "width": "5%", "targets": 8 },
                { "width": "7%", "targets": 9 },
                { "width": "7%", "targets": 10 },
                { "width": "5%", "targets": 11 },
                { "width": "6%", "targets": 12 },
                { "width": "7%", "targets": 13 },
            ],
            'order': [],
            drawCallback: function() {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
                previous:"<i class='mdi mdi-chevron-left'>",
                next:"<i class='mdi mdi-chevron-right'>"
            }},
        });
        if (tbl_supplier.rows().count() > 0) {
            $('.tbl_supplier tbody').on('click', 'tr', function () {
                var postId = this.getAttribute('data-item-id');
                window.location.href = '/suppliers/' + postId + '/edit';
            });
        }
    }
}

window.formatTableMasterProduct = function(){
    // var numFlexibleColumns = $('.tbl_master_product thead tr:eq(1) th').length - 7;
    // var remainingWidth = 60;
    // var flexibleColumnWidth = remainingWidth / numFlexibleColumns;

    // var columns = [
    //     { "width": "5%" },
    //     { "width": "15%" },
    //     { "width": "10%" },
    // ];

    // for (var i = 0; i < numFlexibleColumns; i++) {
    //     columns.push({ "width": flexibleColumnWidth+"%" });
    // }

    // columns.push(
    //     { "width": "8%" },
    //     { "width": "8%" }, 
    //     { "width": "8%" }, 
    //     { "width": "8%" }, 
    //     { "width": "6%" }, 
    // );

    var tbl_master_product = document.getElementsByClassName("tbl_master_product");  
    if (tbl_master_product.length > 0) {
        tbl_master_product = window.$('.tbl_master_product').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
            'ordering': false,
            'columnDefs': [
                { "width": "5%", "targets": 0 },
                { "width": "15%", "targets": 1 },
                { "width": "10%", "targets": 2 },
                { "width": "10%", "targets": 3 },
                { "width": "10%", "targets": 4 },
                { "width": "10%", "targets": 5 },
                { "width": "10%", "targets": 6 },
                { "width": "10%", "targets": 7 },
                { "width": "8%", "targets": 8 },
                { "width": "8%", "targets": 9 },
                { "width": "4%", "targets": 10 },
            ],
            drawCallback: function() {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
                previous:"<i class='mdi mdi-chevron-left'>",
                next:"<i class='mdi mdi-chevron-right'>"
            }},
        });
        if (tbl_master_product.rows().count() > 0) {
            $('.tbl_master_product tbody').on('click', 'tr', function () {
                var postId = this.getAttribute('data-item-id');
                window.location.href = '/master_products/' + postId + '/edit';
            });
        }
    }
}

window.formatTableOnsite = function(){
    var tbl_onsite = document.getElementsByClassName("tbl_onsite");  
    if (tbl_onsite.length > 0) {
        tbl_onsite = window.$('.tbl_onsite').DataTable({
            'stateSave': false,
            "processing": false,
            "serverSide": false,
            'destroy': true,
           'ordering': false,
           'columnDefs': [
                { "width": "5%", "targets": 0 },
                { "width": "7%", "targets": 1 },
                { "width": "20%", "targets": 2 },
                { "width": "17%", "targets": 3 },
                { "width": "10%", "targets": 4 },
                { "width": "15%", "targets": 5 },
                { "width": "15%", "targets": 6 },
                { "width": "11%", "targets": 7 },
            ],
            'order': [],
            drawCallback: function() {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
                previous:"<i class='mdi mdi-chevron-left'>",
                next:"<i class='mdi mdi-chevron-right'>"
            }},
        });
        if (tbl_onsite.rows().count() > 0) {
            $('.tbl_onsite tbody').on('click', 'tr td:nth-child(-n+7)', function(e) {
                if ($(e.target).is('select')) {
                    return;
                }
                var objectDataSubmit = { id: $(this).closest('tr').data('item-id') };
                $.ajax({
                    url: '/onsites/edit_onsite',
                    type: 'GET',
                    data: objectDataSubmit,
                    success: function(response) {
                    },
                    error: function(xhr, status, error) {
                    }
                  });
            });
        }
    }
}



function change_table_responsive(id){
	var id  = $(id).find("#DataTables_Table_0_length").parent();
	var search_zone = $(id).find("#DataTables_Table_0_filter").parent();
	$(id).removeClass("col-md-6");
	$(id).removeClass("col-sm-12");
	$(id).addClass("col-sm-6");
	$(search_zone).removeClass("col-md-6");
	$(search_zone).removeClass("col-sm-12");
	$(search_zone).addClass("col-sm-6");
}