import {Config} from '../define/AppConfig';
import {Convert} from '../../convert';
import { link_enum } from '../edit/annotation';
var signalFabricCanvas;
export class SettingToolbar{  
    eventBus = null  
    constructor(options, eventBus){    
        this.eventBus = eventBus;
        events(this.eventBus);
    }   
}
function events(eventBus){
    addEvents(eventBus);
    eventCommon();
}
function eventCommon(){
    $("body").delegate('.color-select .color-item', 'click', function(ev) {
        var active = 'selected';
        $('.color-select .color-item').removeClass(active);
        $('.color-select .input-color-container').removeClass(active);
        this.classList.add(active);
    })
    $("body").delegate('.dash-style .toolbarLineDashStyle li', 'click', function(ev) {
        var active = 'selected';
        $('.dash-style .toolbarLineDashStyle li').removeClass(active);
        this.classList.add(active);
    })
    $("body").delegate('.color-select .input-color', 'input', function(ev) {
        $('.color-select .color-item').removeClass("selected");
        this.closest('.input-color-container').classList.add('selected');
    })
    $("body").delegate('.buttonToggle', 'click', function(ev) {
        toggle(this);
    })  
    $("body").delegate('.top-col-left .edit-button', 'click', function(ev) {
        var buttons = document.querySelector('.top-col-left').querySelectorAll(`.edit-button`);
        for (let index = 0; index < buttons.length; index++) {
            if(buttons[index].id != this.id){
                buttons[index].setAttribute('selected',false);            
            }
            else{
                this.setAttribute('selected',true);
            }
            
        }    
    })  
    
}
function addEvents(eventBus){    
    $("body").delegate('.text-properties #fontFamilyEdit', 'change', function() {
        Config.text.addtext.fontFamily = this.value;
    })
    $("body").delegate('.text-properties #fontSizeEdit', 'change', function() {
        Config.text.addtext.fontSize = parseInt(this.value);
    })
    $("body").delegate('.text-properties .color-select .color-item', 'click', function(ev) {
        var color = Convert.rgbToHex(this.style.backgroundColor);
        Config.text.addtext.fill = color;
        el('colorTextEdit').style.backgroundColor = color;
    })
    $("body").delegate('.text-properties .color-select .input-color', 'input', function(ev) {
        Config.text.addtext.fill = this.value;   
        el('colorTextEdit').style.backgroundColor = this.value;
    })

    $("body").delegate('.text-properties #boldTextEdit', 'click', function() {
        Config.text.addtext.boldText = !Config.text.addtext.boldText;
    })
    $("body").delegate('.text-properties #italicTextEdit', 'click', function() {
        Config.text.addtext.italicText = !Config.text.addtext.italicText;
    })
    // hight light
    $("body").delegate('.text-highlight-properties .color-select .input-color', 'input', function(ev) {
        Config.text.highlight.color = this.value;   
        el('colorHighLight').style.backgroundColor = this.value;
    })
    $("body").delegate('.text-highlight-properties .color-select .color-item', 'click', function(ev) {
        var color = Convert.rgbToHex(this.style.backgroundColor);
        Config.text.highlight.color = color;
        el('colorHighLight').style.backgroundColor = color;
    })
    //
    // strokeout
    $("body").delegate('.text-strokeout-properties .color-select .input-color', 'input', function(ev) {
        Config.text.strokeout.color =   this.value;   
        el('colorStrokeOut').style.backgroundColor = this.value;
    })
    $("body").delegate('.text-strokeout-properties .color-select .color-item', 'click', function(ev) {
        var color = Convert.rgbToHex(this.style.backgroundColor);
        Config.text.strokeout.color = color;
        el('colorStrokeOut').style.backgroundColor = color;
    })
    //
    // underline
    $("body").delegate('.text-underline-properties .color-select .input-color', 'input', function(ev) {
        Config.text.underline.color = this.value;   
        el('colorUnderLine').style.backgroundColor = this.value;
    })
    $("body").delegate('.text-underline-properties .color-select .color-item', 'click', function(ev) {
        Config.text.underline.color = this.style.backgroundColor;
        el('colorUnderLine').style.backgroundColor = this.style.backgroundColor;
    })
    $("body").delegate('.c_stamp_personal, .c_stamp_manager,.c_stamp_company,.canvas_signal', 'click', function(ev) {        
        setToggle(ev.target);
    })
    //
    // line
    $("body").delegate('.shape-line-properties .color-select .input-color', 'input', function(ev) {
        Config.shape.line.backGroundColor = this.value;   
        el('lineItemColor').style.backgroundColor = this.value;
    })
    $("body").delegate('.shape-line-properties .color-select .color-item', 'click', function(ev) {
        var color = Convert.rgbToHex(this.style.backgroundColor);
        Config.shape.line.backGroundColor = color;
        el('lineItemColor').style.backgroundColor = color;
    })
    $("body").delegate('.shape-line-properties #lineItemOpacity', 'change', function(ev) {   
        Config.shape.line.opacity = this.value;
    })
    
    // arrow
    $("body").delegate('.shape-arrow-properties .linewidthitem', 'click', function(ev) {   
        Config.shape.arrow.size = parseInt(this.querySelector('.bar').style.height);
        let children = document.querySelectorAll(".shape-arrow-properties .linewidthitem");
        children.forEach(child => {
            child.classList.remove('selected')
        })  
        document.querySelector(`.shape-arrow-properties .linewidthitem[data-item="${Config.shape.arrow.size}"]`).classList.add('selected');
    })
    
    $("body").delegate('.shape-arrow-properties .color-select .input-color', 'input', function(ev) {
        Config.shape.arrow.color = this.value;   
        el('arrowColor').style.backgroundColor = this.value;
    })
    $("body").delegate('.shape-arrow-properties .color-select .color-item', 'click', function(ev) {
        var color = Convert.rgbToHex(this.style.backgroundColor);
        Config.shape.arrow.color = color;
        el('arrowColor').style.backgroundColor = color;
    })

    $("body").delegate('.shape-arrow-properties .dropdownArrowStyle li', 'click', function(ev) {          
        Config.shape.arrow.position_arrow = parseInt(this.querySelector('img').getAttribute('arrow'));
        document.getElementById("btnSelectArrowStyle").querySelector('img').setAttribute('src',this.querySelector('img').getAttribute('src'));
    })
    
    $("body").delegate('#buttonid', 'click', function() {	
        document.getElementById('fileid').click();
     })
    
    //
   // ellipse
   $("body").delegate('.shape-ellipse-properties .color-select .input-color', 'input', function(ev) {
        Config.shape.ellipse.stroke = this.value;   
        el('ellipsetemColor').style.backgroundColor = this.value;
    })
    $("body").delegate('.shape-ellipse-properties .color-select .color-item', 'click', function(ev) {
        var color = Convert.rgbToHex(this.style.backgroundColor);
        Config.shape.ellipse.stroke = color;
        el('ellipsetemColor').style.backgroundColor = color;
    })

    $("body").delegate('.shape-ellipse-properties #ellipseItemOpacity', 'change', function(ev) {   
        Config.shape.ellipse.opacity = this.value;
    })
    // rectangle
   $("body").delegate('.shape-rectangle-properties .color-select .input-color', 'input', function(ev) {
        Config.shape.rectangle.stroke = this.value;   
        el('rectangleItemColor').style.backgroundColor = this.value;
    })
    $("body").delegate('.shape-rectangle-properties .color-select .color-item', 'click', function(ev) {
        var color = Convert.rgbToHex(this.style.backgroundColor);
        Config.shape.rectangle.stroke = color;
        el('rectangleItemColor').style.backgroundColor = color;
    })   
    $("body").delegate('.shape-rectangle-properties #rectangleItemOpacity', 'change', function(ev) {   
        Config.shape.rectangle.opacity = this.value;
    })
     // pencil
   $("body").delegate('.pencil-properties .color-select .input-color', 'input', function(ev) {
        el('pencilStrokeColor').style.backgroundColor =  this.value;
        eventBus.dispatch("brushChangeStrokeColor", {
            source: null,
            color: this.value, 
        }); 
    })
    $("body").delegate('.pencil-properties .color-select .color-item', 'click', function(ev) {
        var color = Convert.rgbToHex(this.style.backgroundColor);
        el('pencilStrokeColor').style.backgroundColor =  color;
        eventBus.dispatch("brushChangeStrokeColor", {
            source: null,
            color: color,        
        }); 
    })
   
    $("body").delegate('.toolbars .linewidthitem', 'click', function(ev) {  
        var strokeWidth = parseInt(this.querySelector('.bar').style.height); 
        let children = this.parentElement.querySelectorAll(".linewidthitem");
        children.forEach(child => {
            child.classList.remove('selected')
        })  
        this.parentElement.querySelector(`[data-item="${strokeWidth}"]`).classList.add('selected');
        eventBus.dispatch("brushChangeStrokeWidth", {
            source: null,
            strokeWidth: strokeWidth,        
        }); 
    })
    // freedraw
    $("body").delegate('.freedraw-properties .color-select .input-color', 'input', function(ev) {
        el('freedrawStrokeColor').style.backgroundColor =  this.value;
        eventBus.dispatch("brushChangeStrokeColor", {
            source: null,
            color: this.value, 
        }); 
    })
    $("body").delegate('.freedraw-properties .color-select .color-item', 'click', function(ev) {
        var color = Convert.rgbToHex(this.style.backgroundColor);
        el('freedrawStrokeColor').style.backgroundColor = color;
        eventBus.dispatch("brushChangeStrokeColor", {
            source: null,
            color: color,        
        }); 
    })
    $("body").delegate('#textFieldWidth', 'change', function(ev) {
        var element = ev.target;
       
        eventBus.dispatch("changeformcontrolwidth", {
            element: element,
            width: parseInt(this.value),          
        }); 
    })
    $("body").delegate('#textFieldHeight', 'change', function(ev) {
        var element = ev.target;
       
        eventBus.dispatch("changeformcontrolHeight", {
            element: element,
            height: parseInt(this.value),        
        }); 
    })
    
    $("body").delegate('#textFieldBorderWidth', 'change', function(ev) {
        var element = ev.target;
       
        eventBus.dispatch("changeformcontrolborderwidth", {
            element: element,
            width: parseInt(this.value),   
        }); 
    })
    $("body").delegate('.annotation-added', 'click', function(ev) {
        var element = ev.target;       
        eventBus.dispatch("selectElement", {
            element: element
        }); 
    })
    $("body").delegate('.delete_element', 'click', function(ev) {
        eventBus.dispatch("delete_element", {
            source: this
        }); 
        hideTools();  
    })  
    
    $("body").delegate('#text-format-color', 'input', function(ev) {
        eventBus.dispatch("updateBackgroundColorDiv", {
            color: this.value
        });         
    })
    $("body").delegate('.textLayer,.hideTool', 'click', function() {
        hideTools();  
      })
      $("body").delegate('.formLayer', 'click', function(ev) {
        eventBus.dispatch("formLayerClick", {
            event: ev
       });         
    })
   
    // text_field
    $("body").delegate('.color-text .color-select .input-color', 'input', function(ev) {
        this.closest('.toolbarItem').querySelector('#textFieldColor').style.backgroundColor =  this.value;
        eventBus.dispatch("changeformcontrolColor", {
            source: null,
            color: this.value, 
        }); 
    })
    $("body").delegate('.color-text .color-select .color-item', 'click', function(ev) {
        var color = Convert.rgbToHex(this.style.backgroundColor);
        this.closest('.toolbarItem').querySelector('#textFieldColor').style.backgroundColor = color;
        eventBus.dispatch("changeformcontrolColor", {
            source: null,
            color: color,        
        }); 
    })

    $("body").delegate('.color-text-border .color-select .input-color', 'input', function(ev) {
        this.closest('.toolbarItem').querySelector('#textFieldBoderColor').style.backgroundColor =  this.value;
        eventBus.dispatch("changeformcontrolBorderColor", {
            source: null,
            color: this.value, 
        }); 
    })
    $("body").delegate('.color-text-border .color-select .color-item', 'click', function(ev) {
        var color = Convert.rgbToHex(this.style.backgroundColor);
        this.closest('.toolbarItem').querySelector('#textFieldBoderColor').style.backgroundColor = color;
        eventBus.dispatch("changeformcontrolBorderColor", {
            source: null,
            color: color,        
        }); 
    })    
    $("body").delegate('#btnFullScreen', 'click', function(ev) {
        var isFullScreen = this.getAttribute('full-screen');
        var el = document.querySelector('.content-edit-form'); 
        if(isFullScreen == "true"){
            this.setAttribute("full-screen",false);
            closeFullscreen();
        }
        else{
           
            el.webkitRequestFullscreen();
            this.setAttribute("full-screen",true);
        }
       
    })   
    
    $("body").delegate('#textbox-border-color,#select-border-color,#radio-border-color', 'input', function(ev) {
        eventBus.dispatch("updateBorderColor", {
            source: null,
            color: this.value,        
        }); 
      })
      $("body").delegate('#textbox-color,#select-text-color', 'input', function(ev) {
        eventBus.dispatch("updateElementColor", {
            source: null,
            color: this.value,        
        }); 
      })
      $("body").delegate('#textbox-border-width,#radio-border-width', 'input', function(ev) {
        eventBus.dispatch("updateBoderWidth", {
            source: null,
            width: this.value,        
        }); 
      })

      $("body").delegate('#radio-color', 'input', function(ev) {       

        eventBus.dispatch("updateCenterColorElem", {
            color: this.value   
        }); 
      })

      $("body").delegate('#select_fieldName', 'change', function(ev) {
        eventBus.dispatch("changeFieldName", {
            source: null,
            fieldName: this.value,        
        }); 
      })
      $("body").delegate('#fieldOptions', 'change', function(ev) {
        eventBus.dispatch("updateOptionSelect", {
            source: null,
            strOption: this.value,        
        }); 
      }) 
      
      $("body").delegate('.item-anotation', 'click', function(ev) {
        var active = 'active';
        $('.list-anotation .item-anotation').removeClass(active);
        if(!this.classList.contains(active)){      
            this.classList.add(active);
        }  
        eventBus.dispatch("itemannotation_click", {
            element: this                   
        }); 
      }) 
      $("body").delegate('#show_pdf_edit_page_button', 'click', function () {
        if ($("#pdf_edit_show_edit_page").hasClass('d-none')){
            $(".pdf-edit-left-bar-item").removeClass('d-none')
            $("#pdf_edit_show_list_page").addClass('d-none')
        } else{
                $(".pdf-edit-left-bar-item").removeClass('d-none')
                $("#pdf_edit_show_edit_page").addClass('d-none')
            }
      });
      $("body").delegate('#hide_left', 'click', function () {
        var body_page = this.closest('.body-page');
        var hide_page = body_page.getAttribute('hide-page');
        if(hide_page == 'true'){
            body_page.setAttribute('hide-page',false);
        }
        else{
            body_page.setAttribute('hide-page',true);
        }
      });
      $("body").delegate('#btnApplyArrangePages', 'click', function () {
        eventBus.dispatch("saveArrageFile", {
            source: this,
        }); 
      });   

      $("body").delegate('#line-stroke-width,#rectangle-stroke-width,#ellipse-stroke-width,#free-draw-stroke-width', 'input', function(ev) {
        eventBus.dispatch("updateLineWidth", {
            width: this.value,
        });     
      })
      $("body").delegate('#line-color,#rectangle-color,#ellipse-color,#free-draw-color', 'input', function(ev) {
        eventBus.dispatch("updateLineColor", {
            color: this.value,
        });    
      })
     
      $("body").delegate('#line-opacity,#rectangle-opacity,#ellipse-opacity,#free-draw-opacity,#img-opacity', 'input', function(ev) {
        eventBus.dispatch("updateLineOpacity", {
            opacity: this.value,
        });   
      })
      $("body").delegate('.close_dialog', 'click', function(ev) {
        eventBus.dispatch("close_dialog", {
            source: this,
        });   
      })
    $("body").delegate('#background-color,#rectangle-background-color,#ellipse-background-color,#text-color', 'input', function(ev) {
        eventBus.dispatch("updateBackgroundColor", {
            color: this.value,
        });
    })
    $("body").delegate('#btnSaveLink', 'click', function(ev) {
        var link_type = link_enum.url,url,email,phone,page;
        if(document.getElementById('rd-url').checked ==true){
        link_type = link_enum.url;
        }
        
        if(document.getElementById('rd-mail').checked ==true){
        link_type = link_enum.mail;
        }
        if(document.getElementById('rd-phone').checked ==true){
        link_type = link_enum.phone;
        }
        if(document.getElementById('rd-page').checked ==true){
        link_type = link_enum.page;
        }
        url = document.getElementById("targetURL").value;
        email = document.getElementById("targetEmailAddress").value;
        phone = document.getElementById("targetTel").value;
        page = document.getElementById("targetPage").value;
        
   

         var new_link = {link_type: link_type, url: url, email:email,phone:phone,page:page}
        eventBus.dispatch("savelink", {
            link: new_link,
        });
    })
    $("body").delegate('.button-delete-annotation', 'click', function(ev) {
        var parent = this.closest('.item-anotation');
        var objectId = parent.getAttribute('id');
        var typeObject = parent.getAttribute('type');
        var pageNum = parent.getAttribute('page-num');
        // parent.remove();
        eventBus.dispatch("delete_annotation", {
            pageNum: parseInt(pageNum),
            objectId: objectId,    
            typeObject : typeObject
        }); 
    })

    fabric.util.addListener(document.body, 'keydown', function(options) {
        if (options.repeat) {
          return;
        }
        var key = options.which || options.keyCode; // key detection
        if (key === 37) { // handle Left key
          moveSelected(Direction.LEFT);
        } else if (key === 38) { // handle Up key
          moveSelected(Direction.UP);
        } else if (key === 39) { // handle Right key
          moveSelected(Direction.RIGHT);
        } else if (key === 40) { // handle Down key
          moveSelected(Direction.DOWN);
        }
      else if (key === 46) { // handle Down key
        eventBus.dispatch("commonDelete", {
            source: this                 
        }); 
    }})
    // signal
    $("body").delegate('#bntSaveSignals', 'click',saveSignals) ;
    $("body").delegate('.signal-delete', 'click', deleteSignal) ;
    $("body").delegate('#bntClearSignal', 'click', clearSignal) ;
    $("body").delegate('#txtSignature', 'input', function(ev) {
        createListSinalPreview();
    }) 
    $("body").delegate('.signaturePreview', 'click', function(ev) {
        var active = 'selected';
        if(!this.classList.contains(active)){        
            $('.signaturePreview').removeClass(active);
            this.classList.add(active);
        }     
        createListSinalPreview();
    }) 
    $("body").delegate('#signatureTypeColorPicker .color-signal', 'click', function(ev) {
        var active = 'selected';
        if(!this.classList.contains(active)){        
            $('.color-signal').removeClass(active);
            this.classList.add(active);
        }     
        createListSinalPreview();
    }) 
    $("body").delegate('#signatureDrawColorPicker .color-signal-draw', 'click', function(ev) {
        var active = 'selected';
        if(!this.classList.contains(active)){        
            $('.color-signal-draw').removeClass(active);
            this.classList.add(active);
        }     
        var color = this.style.color;
        signalFabricCanvas.freeDrawingBrush.color = color;
    }) 

    window.$("#modal_add_signals").on("shown.bs.modal", function () {
        createListSinalPreview();
        defineDrawSignal();
    }) 
    $("body").delegate('.doc_image_item', 'click', function() {	
        let children = document.querySelectorAll(".doc_image_item");
        children.forEach(child => {
            child.classList.remove('selected')
        })  
        this.classList.add('selected');
       
    })


  $("body").delegate('.f-input', 'click', function(ev) {
    eventBus.dispatch("showtoolElement", {
        source: this,        
        type: this.getAttribute("kind")
    }); 
  })
  $("body").delegate('.shape-line-properties .toolbarLineDashStyle li', 'click', function(ev) { 
    var dash =   this.getAttribute('dash');
    if(dash){
        dash = JSON.parse(dash);
    }
    Config.shape.line.dash = dash
    document.querySelector(".shape-line-properties").querySelector('.dash-style img').setAttribute('src',this.querySelector('img').getAttribute('src'));
})
$("body").delegate('.shape-arrow-properties .toolbarLineDashStyle li', 'click', function(ev) { 
    var dash =   this.getAttribute('dash');
    if(dash){
        dash = JSON.parse(dash);
    }    
    Config.shape.arrow.dash = dash
    document.querySelector(".shape-arrow-properties").querySelector('.dash-style img').setAttribute('src',this.querySelector('img').getAttribute('src'));
})
$("body").delegate('.shape-ellipse-properties .toolbarLineDashStyle li', 'click', function(ev) { 
    var dash =   this.getAttribute('dash');
    if(dash){
        dash = JSON.parse(dash);
    }
    Config.shape.ellipse.dash = dash
    document.querySelector(".shape-ellipse-properties").querySelector('.dash-style img').setAttribute('src',this.querySelector('img').getAttribute('src'));
})

$("body").delegate('.shape-rectangle-properties .toolbarLineDashStyle li', 'click', function(ev) { 
    var dash =   this.getAttribute('dash');
    if(dash){
        dash = JSON.parse(dash);
    }
    Config.shape.rectangle.dash = dash
    document.querySelector(".shape-rectangle-properties").querySelector('.dash-style img').setAttribute('src',this.querySelector('img').getAttribute('src'));
})
$("body").delegate('.pencil-properties .toolbarLineDashStyle li', 'click', function(ev) { 
    var dash =   this.getAttribute('dash');
    if(dash){
        dash = JSON.parse(dash);
    }
    Config.pencil.dash = dash
    document.querySelector(".pencil-properties").querySelector('.dash-style img').setAttribute('src',this.querySelector('img').getAttribute('src'));
})
$("body").delegate('.freedraw-properties .toolbarLineDashStyle li', 'click', function(ev) { 
    var dash =   this.getAttribute('dash');
    if(dash){
        dash = JSON.parse(dash);
    }
    Config.freedraw.dash = dash
    document.querySelector(".freedraw-properties").querySelector('.dash-style img').setAttribute('src',this.querySelector('img').getAttribute('src'));
})
$("body").delegate('#fileid', 'change', function() {	
    const files = this.files;
    var div,button;
    let i,img;
    var imageContainer = document.getElementById("imageContainer");
    if (files.length>0) {
       for(i = 0;i<files.length;i++){
          div = document.createElement("div");
          div.className = 'img-box';

          img = document.createElement("img");
          img.setAttribute("alt",i)
          img.setAttribute("class","doc_image_item p-1")
          img.src = window.URL.createObjectURL(files[i]);
          div.appendChild(img);

          button = document.createElement("a");
          button.setAttribute("class","remove_img_pdf")
          button.setAttribute("title","delete image")
          button.setAttribute("data-remote",true)
          button.setAttribute("href",'')
          button.innerHTML = 'X';
          button.addEventListener("mouseover", requestReloadImage);
          div.appendChild(button);
          imageContainer.appendChild(div);            
       }
    }

    $("#btnSubmitUploadImage").click();


 })


}


function requestReloadImage(event) {   
    if(event.target.getAttribute('href').length>0) return;
    $.ajax({
       type: 'GET',
       url: window.origin + "/pdf/reloadImage",
       dataType: 'script',           
       success: function(data, textStatus, jqXHR){
           
       },
       error:function(jqXHR, textStatus, errorThrown){
           console.log("AJAX Error: #{textStatus}")
       }
   });
 }
function toggle(btn){
    var active = 'selected';
    if(!btn.classList.contains(active)){      
      btn.classList.add(active);      
    }  
    else{
        btn.classList.remove(active);
    }
}


function setToggle(btn){
    var active = 'active';
    if(!btn.classList.contains(active)){      
      btn.classList.add(active);
      removeOtherButtonActive(btn);
    }  
}
  
function removeOtherButtonActive(btn){
    $('.anotation-buttons a').not(btn).removeClass("active");
    $('.anotation-buttons canvas').not(btn).removeClass("active");
    $('.anotation-buttons img').not(btn).removeClass("active");
}

function createListSinalPreview(){
    var divPreview = document.getElementById('signaturePreviewWrap');
    var colorElementSelected = document.getElementById('signatureTypeColorPicker').querySelector('.selected');
    var color = colorElementSelected.style.color;
    var text = document.getElementById('txtSignature').value;
    var divPreview = document.getElementById('signaturePreviewWrap');
    divPreview.style.color = color;
    var listSinalPreview = divPreview.querySelectorAll('.signaturePreview');
    for (let index = 0; index < listSinalPreview.length; index++) {
      const element = listSinalPreview[index];
      element.innerHTML = text;    
    }  
  }
  function defineDrawSignal(){ 
    if(!signalFabricCanvas){
      var signatureDrawCanvas = document.getElementById('signatureDrawCanvas');
      signalFabricCanvas = new fabric.Canvas(signatureDrawCanvas); 
      signalFabricCanvas.stateful = true;    
      signalFabricCanvas.isDrawingMode = true;
      signalFabricCanvas.freeDrawingBrush = new fabric['PencilBrush'](signalFabricCanvas);
      signalFabricCanvas.freeDrawingBrush.width = 4;
      var colorElementSelected = document.getElementById('signatureDrawColorPicker').querySelector('.selected');
      var color = colorElementSelected.style.color;
      signalFabricCanvas.freeDrawingBrush.color = color;
    }
    else{
      signalFabricCanvas.clear();
    }
   
  }
  function saveSignals(){
    var tabName = $('.nav-tabs .active');
    var tabId = tabName[0].id;
    if(tabId == 'tabText'){
      saveTabText();
    }
    if(tabId == 'tabDraw'){
      saveTabDraw();
    } 
  }
  function saveTabText() {
    var divPreview = document.getElementById('signaturePreviewWrap');
    var colorElementSelected = document.getElementById('signatureTypeColorPicker').querySelector('.selected');
    var color = Convert.rgbToHex( colorElementSelected.style.color);
    var text = document.getElementById('txtSignature').value;
    var divPreview = document.getElementById('signaturePreviewWrap');
    var font_family = divPreview.querySelector('.selected').style.fontFamily;
    var objectDataSubmit = {color: color,text:text,font_family:font_family};
    AjaxSubmit("/pdf/save_signal",'GET',objectDataSubmit);  
  }
  function saveTabDraw() {
    var dataURL = signalFabricCanvas.toDataURL(); 
    // Get our file
    var file= dataURLtoBlob(dataURL);
    // Create new form data
    var fd = new FormData();
    // Append our Canvas image file to the form data
    fd.append("image", file);
    // And send it
    // var objectDataSubmit = {data: fd};
    // AjaxSubmit("/pdf/upload_signal",'POST',objectDataSubmit);  
    $.ajax({
       url:window.location.origin + "/pdf/upload_signal",
       type: "POST",
       data: fd,
       dataType: 'script',
       processData: false,
       contentType: false,
       success: function(data, textStatus, jqXHR){
        console.log("AJAX success!")
      },
      error:function(jqXHR, textStatus, errorThrown){
          console.log("AJAX Error: #{textStatus}")
      }
    });
  }
  function deleteSignal(){
    var id = this.closest(".row-signal-element").querySelector('.signal_ID').value;
    var objectDataSubmit = {id: id};
    AjaxSubmit("/pdf/deleteSignal",'GET',objectDataSubmit);  
  }
  function clearSignal() {
    var m = confirm("Do you want to clear?");
    if (m) {
      signalFabricCanvas.clear();
    }
  }
  
  function dataURLtoBlob(dataURL) {
    // Decode the dataURL
    var binary = atob(dataURL.split(',')[1]);
    // Create 8-bit unsigned array
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    // Return our Blob object
    return new Blob([new Uint8Array(array)], {type: 'image/png'});
  }

  function hideTools(){
    let children = document.querySelectorAll(".toolbars  .dropdown-toolbar");
    children.forEach(child => {
        child.style.display = 'none';
    })  
  }
  