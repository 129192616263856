$(document).on('turbolinks:load', function() {
    format_datepicker('#customer_end_datepicker', '.customer_end_datepicker_value');
    format_datepicker('#customer_cancellation_datepicker', '.customer_cancellation_datepicker_value');
    
    bindItemDropdownEvents('#customer_deposit_method_dropdown_menu_select_type', 
    '#customer_deposit_method_text', '#deposit_method_id', '#add_new_deposit_method', 
    '.customer-deposit-method-delete', '.customer-deposit-method-edit', 
    '/confirm_to_remove_deposit_method_customer', '/edit_deposit_method_customer', '/new_deposit_method_customer');

    bindItemDropdownEvents('#customer_unit_price_dropdown_menu_select_type', 
        '#customer_unit_price_text', '#unit_price_id', '#add_new_unit_price', 
        '.customer-unit-price-delete', '.customer-unit-price-edit', 
        '/confirm_to_remove_unit_price_customer', '/edit_unit_price_customer', '/new_unit_price_customer');

    $("#customer_postcode").on("input", function() {
        var inputValue = $(this).val();
        let sanitizedValue = inputValue.replace(/[^0-9-]/g, '').replace(/-+/g, '-');
        let parts = sanitizedValue.split('-');

        if (parts.length === 2) {
            parts[0] = parts[0].substring(0, 3);
            parts[1] = parts[1].substring(0, 4);
            sanitizedValue = parts.join('-');
        } else {
            sanitizedValue = sanitizedValue.substring(0, 3);
        }

        $(this).val(sanitizedValue);
        var postcode = sanitizedValue;

        $.ajax({
            url: "/check_postcode_customer?format=js",
            method: "GET",
            data: { postcode: postcode },
            dataType: "script"
        });
    });

    var depositMethodTextElement = $('#customer_deposit_method_text');
    var depositMethodItemName = depositMethodTextElement.data('item-name');

    if (depositMethodItemName) {
        depositMethodTextElement.text(depositMethodItemName);
    }

    var unitPriceTextElement = $('#customer_unit_price_text');
    var unitPriceItemName = unitPriceTextElement.data('item-name');

    if (unitPriceItemName) {
        unitPriceTextElement.text(unitPriceItemName);
    }

    $("body").delegate(".customer_phone", "input", function() {
        phone_fax_format(this);
    });

    $(".customer_fax").on("input", function() {
        phone_fax_format(this);
    });

    
    
});

function phone_fax_format(element){
    // const phoneInput = $(element).val();
    // const phonePattern = /^(\d{2,3})\-?(\d{3,4})\-?(\d{4})$/;
    // const noAlpha = phoneInput.replace(/[a-zA-Z]/g, '');
    // $(element).val(noAlpha);

    // if (!phonePattern.test(phoneInput)) {
    //   $(element).addClass("is-invalid");
    //   if (!$(element).next(".show-message").length) {
    //     $(element).after('<div class="text-danger show-message">Please enter a valid phone number in the format 12-345-6789 or 123-4567-8901</div>');
    //   }
    //   // setTimeout(() => {
    //   //   $(element).removeClass("is-invalid");
    //   //   $(element).next(".show-message").remove();
    //   // }, 5000);
    // } else {
    //   $(element).removeClass("is-invalid");
    //   $(element).next(".show-message").remove();
    // }
}
