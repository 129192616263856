import { loadFabric } from '../edit/fabric_helper';
import {TextLayerBuilder} from './text_layer_builder'  ;
import { ANOTATION } from '../edit/annotation';
// class create Container contain content of file
export class PDFContent{
  baseView  
  
  constructor(baseView){
    this.baseView = baseView
  }

  create(){  
    this.baseView.viewComponents.viewContainer.replaceChildren(); 
    load(this.baseView);
  }   
   
  render(pageNumber) {
    this.renderPage(this.baseView);
  }

  renderPage(pageNumber){
    renderPage(this.baseView.file.pages[pageNumber],this.baseView);
  }

  renderViewPage(baseView){
    var divPage = baseView.viewComponents.viewContainer.querySelector(`#page-${baseView.currentPageNumber}`);
    var page = baseView.file.pages[baseView.currentPageNumber-1];
    var rotate = (page.rotate + page.viewRotate) % 360;  
    var viewport = page.getViewport({scale: baseView.currentScaleValue,rotation:rotate});
    divPage.style.width = viewport.width + "px";
    divPage.style.height = viewport.height + "px";
    divPage.replaceChildren();
    createcanvas(viewport,page,divPage,baseView);
  }

  updateScale(baseView){
    var page,viewport,rotate;
    var divPage,divLoadingIcon;
    var i;
    for (i = 0; i < baseView.file.pages.length; i++) {  
        page = baseView.file.pages[i];
        rotate = (page.rotate + page.viewRotate) % 360;  
        viewport = page.getViewport({scale: baseView.currentScaleValue,rotation:rotate});
        divPage = baseView.viewComponents.viewContainer.querySelector(`#page-${i+1}`);
        divPage.style.width = viewport.width + "px";
        divPage.style.height = viewport.height + "px";
        var formLayer = divPage.querySelector('.formLayer')
        if(formLayer){
          formLayer.replaceChildren();
        } 
        if(i== baseView.currentPageNumber-1){
          updatecanvas(viewport,page,divPage,baseView);  
        }
        else{
          divPage.replaceChildren();
          divLoadingIcon = document.createElement("div");
          divLoadingIcon.setAttribute("class", "loadingIcon notVisible");
          divLoadingIcon.setAttribute("role", "img");
          divLoadingIcon.setAttribute("aria-label","Loading...");        
          divPage.appendChild(divLoadingIcon); 
          
          divPage.setAttribute("data-loaded",false);    
        }
        // createcanvas(viewport,page,divPage);;
    }
    
  }
}



function load(baseView){
    var page,viewport,rotate;
    var divPage,divLoadingIcon;
    var i;
    for (i = 0; i < baseView.file.pages.length; i++) {  
        page = baseView.file.pages[i];
        rotate = (page.rotate + page.viewRotate) % 360;  
        viewport = page.getViewport({scale: baseView.currentScaleValue,rotation:rotate});
        divPage = document.createElement("div");
        divPage.setAttribute("id", "page-" + (page.pageNumber));
        divPage.setAttribute("class", "page");
        divPage.setAttribute("data-page-number", page.pageNumber);
        divPage.setAttribute("aria-label","Page " + (page.pageNumber));
        divPage.setAttribute("style", "position: relative");        
        divPage.style.width = viewport.width + "px";
        divPage.style.height = viewport.height + "px";

        divLoadingIcon = document.createElement("div");
        divLoadingIcon.setAttribute("class", "loadingIcon notVisible");
        divLoadingIcon.setAttribute("role", "img");
        divLoadingIcon.setAttribute("aria-label","Loading...");        
        divPage.appendChild(divLoadingIcon);  
        baseView.viewComponents.viewContainer.appendChild(divPage)            
    }
}

function createElementCanvasAnotation(canvas){
  var canvasAnotation = canvas.cloneNode();
  canvasAnotation.width = canvas.width;
  canvasAnotation.height = canvas.height;
  return canvasAnotation;
}

function createcanvas(viewport,page,div_page,baseView){
  var canvas = document.createElement("canvas");  
  var divcanvasWrapper = document.createElement("div");  
  // Set id attribute with page-#{pdf_page_number} format
  divcanvasWrapper.setAttribute("class", "canvasWrapper");  
  divcanvasWrapper.style.width = viewport.width + "px";
  divcanvasWrapper.style.height = viewport.height + "px";

  divcanvasWrapper.appendChild(canvas);
  div_page.appendChild(divcanvasWrapper);
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  var canvasAnotation = createElementCanvasAnotation(canvas);
  div_page.appendChild(canvasAnotation);
  // var fb = new FabricHelper(canvasAnotation);
  var fcanvas = loadFabric(canvasAnotation,baseView.eventBus,page._pageIndex + 1);
  var fabricpageAnnotations_item=[page._pageIndex,fcanvas]
  baseView.fabricpageAnnotations.push(fabricpageAnnotations_item);

  const renderContext = {
    annotationMode:  2,
    background: 'rgba(0,0,0,0)',
    get canvasContext() {
      return canvas.getContext('2d');
    },
    viewport: viewport,
  };
  var renderTask = page.render(renderContext);

  // Wait for rendering to finish
  renderTask.promise.then(function() {  
    var textLayerDiv = document.createElement("div");
    page.getTextContent().then(function(textContent) {
      // Set it's class to textLayer which have required CSS styles
      textLayerDiv.setAttribute("class", "textLayer");
      settingDisplay(baseView,textLayerDiv);

      textLayerDiv.style.width = viewport.width + "px";
      textLayerDiv.style.height = viewport.height + "px";
      div_page.appendChild(textLayerDiv);
      var annotationLayer = document.createElement("div");
      annotationLayer.setAttribute("class", "annotationLayer");
      div_page.appendChild(annotationLayer);
      const eventBus = new pdfjsViewer.EventBus();
      const pdfLinkService = new pdfjsViewer.PDFLinkService({
        eventBus,
      });
      
      page.getAnnotations().then(function (annotationsData) {    
        pdfjsLib.AnnotationLayer.render({
          viewport: viewport.clone({ dontFlip: true }),
          div: annotationLayer,
          annotations: annotationsData,
          page: page,
          linkService: pdfLinkService,
          textLayerMode: 2  
        });
        
      })
      var formLayer = document.createElement("div");
      formLayer.setAttribute("class", "formLayer");
      
      formLayer.style.width = viewport.width + "px";
      formLayer.style.height = viewport.height + "px";

      div_page.appendChild(formLayer);
      var textLayer = new TextLayerBuilder({
        textLayerDiv: textLayerDiv, 
        pageIndex: page._pageIndex,
        viewport: viewport
      });
  
      textLayer.setTextContent(textContent);      
      textLayer.render();        
    });       
  });
  div_page.setAttribute("data-loaded",true);   
  
    
}
function updatecanvas(viewport,page,div_page,baseView){
  var divcanvasWrapper = div_page.querySelector(".canvasWrapper");  
  // Set id attribute with page-#{pdf_page_number} format
  divcanvasWrapper.style.width = viewport.width + "px";
  divcanvasWrapper.style.height = viewport.height + "px";  

  var canvas = divcanvasWrapper.querySelector('CANVAS');
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);

  var canvasContainer = div_page.querySelector(".canvas-container");  
  // canvasContainer.style.width = viewport.width + "px";
  // canvasContainer.style.height = viewport.height + "px";

  // var lowercanvas = canvasContainer.querySelector('.lower-canvas');
  // lowercanvas.style.width = viewport.width + "px";
  // lowercanvas.style.height = viewport.height + "px";

  // var uppercanvas  = canvasContainer.querySelector('.upper-canvas ');
  // uppercanvas.style.width = viewport.width + "px";
  // uppercanvas.style.height = viewport.height + "px";
  var canvasAnotation = createElementCanvasAnotation(canvas);
  canvasContainer.replaceWith(canvasAnotation);
  // var fb = new FabricHelper(canvasAnotation);
  var fcanvas = loadFabric(canvasAnotation,baseView.eventBus,page._pageIndex + 1);
  var fabricpageAnnotations_item=[page._pageIndex,fcanvas]
  baseView.fabricpageAnnotations.push(fabricpageAnnotations_item);

  const renderContext = {
    annotationMode:  2,
    background: 'rgba(0,0,0,0)',
    get canvasContext() {
      return canvas.getContext('2d');
    },
    viewport: viewport,
  };
  var textLayerDiv,annotationLayer,formLayer,textLayer;
  var renderTask = page.render(renderContext);
  const eventBus = new pdfjsViewer.EventBus();
  const pdfLinkService = new pdfjsViewer.PDFLinkService({
    eventBus,
  });
  renderTask.promise.then(function() {
  
    textLayerDiv = div_page.querySelector(".textLayer");
    textLayerDiv.replaceChildren();
    page.getTextContent().then(function(textContent) {
      textLayerDiv.style.width = viewport.width + "px";
      textLayerDiv.style.height = viewport.height + "px";
      annotationLayer = div_page.querySelector(".annotationLayer");
      annotationLayer.replaceChildren();     
      
      page.getAnnotations().then(function (annotationsData) {    
        pdfjsLib.AnnotationLayer.render({
          viewport: viewport.clone({ dontFlip: true }),
          div: annotationLayer,
          annotations: annotationsData,
          page: page,
          linkService: pdfLinkService
        });
        
      })
      formLayer = div_page.querySelector(".formLayer");
      
      formLayer.style.width = viewport.width + "px";
      formLayer.style.height = viewport.height + "px";

      textLayer = new TextLayerBuilder({
        textLayerDiv: textLayerDiv, 
        pageIndex: page._pageIndex,
        viewport: viewport
      });
  
      textLayer.setTextContent(textContent);      
      textLayer.render();        
    });       
  });
  div_page.setAttribute("data-loaded",true);   
}
function renderPage(page,baseView) {
    try{   
      var div_page = document.getElementById("page-"+ page.pageNumber);
      var is_data_loaded = div_page.getAttribute("data-loaded");  
      if(is_data_loaded == "true" ) {return;}  
      var rotate = (page.rotate + page.viewRotate) % 360;  
      var viewport = page.getViewport({scale: baseView.currentScaleValue,rotation:rotate});    
      div_page.replaceChildren();
      createcanvas(viewport,page,div_page,baseView);
     
  }
  catch(error)
  {  
    console.log("Render error page" + page.pageNumber);
    console.log(error.message);
  }
}

function settingDisplay(baseView,textLayerDiv){
  if(baseView.current_anotation != ANOTATION.NONE 
    && baseView.current_anotation != ANOTATION.TEXT_HIGHLIGHT
    && baseView.current_anotation != ANOTATION.TEXT_STROKEOUT
    && baseView.current_anotation != ANOTATION.TEXT_UNDERLINE)
  {
    textLayerDiv.classList.add("no-display");
  }
}