import { move_top_item } from './commons';
import { move_up_item } from './commons';
import { move_down_item } from './commons';
import { move_bottom_item } from './commons';
import { call_sortable_list } from './commons';
import { selected_item } from './commons';
import { update_index_item } from './commons';

$(document).on('turbolinks:load', function() {

const myModal = document.getElementById('modal-reorder-deposit-methods');
if (myModal) {
  myModal.addEventListener('shown.bs.modal', function () {
    call_sortable_list("#list_sort_deposit_method");
  });
}

$("body").delegate('#btnMoveTopSortDepositMethod', 'click', function () {
  move_top_item("#list_sort_deposit_method", '.item-deposit-method');
});

$("body").delegate('#btnMoveUpSortDepositMethod', 'click', function () {
  move_up_item("#list_sort_deposit_method");
});

$("body").delegate('#btnMoveDownSortDepositMethod', 'click', function () {
  move_down_item("#list_sort_deposit_method")
});

$("body").delegate('#btnMoveBottomSortDepositMethod', 'click', function () {
  move_bottom_item("#list_sort_deposit_method", '.item-deposit-method');
});

$("body").delegate('.item-deposit-method', 'click', function () {
  selected_item('.item-deposit-method', this);
});

$("body").delegate('#btnUpdateIndexDepositMethods', 'click', function () {
  update_index_item("#list_sort_deposit_method", "/update_index_deposit_methods");
});

const modal_confirm_remove_deposit_method = document.getElementById('modal-confirm-remove-deposit-method');
if (modal_confirm_remove_deposit_method) {
  modal_confirm_remove_deposit_method.addEventListener('shown.bs.modal', function () {
    $("#modal-edit-deposit-method").addClass("modal-blur");
  });
  modal_confirm_remove_deposit_method.addEventListener('hidden.bs.modal', function () {
    $("#modal-edit-deposit-method").removeClass("modal-blur");
  });
}

const modal_confirm_change_deposit_method = document.getElementById('modal-confirm-change-deposit-method');
if (modal_confirm_change_deposit_method) {
  modal_confirm_change_deposit_method.addEventListener('shown.bs.modal', function () {
    $("#modal-edit-deposit-method").addClass("modal-blur");
  });
  modal_confirm_change_deposit_method.addEventListener('hidden.bs.modal', function () {
    $("#modal-edit-deposit-method").removeClass("modal-blur");
  });
}


});
