$(document).on('turbolinks:load', function() {
    format_datepicker('#master_product_cancellation_datepicker', '.master_product_cancellation_datepicker_value');
    bindItemDropdownEvents('#master_product_use_purpose_dropdown_menu_select_type', 
    '#master_product_use_purpose_text', '#use_purpose_id', '#add_new_use_purpose', 
    '.master-product-use-purpose-delete', '.master-product-use-purpose-edit', 
    '/confirm_to_remove_use_purpose_master_product', '/edit_use_purpose_master_product', '/new_use_purpose_master_product');
    bindItemDropdownEvents('#master_product_inventory_status_dropdown_menu_select_type', 
    '#master_product_inventory_status_text', '#inventory_status_id', '#add_new_inventory_status', 
    '.master-product-inventory-status-delete', '.master-product-inventory-status-edit', 
    '/confirm_to_remove_inventory_status_master_product', '/edit_inventory_status_master_product', '/new_inventory_status_master_product');
    bindItemDropdownEvents('#master_product_unit_dropdown_menu_select_type',
    '#master_product_unit_text', '#unit_id', '#add_new_unit',
    '.master-product-unit-delete', '.master-product-unit-edit',
    '/confirm_to_remove_unit_master_product', '/edit_unit_master_product', '/new_unit_master_product');
    bindItemDropdownEvents('#master_product_category_dropdown_menu_select_type', 
    '#master_product_category_text', '#master_product_category_id', '#add_new_product_category', 
    '.master-product-category-delete', '.master-product-category-edit', 
    '/confirm_to_remove_product_category_master_product', '/edit_product_category_master_product', '/new_product_category_master_product');
    bindItemDropdownEvents('#master_product_purchase_tax_dropdown_menu_select_type',
    '#master_product_purchase_tax_text', '#purchase_tax_id', '#add_new_purchase_tax',
    '.master-product-purchase-tax-delete', '.master-product-purchase-tax-edit',
    '/confirm_to_remove_purchase_tax_master_product', '/edit_purchase_tax_master_product', '/new_purchase_tax_master_product');
    bindItemDropdownEvents('#master_product_sales_tax_dropdown_menu_select_type',
    '#master_product_sales_tax_text', '#sales_tax_id', '#add_new_sales_tax',
    '.master-product-sales-tax-delete', '.master-product-sales-tax-edit',
    '/confirm_to_remove_sales_tax_master_product', '/edit_sales_tax_master_product', '/new_sales_tax_master_product');


    var master_product_use_purpose_text = $('#master_product_use_purpose_text');
    var master_product_use_purpose_name = master_product_use_purpose_text.data('item-name');

    if (master_product_use_purpose_name) {
        master_product_use_purpose_text.text(master_product_use_purpose_name);
    }

    var master_product_inventory_status_text = $('#master_product_inventory_status_text');
    var master_product_inventory_status_name = master_product_inventory_status_text.data('item-name');

    if (master_product_inventory_status_name) {
        master_product_inventory_status_text.text(master_product_inventory_status_name);
    }

    var master_product_unit_text = $('#master_product_unit_text');
    var master_product_unit_name = master_product_unit_text.data('item-name');

    if (master_product_unit_name) {
        master_product_unit_text.text(master_product_unit_name);
    }

    var master_product_category_text = $('#master_product_category_text');
    var master_product_category_name = master_product_category_text.data('item-name');

    if (master_product_category_name) {
        master_product_category_text.text(master_product_category_name);
    }

    var master_product_purchase_tax_text = $('#master_product_purchase_tax_text');
    var master_product_purchase_tax_name = master_product_purchase_tax_text.data('item-name');

    if (master_product_purchase_tax_name) {
        master_product_purchase_tax_text.text(master_product_purchase_tax_name);
    }

    var master_product_sales_tax_text = $('#master_product_sales_tax_text');
    var master_product_sales_tax_name = master_product_sales_tax_text.data('item-name');

    if (master_product_sales_tax_name) {
        master_product_sales_tax_text.text(master_product_sales_tax_name);
    }

});