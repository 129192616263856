//= require jquery
//= require jquery-ui
//= require jquery_ujs
//= require jstree.min
//= require bootstrap-tokenfield
//= require jquery-ui/widgets/mouse
//= require jquery-ui/widgets/draggable
//= require jquery-ui/widgets/droppable
//= require jquery-ui/widgets/resizable
//= require jquery-ui/widgets/selectable
//= require jquery-ui/widgets/sortable
//= require_tree .
//= require commons

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("jquery-ui")
global.$ = require("jquery")
require('@nathanvda/cocoon')
import 'select2';
require("packs/formats/format_datatable")
require("packs/formats/datepicker")
require("packs/commons")
require( 'datatables.net' )
require("packs/users")
require("packs/worker") 
require("packs/supplier_categories") 
require("packs/deposit_methods") 
require("packs/locations") 
require("packs/customer_categories") 
require("packs/product_categories") 
require("packs/units") 
require("packs/post_codes") 
require("packs/onsites") 
require("packs/payment_methods") 
require("packs/customers")
require("packs/suppliers")
require("packs/master_datatables")
require("packs/master_products")
require("packs/pdf/pdf")

