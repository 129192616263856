import { move_top_item } from './commons';
import { move_up_item } from './commons';
import { move_down_item } from './commons';
import { move_bottom_item } from './commons';
import { call_sortable_list } from './commons';
import { selected_item } from './commons';
import { update_index_item } from './commons';

$(document).on('turbolinks:load', function() {

  const myModal = document.getElementById('modal-reorder-product-categories');
    if (myModal) {
      myModal.addEventListener('shown.bs.modal', function () {
      call_sortable_list("#list_sort_product_category");
    });
  }
  
  $("body").delegate('#btnMoveTopSortProductCategory', 'click', function () {
    move_top_item("#list_sort_product_category", '.item-product-category');
  });
  
  $("body").delegate('#btnMoveUpSortProductCategory', 'click', function () {
    move_up_item("#list_sort_product_category");
  });
  
  $("body").delegate('#btnMoveDownSortProductCategory', 'click', function () {
    move_down_item("#list_sort_product_category")
  });
  
  $("body").delegate('#btnMoveBottomSortProductCategory', 'click', function () { 
    move_bottom_item("#list_sort_product_category", '.item-product-category');
  });
  
  $("body").delegate('.item-product-category', 'click', function () {
    selected_item('.item-product-category', this);
  });
  
  $("body").delegate('#btnUpdateIndexProductCategories', 'click', function () {
    update_index_item("#list_sort_product_category", "/update_index_product_categories");
  });
  
  const modal_confirm_remove_product_category = document.getElementById('modal-confirm-remove-product-category');
  if (modal_confirm_remove_product_category) {
    modal_confirm_remove_product_category.addEventListener('shown.bs.modal', function () {
      $("#modal-edit-product-category").addClass("modal-blur");
    });
    modal_confirm_remove_product_category.addEventListener('hidden.bs.modal', function () {
      $("#modal-edit-product-category").removeClass("modal-blur");
    });
  }
  
  const modal_confirm_change_product_category = document.getElementById('modal-confirm-change-product-category');
  if (modal_confirm_change_product_category) {
    modal_confirm_change_product_category.addEventListener('shown.bs.modal', function () {
      $("#modal-edit-product-category").addClass("modal-blur");
    });
    modal_confirm_change_product_category.addEventListener('hidden.bs.modal', function () {
      $("#modal-edit-product-category").removeClass("modal-blur");
    });
  } 
    

});
