$(document).on('turbolinks:load', function () {
	loadTouchSpin();
	loadMeasurementInfo();
	load_work_report_info();
	formatallDatepicker();
	format_jp_money_value_load();
	format_jp_money_value_change();
	format_datepicker_current_day('#work_report_datepicker', '.work_report_datepicker_value');
	$("#customer_name_select_field_1").select2({
	});
	$(".select2-selection.select2-selection--single").addClass("form-select");
	$('b[role="presentation"]').hide();

	$("#customer_name_select_field_1").on('select2:select', function (event) {
		var selectedOption = event.params.data;
    	var customerId = selectedOption.id;
		$.ajax({
			url: '/handle_selected_field',
			method: 'GET', 
			data: { customerId: customerId },
			dataType: "script",
			success: function (response) {
				$("#site_id_select_option").select2({

				});
			
				$("#site_id_select_option").on('select2:select', function (e) {
					var selectedValue = e.params.data;
					if (typeof selectedValue.isNew === 'undefined') {
						var selectedValueId = selectedValue.id;
						$.ajax({
							url: '/handle_site_selected_field',
							method: 'GET',
							data: { option: selectedValueId},
							dataType: "script",
							success: function (response) {
							},
							error: function (error) {
							}
						});
					}else{
						$("#construction_company").val("");
					}

					if( selectedValue.text === "(Select One)"){
						$("#title_site_name").text("");
						$("#content_site_name").text("");
						$('#construction_company').prop('disabled', true);
    					$('#construction_company').prop('readonly', true);
					}else{
						$("#content_site_name").text(selectedValue.text);
						$("#title_site_name").text("現 場 名");
						$('#construction_company').prop('disabled', false);
						$('#construction_company').prop('readonly', false);
					}

				});
 
			},
			error: function (error) {
			  // Handle the AJAX error
			  // Display an error message or take appropriate action
			} 
		  });
		if (selectedOption.text === "Choose ..."){
			$("#content_customer_name").text("");
			$("#title_customer_name").text("");		
			$('#construction_company').prop('disabled', true);
			$('#construction_company').prop('readonly', true);
		}else{
			$("#content_customer_name").text(selectedOption.text);
			$("#title_customer_name").text("得意先名");
		}
		$("#title_site_name").text("");
		$("#content_site_name").text("");
		$("#content_team_name").text("");
		$("#title_team_name").text("");
		$("#construction_company").val("")
	});

	var contentSiteName = $('#content_site_name').text().trim();
	var contentTeamName = $('#content_team_name').text().trim();
	var contentCustomerName = $('#content_customer_name').text().trim();
	
	if (contentSiteName !== '') {
	  $('#title_site_name').text('現 場 名');
	} else {
	  $('#title_site_name').text('');
	}
	
	if (contentTeamName !== '') {
	  $('#title_team_name').text('建設組名');
	} else {
	  $('#title_team_name').text('');
	}

	if (contentCustomerName !== '') {
	  $('#title_customer_name').text('得意先名');
	} else {
	  $('#title_customer_name').text('');
	}
	$("#site_id_select_option").select2({});	
	$("#site_id_select_option").on('select2:select', function (e) {
		var selectedValue = e.params.data;
		if (typeof selectedValue.isNew === 'undefined') {
			var selectedValueId = selectedValue.id;
			$.ajax({
				url: '/handle_site_selected_field',
				method: 'GET',
				data: { option: selectedValueId},
				dataType: "script",
				success: function (response) {
				},
				error: function (error) {
				}
			});
		}else{
			$("#construction_company").val("");
		}

		if( selectedValue.text === "(Select One)"){
			$("#title_site_name").text("");
			$("#content_site_name").text("");
			$('#construction_company').prop('disabled', true);
			$('#construction_company').prop('readonly', true);
		}else{
			$("#content_site_name").text(selectedValue.text);
			$("#title_site_name").text("現 場 名");
		}

	});
	

	$("body").delegate("#construction_company", "input", function (event) {
		var construction_teams_select_option = $(this).val()
		if (construction_teams_select_option === ""){
			$("#content_team_name").text("");
			$("#title_team_name").text("");
		}else{
			$("#content_team_name").text(construction_teams_select_option);
			$("#title_team_name").text("建設組名");
		}
	});


	  var currentTime = new Date();
	  var currentHour = currentTime.getHours();
	  var currentMinute = currentTime.getMinutes();

	  if (currentMinute < 30) { 
		currentMinute = 0;
	  } else {
		currentHour++;
		currentMinute = 0;
	  }
	
	  $('#start_hour_work_report').val(currentHour);
	  $('#start_minute_work_report').val(currentMinute);
	  $('#end_hour_work_report').val(currentHour + 1);
	  $('#end_minute_work_report').val(currentMinute);

	  $('body').delegate('.unit-price-cost', 'input', function(e) {
		var unit_price_cost = change_type_number_value($(this).val());
		show_total_measurement_with_unit_price_cost(this)
	  });

    var tblWorkReport = document.getElementsByClassName("tbl-work-report");
	if (tblWorkReport.length > 0) {

		
		window.$('.tbl-work-report').DataTable({
			stateSave: false,
			'destroy': true,
			'columnDefs': [
				{
					'targets': 0,
					'width': "5%",
					'checkboxes': {
						'selectRow': true
					}
				},
				{ "width": "5%", "targets": 1 },
				{ "width": "30%", "targets": 2 },
				{ "width": "20%", "targets": 3 },
				{ "width": "20%", "targets": 4 },
				{ "width": "20%", "targets": 5 },
			],
			'select': {
				'style': 'multi'
			},
			'order': [1, 'asc'],
			drawCallback: function () {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language: {
				paginate: {
					previous: "<i class='mdi mdi-chevron-left'>",
					next: "<i class='mdi mdi-chevron-right'>"
				}
			},
			paging: true,
			fixedColumns: {
				leftColumns: 1
			},
		});
	}; 
	
		// $('.edit_work_report').submit(function() {
		// 	updatevalueWork();
		// });
		
	  $('body').delegate('#add_new_measurement_info_field', 'click', function (e){
		$("#create_new_measurement_detail_field").click();
		load_work_report_info();
		loadMeasurementInfo();
		handleMasterProductSelect();
		format_jp_money_value_load();
		format_jp_money_value_change();
		loadTouchSpin();
	  });

	  $('body').delegate('.add-new-measurement-info', 'click', function (e){
		  var measurement_field = $(this).closest(".measurement-field").attr('id');
		  var measurement_field_position_no = measurement_field.replace(/\D/g, '');
		//   var measurement_info_id = $(this).closest(".measurement-field").attr('data-item-id');
		  var work_report_id = $(this).closest(".measurement-field").attr('data-parent-id');
		  var measurement_info_site_name = $(this).closest(".measurement-field").find('.work-place-input').val();
		  var measurement_info_product_id = $(this).closest(".measurement-field").find(".selected-product").val()
		  var measurement_info_unit_price_id = $(this).closest(".measurement-field").find(".handle-select-unit-price").val();
		  var measurement_info_unit_price_cost  = $(this).closest(".measurement-field").find(".unit-price-cost ").val();
		  $.ajax({
			url: '/handle_selected_option_measurement_info',
			method: 'GET',
			data: { 
				// measurement_info_id: measurement_info_id,
				work_report_id: work_report_id,
				measurement_info_site_name: measurement_info_site_name,
				measurement_info_product_id: measurement_info_product_id,
				measurement_info_unit_price_id: measurement_info_unit_price_id,
				measurement_info_unit_price_cost: measurement_info_unit_price_cost,
				measurement_field_position_no: measurement_field_position_no,
			},
			dataType: "script",
			success: function (response) {
			},
			error: function (error) {
			}
		});
		  $("#modal_select_type_measurement").attr("data-item", measurement_field_position_no);
	  });

	  $('body').delegate('#clone_measurement_detail', 'click', function (e){
		$(this).closest(".measurement-type-zone").find('.add-new-measurement-row').click();
		var measurement_option_zone = $(this).closest(".measurement-option-zone");
		var measurement_info_row = $(this).closest(".measurement-row");
		var data_item = measurement_option_zone.data("item");
		var condition = '.measurement-row:not([data-item='+data_item+'])';
		measurement_option_zone.find(condition).remove();
		var added_measurement_row = measurement_option_zone.find(".measurement-row:last");
		var id = $(this).closest(".measurement-field").attr("id");
		var measurement_type_id = measurement_option_zone.find(".measurement-type-zone").attr("id")
        get_no_order("#"+measurement_type_id, ".measurement-stt", "measurement-row");
		var measurement_info_row_type = measurement_info_row.data("item");
		//get value from selected row
		var joint_width_value = measurement_info_row.find(".joint-width-value").val();
		var joint_height_value = measurement_info_row.find(".joint-height-value").val();
		var dimensions_width_value = measurement_info_row.find(".dimensions-width-value").val();
		var quantity_value = measurement_info_row.find(".quantity-value").val();
		var measurement_row_value = measurement_info_row.find(".measurement-row-value").val();
		var note_text = measurement_info_row.find(".note-text").val();
		var product_value = measurement_info_row.find(".product-price-value").val();
		var final_price_value = measurement_info_row.find(".final-price-value").val();
		clone_to_new_measurement_row(added_measurement_row, joint_width_value, joint_height_value, 
			dimensions_width_value, quantity_value, measurement_row_value, note_text, product_value, final_price_value);
		switch(measurement_info_row_type) {
			case 1:
			  var dimensions_height_value = measurement_info_row.find(".dimensions-height-value").val();
			  $(added_measurement_row).find(".dimensions-height-value").val(dimensions_height_value);
			  break;
			case 2:
				var dimensions_height_value = measurement_info_row.find(".dimensions-height-value").val();
				$(added_measurement_row).find(".dimensions-height-value").val(dimensions_height_value);
			  break;
			case 5:
				var joint_width_b_value = measurement_info_row.find(".joint-width-b-value").val();
				var joint_height_b_value = measurement_info_row.find(".joint-height-b-value").val();
				$(added_measurement_row).find(".joint-width-b-value").val(joint_width_b_value);
				$(added_measurement_row).find(".joint-height-b-value").val(joint_height_b_value);
			default:
		}
		loadTouchSpin();
		show_total_measurement_cost(this);
		format_jp_money_value_load();
	  	format_jp_money_value_change();
	  });

	  function clone_to_new_measurement_row(added_measurement_row, joint_width_value, joint_height_value, 
		dimensions_width_value, quantity_value, measurement_row_value, note_text, product_value, final_price_value){
			$(added_measurement_row).find(".joint-width-value").val(joint_width_value);
			$(added_measurement_row).find(".joint-height-value").val(joint_height_value);
			$(added_measurement_row).find(".dimensions-width-value").val(dimensions_width_value);
			$(added_measurement_row).find(".quantity-value").val(quantity_value);
			$(added_measurement_row).find(".measurement-row-value").val(measurement_row_value);
			$(added_measurement_row).find(".note-text").val(note_text);
			$(added_measurement_row).find(".product-price-value").val(product_value);
			$(added_measurement_row).find(".final-price-value").val(final_price_value);
	  };

	//   $('body').delegate('#add_new_other_cost_field', 'click', function (e){
	// 	$("#create_new_other_cost_field").click();
	// 	format_jp_money_value_load();
	// 	format_jp_money_value_change();
	//   });

	//   $('body').delegate("#list_other_cost", 'cocoon:after-insert', function() {
	// 	get_no_order("#list_other_cost", ".numerical-order", "other-record-rows");
    //   });

	//   $('body').delegate('#delete_other_cost_row', 'click', function (e){
	// 	$(this).closest(".other-record-rows").find("#remove_other_cost_row").click();
	// 	// get_no_order_after_delete("#list_other_cost", ".numerical-order", "other-record-rows", this);
	// 	show_total_other_cost_after_delete(this);
	//   });
	  formatallDatepicker();
	  $('body').delegate('#add_new_labor_cost_row', 'click', function (e){
		$("#create_new_labor_cost_row").click();
		get_no_order("#labor_cost_list", ".labor-cost-no", "labor-cost-rows");
		formatDatepicker();
		format_jp_money_value_load();
		format_jp_money_value_change();
	  });
	  $('body').delegate('#delete_labor_cost_detail', 'click', function (e){
		$(this).closest(".labor-cost-rows").find("#remove_labor_cost_detail").click();
		get_no_order_after_delete("#labor_cost_list", ".labor-cost-no", "labor-cost-rows", this);
	  });

	  $('body').delegate('#delete_measurement_button', 'click', function (e){
		var measurememt_type_zone = $(this).closest(".measurement-option-zone");
		$(this).closest(".measurement-row").find("#remove_measurement_row").click();
		if (measurememt_type_zone.find("div.measurement-row:not([style*='display: none'])").length === 1){
			measurememt_type_zone.addClass("d-none");
			var data_type = measurememt_type_zone.data("item").toString();
			var list_type = $(measurememt_type_zone).closest(".measurement-field").find(".list-option-measurement-field").val();
			var array_list_type = list_type.split(",");
			if ($.inArray(data_type, array_list_type) !== -1) { 
				array_list_type.splice($.inArray(data_type, array_list_type), 1);
			}		  
			var result = array_list_type.join(",");
			$(measurememt_type_zone).closest(".measurement-field").find(".list-option-measurement-field").attr("value", result)
		}
		var id = measurememt_type_zone.find('.measurement-type-zone').attr("id");
		get_no_order_after_delete("#"+id, ".measurement-stt", "measurement-row", this);
		show_total_measurement_cost_after_delete(this);
	  });

	$('body').delegate('#confirm_delete_work_report', 'click', function (e){
		var objectDataSubmit = {id: this.getAttribute('data-item-id'), status: this.getAttribute('data-item-id')};
		AjaxSubmit( '/work_reports/confirm_delete_work_report','GET',objectDataSubmit);
	});

	$('body').delegate('#close_report_modal', 'click', function (e){
		$(this).closest(".text-end").find("#close_modal").click();
	});

	$('body').delegate('#input_total_labor_cost', 'input', show_total_labor_cost);

	// $('body').delegate('.other-cost-value', 'input', show_total_other_cost);

	$('body').delegate('.measurement-row-value', 'input propertychange', function (e) {
		show_final_unit_price(this)
		show_total_measurement_cost(this);
	});

	$('body').delegate('.product-price-value', 'input propertychange', function (e) {
		var measurement_total = $(this).closest('.measurement-row').find(".measurement-row-value");
		show_final_unit_price(measurement_total);
	});

	$('body').delegate('.work-place-input', 'input', function (e) {
		change_construction_place_name(this);
	});

	$('body').delegate('.selected-product', 'change', function (e) {
		change_product_name(this);
		var product_id = $(this).val();
		var unit_price_id = $(this).closest(".measurement-field").find(".handle-select-unit-price").val();
		var unit_price_cost_id = $(this).closest(".measurement-field").find(".unit-price-cost ").attr('id');
		if (unit_price_id !== ''){
			get_unit_price_product_number(product_id, unit_price_id, unit_price_cost_id);
		}
	});

	$('body').delegate('.handle-select-unit-price', 'change', function (e) {
		var product_id = $(this).closest(".measurement-field").find(".selected-product").val()
		var unit_price_cost_id = $(this).closest(".measurement-field").find(".unit-price-cost ").attr('id');
		var unit_price_id =  $(this).val();
		if (product_id !== ''){
			get_unit_price_product_number(product_id, unit_price_id, unit_price_cost_id);
		}
	});

	$('body').delegate('#update_report', 'click', function (e){
		$("#hidden_update_report").click();
	});

	hide_all_first_option();

	$('body').delegate('#check_before_submit_form', 'click', function (e){
		$("#update_work_report_form").click();
	});

	$('body').delegate('.quantity-value', 'input propertychange', function (e) {
		var measurememt_type = $(this).closest(".measurement-option-zone").data("item");
		var caculate_type_3_condition = "3,4,5";
		var measurement_row_value = 0;
		var new_current = parseFloat(change_type_number_value($(this).val()));
		if (isNaN(new_current)){
			new_current = 0;
		}
		var dimensions_width = parseFloat(change_type_number_value($(this).closest(".measurement-row").find(".dimensions-width-value").val()));
		if (isNaN(dimensions_width)){
			dimensions_width = 0;
		}
		if (caculate_type_3_condition.includes(measurememt_type)){
			measurement_row_value = parseFloat((dimensions_width*new_current/1000).toFixed(2)) ;
		}else{
			var dimensions_height = parseFloat(change_type_number_value($(this).closest(".measurement-row").find(".dimensions-height-value").val()));
			if (isNaN(dimensions_height)){
				dimensions_height = 0;
			}
			if (measurememt_type === 1){
				measurement_row_value = parseFloat(((dimensions_width/1000 + dimensions_height/1000)*4*new_current).toFixed(2)) ;
			  } else if (measurememt_type === 2){
				measurement_row_value = parseFloat(((dimensions_width/1000 + dimensions_height/1000)*2*new_current).toFixed(2)) ;
			  } else {
				measurement_row_value = 0;
			  }
		}
		measurement_row_value = measurement_row_value.toLocaleString('en-US')
		var parts = measurement_row_value.split('.');
		if (parts[1] && parts[1].length === 1) {
		  measurement_row_value += '0';
		}
	  
		measurement_row_value = measurement_row_value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		var measurement_total = $(this).closest(".measurement-row").find(".measurement-row-value");
		measurement_total.val(measurement_row_value);
		show_final_unit_price(measurement_total);
		show_total_measurement_cost(measurement_total);
	});

	$('body').delegate('.dimensions-height-value', 'input propertychange', function (e) {
		var measurememt_type = $(this).closest(".measurement-option-zone").data("item");
		var measurement_row_value = 0;
		var new_current = parseFloat(change_type_number_value($(this).val()));
		if (isNaN(new_current)){
			new_current = 0;
		}
		var dimensions_width = parseFloat(change_type_number_value($(this).closest(".measurement-row").find(".dimensions-width-value").val()));
		if (isNaN(dimensions_width)){
			dimensions_width = 0;
		}
		var quantity_value = parseFloat(change_type_number_value($(this).closest(".measurement-row").find(".quantity-value").val()));
		if (isNaN(quantity_value)){
			quantity_value = 0;
		}
		if (measurememt_type === 1){
			measurement_row_value = parseFloat(((dimensions_width/1000 + new_current/1000)*4*quantity_value).toFixed(2)) ;
		  } else if (measurememt_type === 2){
			measurement_row_value = parseFloat(((dimensions_width/1000 + new_current/1000)*2*quantity_value).toFixed(2)) ;
		  } else {
			measurement_row_value = 0;
		}
		measurement_row_value = measurement_row_value.toLocaleString('en-US')
		var parts = measurement_row_value.split('.');
		var parts = measurement_row_value.split('.');
		if (parts[1] && parts[1].length === 1) {
		  measurement_row_value += '0';
		}
	  
		measurement_row_value = measurement_row_value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		var measurement_total = $(this).closest(".measurement-row").find(".measurement-row-value");
		measurement_total.val(measurement_row_value);
		show_final_unit_price(measurement_total);
		show_total_measurement_cost(measurement_total);
	});

	$('body').delegate('.dimensions-width-value', 'input propertychange', function (e) {
		var measurememt_type = $(this).closest(".measurement-option-zone").data("item");
		var caculate_type_3_condition = "3,4,5";
		var measurement_row_value = 0;
		var new_current = parseFloat(change_type_number_value($(this).val()));
		if (isNaN(new_current)){
			new_current = 0;
		}
		var quantity_value = parseFloat(change_type_number_value($(this).closest(".measurement-row").find(".quantity-value").val()));
		if (isNaN(quantity_value)){
			quantity_value = 0;
		}
		if (caculate_type_3_condition.includes(measurememt_type)){
			measurement_row_value = parseFloat((new_current*quantity_value/1000).toFixed(2)) ;
		}else{
			var dimensions_height = parseFloat(change_type_number_value($(this).closest(".measurement-row").find(".dimensions-height-value").val()));
			if (isNaN(dimensions_height)){
				dimensions_height = 0;
			}
			if (measurememt_type === 1){
				measurement_row_value = parseFloat(((new_current/1000 + dimensions_height/1000)*4*quantity_value).toFixed(2)) ;
			  } else if (measurememt_type === 2){
				measurement_row_value = parseFloat(((new_current/1000 + dimensions_height/1000)*2*quantity_value).toFixed(2)) ;
			  } else {
				measurement_row_value = 0;
			  }
		}
		measurement_row_value = measurement_row_value.toLocaleString('en-US')
		var parts = measurement_row_value.split('.');
		var parts = measurement_row_value.split('.');
		if (parts[1] && parts[1].length === 1) {
		  measurement_row_value += '0';
		}
	  
		measurement_row_value = measurement_row_value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		var measurement_total = $(this).closest(".measurement-row").find(".measurement-row-value");
		measurement_total.val(measurement_row_value);
		show_final_unit_price(measurement_total);
		show_total_measurement_cost(measurement_total);
	});


	$('work_report_start_hour').change(function() {
		var time_start_hour = $("#work_report_start_hour").val();
		var time_start_minutes = $("#work_report_start_minute").val();
		var time_end_hour = $("#work_report_end_hour").val();
		var time_end_minutes = $("#work_report_end_minute").val();
		if (time_start_hour == '') {
		  $("#work_report_start_minute").val('');
		} 
		else {
		  if (time_start_minutes == '' && time_end_hour == '' && time_end_minutes == '') {
			$("#work_report_start_minute").val(0);
			$("#work_report_end_hour").val(parseInt(time_start_hour) + 1);
			$("#work_report_end_minute").val(0);
		  }
		  else if(time_start_minutes == '') {
			$("#work_report_start_minute").val(0);
		  }
		  else{}
		}
	  });

	  $("#work_report_end_hour").change(function() {
		var time_start_hour = $("#work_report_start_hour").val();
		var time_start_minutes = $("#work_report_start_minute").val();
		var time_end_hour = $("#work_report_end_hour").val();
		var time_end_minutes = $("#work_report_end_minute").val();
		if (time_end_hour == '') {
		  $("#work_report_end_minute").val('');
		}
		else {
		  if (time_end_minutes == '') $("#work_report_end_minute").val(0);
		}
	  });

	$("body").delegate('#work_report_start_hour', 'click', function (e) {
		var time_start_hour = $("#work_report_start_hour").val();
		var time_start_minutes = $("#work_report_start_minute").val();
		var time_end_hour = $("#work_report_end_hour").val();
		var time_end_minutes = $("#work_report_end_minute").val();
		if (time_start_hour == '') {
			$("#work_report_start_minute").val('');
		} 
		else {
			if (time_start_minutes == '' && time_end_hour == '' && time_end_minutes == '') {
				$("#work_report_start_minute").val(0);
				$("#work_report_end_hour").val(parseInt(time_start_hour) + 1);
				$("#work_report_end_minute").val(0);
			}
			else if(time_start_minutes == '') {
				$("#work_report_start_minute").val(0);
			}
			else{}
		}
	});

	$("body").delegate('#work_report_end_hour', 'click', function (e) {
		var time_start_hour = $("#work_report_start_hour").val();
		var time_start_minutes = $("#work_report_start_minute").val();
		var time_end_hour = $("#work_report_end_hour").val();
		var time_end_minutes = $("#work_report_end_minute").val();
		if (time_end_hour == '') {
			$("#work_report_end_minute").val('');
		}
		else {
			if (time_end_minutes == '') $("#work_report_end_minute").val(0);
		}
	});

	$(document).ready(function(){
		var measurement_field = $(".measurement-field");
		var labor_cost_rows = $(".labor-cost-rows");
		// var other_record_rows = $(".other-record-rows");
		if (measurement_field.length < 1){
			$("#add_new_measurement_info_field").click(); 
		}
		if (labor_cost_rows.length < 1){
			$("#add_new_labor_cost_row").click(); 
		}
		// if (other_record_rows.length < 1){
		// 	$("#add_new_other_cost_field").click(); 
		// }
	});

	
	$('body').delegate('#show_again_work_report', 'click', function (e){
		var form = $($(this).closest(".container-fluid ").find(".tab-pane.active").find("form"));
		var isValid = true;
		form.find('[required]').each(function() {
			if ($(this).val() === '') {
			isValid = false;
			return false; 
			}
		});

		if (!isValid) {
			return;
		}
		form.submit(function(event) {
			event.preventDefault();

			// Get the existing form data
			var formData = new FormData(form[0]);

			// Add additional parameters
			formData.append('show_button', '1');
			// Submit the form with the updated data
			$.ajax({
				url: form.attr('action'),
				type: 'POST',
				data: formData,
				processData: false,
				contentType: false,
				success: function(response) {
					$('#preview_work_report').click();
				}
			});
		});
		form.submit();
	});
	$('body').delegate('#preview_work_report', 'click', function (e){
		var company_name = $("#work_report_customer_company_id").find(":selected").val();
		var site = $("#work_report_site_id").find(":selected").val();
		var team = $("#work_report_construction_company").find(":selected").val();
		var work_report_start_hour = $("#work_report_start_hour").find(":selected").val();
		var work_report_start_minute = $("#work_report_start_minute").find(":selected").val();
		var work_report_end_hour = $("#work_report_end_hour").find(":selected").val();
		var work_report_end_minute = $("#work_report_end_minute").find(":selected").val();
		var work_report_weather = document.getElementById("work_report_weather").value;
		var objectDataSubmit = {id: this.getAttribute('data-item-id'), status: this.getAttribute('data-item-id')
		, company_name: company_name, site: site, team: team, 
		work_report_start_hour: work_report_start_hour, work_report_start_minute: work_report_start_minute,
		work_report_end_hour: work_report_end_hour, work_report_end_minute: work_report_end_minute,
		work_report_weather: work_report_weather};
		format_jp_money_value_load();
		format_jp_money_value_change();
		$.ajax({
			url: '/work_reports/show_work_report_detail',
			type: 'GET',
			data: objectDataSubmit,
			success: function(response) {
				format_jp_money_value_load();
				format_jp_money_value_change();
			},
			error: function(xhr, status, error) {
			}
		});
	});
	
	var unsavedChanges = false;
	$("body").delegate(".tab-pane.active :input", "change", function (event) {
		unsavedChanges = true;
	});
	$("body").delegate(".dripicons-plus, .dripicons-minus", "click", function (event) {
		unsavedChanges = true;
	});
	$("body").delegate("#add_new_measurement_info_field, .delete-new-measurement-info, #delete_measurement_button, #add_new_measurement_info", "mousedown", function (event) {
		unsavedChanges = true;
	});

	$("body").delegate(".custom-dropdown item-container", "click", function (event) {
		unsavedChanges = true;
	});

	$("body").delegate(" .select2-selection__choice__remove, .select2-results__option select2-results__option--selectable, #delete_labor_cost_detail, .select2-selection__choice__display", "mousedown", function (event) {
		unsavedChanges = true;
	});

	$("body").delegate("#labor_cost_list button, #labor_cost_list span", "mousedown", function (event) {
		unsavedChanges = true;
	});

	// $("body").delegate("#delete_other_cost_row", "click", function (event) {
	// 	unsavedChanges = true;
	// });

	$("body").delegate(".submit-work-report-info, #show_again_work_report", "click", function (event) {
		unsavedChanges = false;
	});

	var tabLinks = $('.nav-link');
	tabLinks.each(function(index, link) {
	$(link).on('click', function(event) {
		var targetTab = $(this).attr('href');
		if (unsavedChanges) {
			event.preventDefault();
			var tabPanes = $('.tab-pane');
			tabPanes.removeClass('active');
			var navLinks = $('.nav-link').has('span.text-decoration-underline').attr("href")
			if ($('.tab-pane'+navLinks).length) {
				$('.tab-pane'+navLinks).addClass('active');
			}
			window.$('#modal_show_confirm_report').modal('show');
			$('#confirm_submit_button').on('click', function(event) {
				window.$('#modal_show_confirm_report').modal('hide')
				$($(navLinks).find(".real_submit_button")).click();
				var tabPanes = $('.tab-pane');
				tabPanes.removeClass('active');
				$(targetTab).addClass('active');
				$('.nav-link').find("span").removeClass('text-decoration-underline text-primary');
				$('.nav-link.active').find("span").addClass('text-decoration-underline text-primary');
				$(targetTab).show();
				unsavedChanges = false;
				var tabId = $(link).attr('href').replace('#workers_input_', '');
				if (tabId === '1' || tabId === '2' || tabId === '0') {
					history.pushState(null, null, '?tab=' + tabId);
					activateTab(tabId);
				  }
				return false;
			});
			$('#cancel_submit_button').on('click', function(event) {
				unsavedChanges = true;
			});
		}
		var tabId = $(link).attr('href').replace('#workers_input_', '');
		if (unsavedChanges === false){
			if (tabId === '1' || tabId === '2' || tabId === '0') {
				history.pushState(null, null, '?tab=' + tabId);
				activateTab(tabId);
			  }
			return false;
		}
		});
	});

	function activateTab(tabId) {
		tabLinks.each(function(index, link) {
			$(link).removeClass('active');
			$(link).find('span').removeClass('text-primary text-decoration-underline');
		});
		var tabPanes = $('.tab-pane');
		tabPanes.removeClass('active');
		var selectedTabLink = $('#work_input_tab_' + tabId);
		var selectedTabSpan = selectedTabLink.find('span');
		var selectedTabPane = $('#workers_input_' + tabId);
		selectedTabLink.addClass('active');
		selectedTabSpan.addClass('text-primary text-decoration-underline');
		selectedTabPane.addClass('active');
	}

	function getQueryParam(name) {
		var urlParams = new URLSearchParams(window.location.search);
		return urlParams.get(name);
	}

	var tabParam = getQueryParam('tab');
	if (tabParam) {
		activateTab(tabParam);
	}

	window.addEventListener('popstate', function(event) {
	var tabParam = getQueryParam('tab');
	if (tabParam) {
		activateTab(tabParam);
	}
	});

	handle_work_report_status_table();
  
	$("body").delegate('.work-report-status-dropdown-menu-select-type  .icon-container button', 'click', function(e) {
		e.stopPropagation();
	});

	handleWorkReportModal();

	$("body").delegate("#open_search_modal", "click", function (event) {
		window.$('#modal_search_work_report').modal('show');
	});

	$(document).on("submit", "#work-report-form", function(e) {
		e.preventDefault(); // Prevent the default form submission behavior
	  
		var form = $(this);pdf-option
		var url = form.attr("action");
		var method = form.attr("method");
		var data = form.serialize();
	  
		$.ajax({
		  url: url,
		  method: method,
		  data: data,
		  dataType: "script", // Expecting a JavaScript response
		  success: function(response) {
			$('.nested-fields').cocoon();
		  },
		  error: function(xhr, status, error) {
			// Handle any error that occurs during the AJAX request
		  }
		});
	  });

	// Add a change event listener to the select element
	$("body").delegate('#pdf-option','change', function() {
		updatePDFLink('#pdf-option');
	});
})


function changeArea(){
// remove class selected all item
	var selected = 'selected';
  	$('.button-area').removeClass(selected);  
	// add class selected for current item selected
	this.classList.add(selected);
}

function updatePDFLink(current) {
    var selectedOption = $('#pdf-option').val();
	var work_report_id = $(current).closest("#measurement_list_row").find("#measurement_field_0").attr('data-parent-id');
    var pdfLink;

    // Determine the PDF link based on the selected option
    if (selectedOption === '1') {
      pdfLink = 1;
    } else if (selectedOption === '2') {
      pdfLink = 2;
    }
	$.ajax({
		url: '/handle_pdf_option_button', 
		method: 'GET', 
		data: { option: pdfLink, work_report_id:work_report_id},
		dataType: "script",
		success: function (response) {
		},
		error: function (error) {
		}
	  });
  }

window.get_no_order = function(list, no_order, rows){
	var list_rows = $(list).find('.'+rows+':not([style*="display: none"])');
	list_rows.each(function (index, elem) {
		jQuery($(elem).find(no_order)).html(index+1);
	});
}

window.get_no_order_after_delete = function(list, no_order, rows, current){
	var value = $(list).find("."+rows);
	var count = 0;
	value.each(function (index, elem) {
		if( !elem.isEqualNode($(current).closest("."+rows)[0]) ){
			if($(elem).closest('.'+rows+':not([style*="display: none"])').length !== 0 ){
				jQuery($(list).find(no_order)[index]).html(count+1);
				count += 1;
			}
		}
	});
}

window.load_work_report_info = function(){
	var measurement_field = document.getElementsByClassName("measurement-field");
	get_no_order("#labor_cost_list", ".labor-cost-no", "labor-cost-rows");
	// get_no_order("#list_other_cost", ".numerical-order", "other-record-rows");
	format_input_iso_file_tags('#list_users', '#hidden_list_users');
	show_total_labor_cost();
	// show_total_other_cost();
	if (measurement_field.length !== 0){
		var measurement_list_row = document.getElementById("measurement_list_row");

		// var measurement_total_cost = document.getElementsByClassName("measurement-total-cost");
		var work_place_input = document.getElementsByClassName("work-place-input");
		var selected_product = document.getElementsByClassName("selected-product");
		var work_place_info = document.getElementsByClassName("work-place-info");
		var product_info = document.getElementsByClassName("product-info");
		var measurement_info_header = document.getElementsByClassName("measurement-info-header");
		var measurement_info_body = document.getElementsByClassName("measurement-info-body");
		//var unit_price_cost = document.getElementsByClassName("unit-price-cost");
		var add_opttion_1 = document.getElementsByClassName("add-new-measurement-detail-fields");
		var add_opttion_2 = document.getElementsByClassName("add-new-standart-type-one-side-fields");
		var add_opttion_3 = document.getElementsByClassName("add-new-measurement-first-optional-fields");
		var add_opttion_4 = document.getElementsByClassName("add-new-measurement-second-optional-fields");
		var add_opttion_5 = document.getElementsByClassName("add-new-measurement-third-optional-fields");
		var measurement_option_zone_1 = document.getElementsByClassName("measurement-standart-type-both-side-field");
		var measurement_option_zone_2 = document.getElementsByClassName("measurement-standart-type-one-side-field");
		var measurement_option_zone_3 = document.getElementsByClassName("measurement-first-optional-field");
		var measurement_option_zone_4 = document.getElementsByClassName("measurement-second-optional-field");
		var measurement_option_zone_5 = document.getElementsByClassName("measurement-third-optional-field");
		var work_name, product_name;
		for (var i = 0; i < measurement_field.length; i++) {
			measurement_field[i].id = 'measurement_field_' + i;
			measurement_info_header[i].id = 'measurement_info_header_' +i;
			$('#'+measurement_info_header[i].id).attr("href", '#measurement_info_body_'+i);
			$('#'+measurement_info_header[i].id).attr("aria-controls", '#measurement_info_body_'+i);
			measurement_info_body[i].id = 'measurement_info_body_' +i;
			//measurement_total_cost[i].id = "measurement_total_cost_" + i;
			work_place_input[i].id = 'work_place_input_' + i;
			selected_product[i].id = 'selected_product_' + i;
			work_place_info[i].id = 'work_place_info_' + i;
			product_info[i].id = 'product_info_' + i;
			//unit_price_cost[i].id = 'unit_price_cost_' + i;
			add_opttion_1[i].id = "add_new_measurement_detail_fields_" + i;
			add_opttion_2[i].id = "add_new_standart_type_one_side_fields_" + i;
			add_opttion_3[i].id = "add_new_measurement_first_optional_fields_" + i;
			add_opttion_4[i].id = "add_new_measurement_second_optional_fields_" + i;
			add_opttion_5[i].id = "add_new_measurement_third_optional_fields_" + i;
			measurement_option_zone_1[i].id = "measurement_standart_type_both_side_field_" + i;
			measurement_option_zone_2[i].id = "measurement_standart_type_one_side_field_" + i;
			measurement_option_zone_3[i].id = "measurement_first_optional_field_" + i;
			measurement_option_zone_4[i].id = "measurement_second_optional_field_" + i;
			measurement_option_zone_5[i].id = "measurement_third_optional_field_" + i;
			$(work_place_info[i]).text($(work_place_input[i]).val());
			if ($(selected_product[i]).find(":selected").text() !== "Choose ..."){
				$(product_info[i]).text($(selected_product[i]).find(":selected").text()); 
			}
			get_no_order("#"+measurement_option_zone_1[i].id, ".measurement-stt", "measurement-row");
			get_no_order("#"+measurement_option_zone_2[i].id, ".measurement-stt", "measurement-row");
			get_no_order("#"+measurement_option_zone_3[i].id, ".measurement-stt", "measurement-row");
			get_no_order("#"+measurement_option_zone_4[i].id, ".measurement-stt", "measurement-row");
			get_no_order("#"+measurement_option_zone_5[i].id, ".measurement-stt", "measurement-row");
			show_total_measurement_cost_load(measurement_info_body[i].id);
		}
		// check_input_number_type();
		handleMasterProductSelect();
	}
}

window.format_input_iso_file_tags = function(add_worker_field, hidden_worker_field){
    $(add_worker_field).tokenfield({
          autocomplete: {
        source: function ( request, response ) {
                  var keyword = $(hidden_worker_field).val();
                  keyword == "" ? keyword = "" : keyword = keyword + ","
                  $.getJSON( "/filter_worker", {
                          term:  keyword + request.term,
                  }, response );
              },
              minLength: 0,
              delay: 100,
          },
          showAutocompleteOnFocus: true,
      })
  
    .on('tokenfield:createtoken', function (e) {
		var existingTokens = $(this).tokenfield('getTokens');
        $.each(existingTokens, function(index, token) {
            if (token.id === e.attrs.id)
                e.preventDefault();
        }); 
      })
  
    .on('tokenfield:createdtoken', function (e) {
		var arr_list_name = $(this).tokenfield('getTokensList').split(", ");
        var get_name = this;
        var list_id = split_format_name(arr_list_name, get_name);
		$(e.relatedTarget).attr("data-item-id", e.attrs.id);
        $(hidden_worker_field).val(list_id.join(","));
        if ($(hidden_worker_field).val()){
          $(add_worker_field).attr("placeholder");
        }else{
          $(add_worker_field).attr("placeholder", "Tag");
        }
      })
  
	.on('tokenfield:removedtoken', function (e) {
		var arr_list_name = $(this).tokenfield('getTokensList');
		if (arr_list_name !== ''){
		  var get_name = this;
		  var list_id = split_format_name(arr_list_name.split(", "), get_name);
		  $(hidden_worker_field).val(list_id.join(","));
		}
		else {
		  $(hidden_worker_field).val('');
		}
		if ($(hidden_worker_field).val()){
		  $(add_worker_field).attr("placeholder");
		}else{
		  $(add_worker_field).attr("placeholder", "Tag");
		}
		   
	  });
  
  }
  
function split_format_name(arr_list_name, get_name) {
	var user_id_list = [];
	$.each(arr_list_name, function(index, value) {
	var user_id = $(get_name).tokenfield('getTokens').filter(obj => {return obj.value === value})[0].id;
		user_id_list.push(user_id);
	});
	return user_id_list;
}

function date_picker_load_input_2(labor_date){
	var today = new Date();
	$(labor_date).datepicker({
		language: 'jp',
		autoclose: true,
		sideBySide: true,
	})
	.on('changeDate', function(e) {
	});
	$(labor_date).datepicker({
		dateFormat: 'yy-mm-dd',
		prevText:"&#x3C;前",
		nextText:"次&#x3E;",
		monthNames:["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"],
		dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
		dateFormat: 'yy-mm-dd',
		showMonthAfterYear: true,
		yearSuffix: '年'
	  });
}

function show_total_labor_cost(){
	var $total = $('#total_labor_cost');
	var $value = $('#input_total_labor_cost');
	$value.val() === '' ? $total.html(0) : $total.html(check_number_type(parseFloat($value.val())).toLocaleString('en-US'));
}

window.formatallDatepicker = function(){
	var listRow = document.querySelectorAll('.labor-cost-rows');
	var today = new Date();	
	for (let index = 0; index < listRow.length; index++) {
		const element = listRow[index];
		var dateControl = element.querySelector('.working-report-datepicker');
		$(dateControl).datepicker({
			language: 'jp',
			autoclose: true,
			sideBySide: true        
		})
		.on('changeDate', function(e) {
			this.parentNode.querySelector('.working-report-datepicker_value').value = $(this).datepicker('getDate');
		});		
		if(element.querySelector('.working-report-datepicker_value').value == ''){				
			$(dateControl).datepicker('setDate', today);
		}
		else{
			var dt =new Date( Date.parse(element.querySelector('.working-report-datepicker_value').value));
			$(dateControl).datepicker('setDate', dt);
		}
		
	}	
	}
	window.formatDatepicker = function(){
	var listRow = document.querySelectorAll('.labor-cost-rows');
	var endRow = listRow[listRow.length-1];
	// date picker
	var dateControl = endRow.querySelector('.working-report-datepicker');
	$(dateControl).datepicker({
		language: 'jp',
		autoclose: true,
		sideBySide: true        
	})
	.on('changeDate', function(e) {
		this.parentNode.querySelector('.working-report-datepicker_value').value = $(this).datepicker('getDate');
	});		
	var today = new Date();	
	$(dateControl).datepicker('setDate', today);
	// select 2
	var dateControl = endRow.querySelector('.select2-multiple');
	$(dateControl).select2({});

}

window.show_total_measurement_with_unit_price_cost = function(current){
	var total= 0;
	var total_all_cost = $("#"+$(current).closest(".measurement-field").find(".measurement-total-cost").attr("id"));
	var unit_price_cost =  $(current).val();
	unit_price_cost = change_type_number_value(unit_price_cost);
	if (unit_price_cost){
		var sum_all_cost = check_number_type(parseFloat(total*parseFloat(unit_price_cost)));
		total_all_cost.html(sum_all_cost.toLocaleString('en-US'));
	} else {
		total_all_cost.html("0");
	}
	format_jp_money_value_change();
}

function change_construction_place_name(current){
	var inputValue = $(current).val();
    $(current).closest(".measurement-field").find(".work-place-info").text(inputValue);
}
function change_product_name(current, id){
	var productValue = $(current).find(":selected").text();
	if (productValue !== "Choose ..."){
	$(current).closest(".measurement-field").find(".product-info").text(productValue);
	}else{
		$(current).closest(".measurement-field").find(".product-info").text("")
	}
}



function change_table_responsive(id){
	var id  = $("#table_work_report").find("#DataTables_Table_0_length").parent();
	var search_zone = $("#table_work_report").find("#DataTables_Table_0_filter").parent();
	$(id).removeClass("col-md-6");
	$(id).removeClass("col-sm-12");
	$(id).addClass("col-sm-6");
	$(search_zone).removeClass("col-md-6");
	$(search_zone).removeClass("col-sm-12");
	$(search_zone).addClass("col-sm-6");
}

function check_number_type(n){
	if (!isNaN(n)){
	  if (n % 1 === 0){
		return Number(parseInt(n));
	  }
	  else {
		return Number(parseFloat(n));
	  }
	}else{
	  return "";
	}
  
  }
  
function check_input_number_type(){
	var number_type = document.getElementsByClassName("number-type");
	for (var index = 0; index< number_type.length; index++ ){
		var new_num = check_number_type(parseFloat(change_type_number_value(number_type[index].value)));
		number_type[index].value = new_num;
	}
}


function hide_all_first_option() {
	var all_option = $(".select-validate");
	all_option.each(function (index, elem) {
		if ($(elem).find("option").eq(0).attr("value") === ''){
			$(elem).find("option").eq(0).hide();
		}
	});
}

function check_before_submit(){
	var selected_product = document.getElementsByClassName("selected-product");
	var count = 0;
	for (var i = 0; i < selected_product.length; i++) {
		if($(selected_product[i]).find("option[selected=selected]").length === 0 ) {
			count++;
		}
	}
	return count > 0 ? false : true;

}

function get_unit_price_product_number(product_id, unit_price_id, unit_price_cost_id){
	$.ajax({
		url: '/handle_unit_price_field', 
		method: 'GET', 
		data: { product_id: product_id , unit_price_id: unit_price_id, unit_price_cost_id: unit_price_cost_id},
		dataType: "script",
		success: function (response) {
		  // Handle the AJAX response
		  // Do something with the received data
		},
		error: function (error) {
		  // Handle the AJAX error
		  // Display an error message or take appropriate action
		}
	  });
}

window.handle_work_report_status_table = function(){
	bindItemDropdownEvents('#work_report_status_dropdown_menu_select_type', 
    '#work_report_status_text', '#work_report_status_id', '#add_new_work_report_status', 
    '.work-report-status-delete', '.work-report-status-edit', 
    '/confirm_to_remove_work_report_status', '/edit_work_report_status', '/new_work_report_status');

	$('.work-report-status-text').each(function() {
		var work_report_status_text_class = $(this);
		var work_report_status_name_class = work_report_status_text_class.data('item-name');
	
		if (work_report_status_name_class) {
			work_report_status_text_class.text(work_report_status_name_class);
		}
	});
	var work_report_status_text_class_show = $("#work_report_status_text");
	var work_report_status_name_class_show = work_report_status_text_class_show.data('item-name');

	if (work_report_status_name_class_show) {
		work_report_status_text_class_show.text(work_report_status_name_class_show);
	}

	$('body').delegate('.work-report-status-dropdown-menu-select-type li.item-container', 'click', function(e) {
		var labelText = $(this).find('span').text();
		var checkboxValue = $(this).data('item-id');
		$(this).closest('.custom-dropdown').find(".work-report-status-text").text(labelText);
		$(this).closest(".status-select").find(".work-report-status-id").val(checkboxValue);
		var work_report_id = $(this).closest("tr").data("item");
		$.ajax({
			url: '/update_work_report_status',
			method: 'GET',
			data: {
				option: checkboxValue,
				work_report_id: work_report_id 
			},
			dataType: "script",
			success: function (response) {
			},
			error: function (error) {H
			}
		  });
	});
}

window.handleMasterProductSelect = function(){
	$(".handle-select-product").select2({
		maximumSelectionLength: 10,
	});
}

function change_type_number_value(val){
	var new_val = val.replace(/,/g, '');
	return new_val;
}

window.handleWorkReportModal = function() {
	window.$('#modal_search_work_report').on('show.bs.modal', function (e) {
		format_datepicker('#work_report_start_datepicker', '.work_report_start_datepicker_value');
		format_datepicker('#work_report_end_datepicker', '.work_report_end_datepicker_value');
		$("#user_created_select_field_search").select2({
			dropdownParent: $('#modal_search_work_report'),
			tags: true,
			createTag: function (params) {
				return {
					id: params.term,
					text: params.term,
					isNew: true
				};
			},
			insertTag: function (data, tag) {
				data.push(tag);
			}
		});
		$("#user_updated_select_field_search").select2({
			dropdownParent: $('#modal_search_work_report'),
			tags: true,
			createTag: function (params) {
				return {
					id: params.term,
					text: params.term,
					isNew: true
				};
			},
			insertTag: function (data, tag) {
				data.push(tag);
			}
		});
		$("#customer_name_select_field_search").select2({
			dropdownParent: $('#modal_search_work_report'),
			tags: true,
			createTag: function (params) {
				return {
					id: params.term,
					text: params.term,
					isNew: true
				};
			},
			insertTag: function (data, tag) {
				data.push(tag);
			}
		});

		$("#department_name_select_field_search").select2({
			dropdownParent: $('#modal_search_work_report'),
			tags: true,
			createTag: function (params) {
				return {
					id: params.term,
					text: params.term,
					isNew: true
				};
			},
			insertTag: function (data, tag) {
				data.push(tag);
			}
		});
	});
};