import { move_top_item } from './commons';
import { move_up_item } from './commons';
import { move_down_item } from './commons';
import { move_bottom_item } from './commons';
import { call_sortable_list } from './commons';
import { selected_item } from './commons';
import { update_index_item } from './commons';

$(document).on('turbolinks:load', function() {

  const myModal = document.getElementById('modal-reorder-users');
  if (myModal) {
    myModal.addEventListener('shown.bs.modal', function () {
      call_sortable_list("#list_sort_user");
    });
  }

  $("body").delegate('#btnMoveTopSortUser', 'click', function () {
    move_top_item("#list_sort_user", '.item-user');
  });

  $("body").delegate('#btnMoveUpSortUser', 'click', function () {
    move_up_item("#list_sort_user");
  });

  $("body").delegate('#btnMoveDownSortUser', 'click', function () {
    move_down_item("#list_sort_user")
  });

  $("body").delegate('#btnMoveBottomSortUser', 'click', function () { 
    move_bottom_item("#list_sort_user", '.item-user');
  });

  $("body").delegate('.item-user', 'click', function () {
    selected_item('.item-user', this);
  });

  $("body").delegate('#btnUpdateIndexUsers', 'click', function () {
    update_index_item("#list_sort_user", "/update_index_users");
  });

  const tableHeaderRows = document.querySelectorAll(".hover_combined_rows thead tr");

  tableHeaderRows.forEach((row) => {
      row.addEventListener("mouseenter", function () {
        tableHeaderRows.forEach((otherRow) => otherRow.classList.add("hovered_customer_table"));
      });

      row.addEventListener("mouseleave", function () {
        tableHeaderRows.forEach((otherRow) => otherRow.classList.remove("hovered_customer_table"));
      });
  });

  var remove_hover_table_customer = $(".hover_combined_rows thead:nth-of-type(1) tr");

  const modal_confirm_remove = document.getElementById('modal-confirm-remove-user');
  if (modal_confirm_remove) {
    modal_confirm_remove.addEventListener('shown.bs.modal', function () {
      $("#modal-edit-user").addClass("modal-blur");
    });
    modal_confirm_remove.addEventListener('hidden.bs.modal', function () {
      $("#modal-edit-user").removeClass("modal-blur");
    });
  }

  const modal_confirm_change = document.getElementById('modal-confirm-change-user');
  if (modal_confirm_change) {
    modal_confirm_change.addEventListener('shown.bs.modal', function () {
      $("#modal-edit-user").addClass("modal-blur");
    });
    modal_confirm_change.addEventListener('hidden.bs.modal', function () {
      $("#modal-edit-user").removeClass("modal-blur");
    });
  }


  $("body").on("click", "#random_password_button", function () {
    var randomPassword = generateRandomPassword(8);
    $('#user_password').val(randomPassword);
  });

  $("#user_fax").on("input", function() {
    phone_fax_format(this);
  });

  bindItemDropdownEvents('#department_dropdown_menu_select_type',
  '#department_text', '#department_id', '#add_new_department',
  '.department-delete', '.department-edit',
  '/confirm_to_remove_department', '/edit_department', '/new_department');


  handleNewDepartmentModal();
  handleEditDepartmentModal();
});
window.handleNewDepartmentModal = function() {
	window.$('#modal-add-user').on('show.bs.modal', function (e) {
    show_modal_blur_other_modal("modal-add-department", "#modal-add-user");
    show_modal_blur_other_modal("modal-edit-department", "#modal-add-user");
    show_modal_blur_other_modal("modal-delete-department", "#modal-add-user");
  });
}
window.handleEditDepartmentModal = function() {
	window.$('#modal-edit-user').on('show.bs.modal', function (e) {
    show_modal_blur_other_modal("modal-add-department", "#modal-edit-user");
    show_modal_blur_other_modal("modal-edit-department", "#modal-edit-user");
    show_modal_blur_other_modal("modal-delete-department", "#modal-edit-user");
    var department_text = $('#department_text');
    var department_name = department_text.data('item-name');
  
    if (department_name) {
      department_text.text(department_name);
    }
  
  });
}

function generateRandomPassword(length) {
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';

  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}
window.show_modal_blur_other_modal = function(modal_current, modal_prev){
  const modal_confirm_change = document.getElementById(modal_current);
  if (modal_confirm_change) {
    modal_confirm_change.addEventListener('shown.bs.modal', function () {
      $(modal_prev).addClass("modal-blur");
    });
    modal_confirm_change.addEventListener('hidden.bs.modal', function () {
      $(modal_prev).removeClass("modal-blur");
    });
  }
}