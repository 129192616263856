import { move_top_item } from './commons';
import { move_up_item } from './commons';
import { move_down_item } from './commons';
import { move_bottom_item } from './commons';
import { call_sortable_list } from './commons';
import { selected_item } from './commons';
import { update_index_item } from './commons';

$(document).on('turbolinks:load', function() {

  const myModal = document.getElementById('modal-reorder-supplier-categories');
  if (myModal) {
    myModal.addEventListener('shown.bs.modal', function () {
      call_sortable_list("#list_sort_supplier_category");
    });
  }

  $("body").delegate('#btnMoveTopSortSupplierCategory', 'click', function () {
    move_top_item("#list_sort_supplier_category", '.item-supplier-category');
  });

  $("body").delegate('#btnMoveUpSortSupplierCategory', 'click', function () {
    move_up_item("#list_sort_supplier_category");
  });

  $("body").delegate('#btnMoveDownSortSupplierCategory', 'click', function () {
    move_down_item("#list_sort_supplier_category")
  });

  $("body").delegate('#btnMoveBottomSortSupplierCategory', 'click', function () { 
    move_bottom_item("#list_sort_supplier_category", '.item-supplier-category');
  });

  $("body").delegate('.item-supplier-category', 'click', function () {
    selected_item('.item-supplier-category', this);
  });

  $("body").delegate('#btnUpdateIndexSupplierCategories', 'click', function () {
    update_index_item("#list_sort_supplier_category", "/update_index_supplier_categories");
  });

  const modal_confirm_remove_supplier_category = document.getElementById('modal-confirm-remove-supplier-category');
  if (modal_confirm_remove_supplier_category) {
    modal_confirm_remove_supplier_category.addEventListener('shown.bs.modal', function () {
      $("#modal-edit-supplier-category").addClass("modal-blur");
    });
    modal_confirm_remove_supplier_category.addEventListener('hidden.bs.modal', function () {
      $("#modal-edit-supplier-category").removeClass("modal-blur");
    });
  }

  const modal_confirm_change_supplier_category = document.getElementById('modal-confirm-change-supplier-category');
  if (modal_confirm_change_supplier_category) {
    modal_confirm_change_supplier_category.addEventListener('shown.bs.modal', function () {
      $("#modal-edit-supplier-category").addClass("modal-blur");
    });
    modal_confirm_change_supplier_category.addEventListener('hidden.bs.modal', function () {
      $("#modal-edit-supplier-category").removeClass("modal-blur");
    });
  }

});
