export class PropertiesToolbar{  
    eventBus = null  
    constructor(options, eventBus){
        this.eventBus = eventBus;
        this.buttons = [{
          element: options.increaseSize,
          eventName: "increaseSizeText"
        } ,   
        {
            element: options.decreaseSize,
            eventName: "decreaseSizeText"
          } ,
          {
            element: options.boldtext,
            eventName: "boldtext"
          } ,
          {
            element: options.italictext,
            eventName: "italictext"
          } ,    
      ];


      this.inputs = [{
        element: options.fontTextChange,
        eventName: "fontTextChange"
        }    ,    
        ];
        this.inputs2 = [{
            element: options.textColor,
            eventName: "textColor",
            
            }    ,    
            
          {
            element: options.markItemWidth,
            eventName: "markItemWidth"
          } ,
          {
            element: options.markItemHeight,
            eventName: "markItemHeight"
          } ,
          {
            element: options.markItemOpacity,
            eventName: "markItemOpacity"
          } 
        ];
        this.commons = [{
            element: options.commonDelete,
            eventName: "commonDelete"
            } ,     
            
          {
            element: options.c_stamp_personal,
            eventName: "selectStamp"
          } ,  
          {
            element: options.c_stamp_manager,
            eventName: "selectStamp"
          } ,  
          {
            element: options.c_stamp_company,
            eventName: "selectStamp"
          } ,  
          {
            element: options.c_stamp_personal,
            eventName: "selectStamp"
          } ,
          {
            element: options.canvas_signal,
            eventName: "selectStamp"
          } ,
          {
            element: options.common_select_img,
            eventName: "common_select_img"
          },
          {
            element: options.toolButton,
            eventName: "toolButton"
          },
          
        ];
        this.#bindListeners(options);
        bindCommons(this.commons,this.eventBus);
        
    }   
   
    
    #bindListeners(options) {   
         
        for (const {
            element,
            eventName,
            eventDetails
          } of this.buttons) {
            element.addEventListener("click", (event) => {
              if(event.target.parentNode.classList.contains('anotation-popup'))
              {
                setToggle(event.target);
              }
              
              if (eventName !== null) {
                const details = {
                    source: this
                };
      
                if (eventDetails) {
                  for (const property in eventDetails) {
                    details[property] = eventDetails[property];
                  }
                }
      
                this.eventBus.dispatch(eventName, details);
              }
            });
        } 
          
        for (const {
            element,
            eventName,
            eventDetails
          } of this.inputs) {
            element.addEventListener("change", (event) => {           
              
              if (eventName !== null) {
                const details = {                  
                  element: event.target,
                  value : event.target.value
                };
      
                if (eventDetails) {
                  for (const property in eventDetails) {
                    details[property] = eventDetails[property];
                  }
                }
      
                this.eventBus.dispatch(eventName, details);
              }
            });
        }     

        for (const {
            element,
            eventName,
            eventDetails
          } of this.inputs2) {
            element.addEventListener("input", (event) => {           
              
              if (eventName !== null) {
                const details = {                  
                  element: event.target,
                  value : event.target.value
                };
      
                if (eventDetails) {
                  for (const property in eventDetails) {
                    details[property] = eventDetails[property];
                  }
                }
      
                this.eventBus.dispatch(eventName, details);
              }
            });
        }  
          
    }
}
function bindCommons(commons,eventBus){
    for (const {
        element,
        eventName,
        eventDetails
      } of commons) {       
        $("body").delegate(element, 'click', function(ev) {
            eventBus.dispatch(eventName, {
                element: ev.target        
            });    
        })
       
    }  
}
