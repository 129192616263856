import { move_top_item } from './commons';
import { move_up_item } from './commons';
import { move_down_item } from './commons';
import { move_bottom_item } from './commons';
import { call_sortable_list } from './commons';
import { selected_item } from './commons';
import { update_index_item } from './commons';

$(document).on('turbolinks:load', function() {

    const myModal = document.getElementById('modal-reorder-customer-categories');
    if (myModal) {
      myModal.addEventListener('shown.bs.modal', function () {
        call_sortable_list("#list_sort_customer_category");
      });
    }
    
    $("body").delegate('#btnMoveTopSortCustomerCategory', 'click', function () {
      move_top_item("#list_sort_customer_category", '.item-customer-category');
    });
    
    $("body").delegate('#btnMoveUpSortCustomerCategory', 'click', function () {
      move_up_item("#list_sort_customer_category");
    });
    
    $("body").delegate('#btnMoveDownSortCustomerCategory', 'click', function () {
      move_down_item("#list_sort_customer_category")
    });
    
    $("body").delegate('#btnMoveBottomSortCustomerCategory', 'click', function () { 
      move_bottom_item("#list_sort_customer_category", '.item-customer-category');
    });
    
    $("body").delegate('.item-customer-category', 'click', function () {
      selected_item('.item-customer-category', this);
    });
    
    $("body").delegate('#btnUpdateIndexCustomerCategories', 'click', function () {
      update_index_item("#list_sort_customer_category", "/update_index_customer_categories");
    });
    
    const modal_confirm_remove_customer_category = document.getElementById('modal-confirm-remove-customer-category');
    if (modal_confirm_remove_customer_category) {
      modal_confirm_remove_customer_category.addEventListener('shown.bs.modal', function () {
        $("#modal-edit-customer-category").addClass("modal-blur");
      });
      modal_confirm_remove_customer_category.addEventListener('hidden.bs.modal', function () {
        $("#modal-edit-customer-category").removeClass("modal-blur");
      });
    }
    
    const modal_confirm_change_customer_category = document.getElementById('modal-confirm-change-customer-category');
    if (modal_confirm_change_customer_category) {
      modal_confirm_change_customer_category.addEventListener('shown.bs.modal', function () {
        $("#modal-edit-customer-category").addClass("modal-blur");
      });
      modal_confirm_change_customer_category.addEventListener('hidden.bs.modal', function () {
        $("#modal-edit-customer-category").removeClass("modal-blur");
      });
    }
    

});
