import { relativeTimeRounding } from 'moment';
import { EventBus } from '../event/EventBus';
import { EditToolbar } from './edittoolbar';
import {PDFViewer} from './PDFViewer';
import { PropertiesToolbar } from './properties_toolbar';
import { SettingToolbar } from './settingtoolbar';
import { ToolBar } from './toolbar';
import {toolBarHelper} from '../edit/toolbar_helper';
const mode = {
  VIEW: 0,
  EDIT:1
}
export const PDFViewerApplication = {
  appConfig: null,
  viewComponents: null,    
  pdfDocument: null,
  pdfLoadingTask: null,
  pdfViewer: null,
  /** @type {PDFThumbnailViewer} */
  pdfThumbnailViewer: null,
  /** @type {PDFRenderingQueue} */
  pdfRenderingQueue: null,
  url: "",
  toolbar: null,
  edittoolbar: null,
  current_mode: mode.VIEW,
  _boundEvents: null,
  _wheelUnusedTicks: 0,
  async initialize(appConfig,viewComponents) {
    this.viewComponents = viewComponents;
    this.appConfig = appConfig;     
    await this._initializeViewerComponents();
    this.bindEvents();

  },  
  set_mode(mode){
    this.current_mode = mode
  },
  async open(url){
    var versionUpdate = (new Date()).getTime();  
    this.url = url;
    var urlAbsolute =  window.location.origin + url + "?v=" + versionUpdate; 
    await this.pdfViewer.openURL(urlAbsolute);    
  },
  bindEvents(){
    const {
      eventBus,
      _boundEvents,
      viewComponents
    } = this;
    viewComponents.viewContainer.addEventListener('scroll',webViewerScroll);
    viewComponents.viewContainer.addEventListener('mousedown',webViewerMouseDown);
    viewComponents.viewContainer.addEventListener('mouseup',webViewerMouseUp);
    viewComponents.viewContainer.addEventListener('keydown', function(options) {
      if (options.repeat) {
        return;
      }
      var key = options.which || options.keyCode; // key detection
      if (key === 46) { // handle Down key
        keyboardDeleteElement();               
      } 
  })
    viewComponents.viewContainer.addEventListener('scroll',webViewerScroll);
    window.addEventListener("wheel", webViewerWheel, { passive: false });

    eventBus._on("formLayerClick", formLayerClick);
    eventBus._on("firstpage", webViewerFirstPage);
    eventBus._on("endpage", webViewerEndPage);
    eventBus._on("nextpage", webViewerNextPage);
    eventBus._on("previouspage", webViewerPreviousPage);
    eventBus._on("zoomin", webViewerZoomIn);
    eventBus._on("zoomout", webViewerZoomOut);
    eventBus._on("pagechanging", webViewerPageChanging);
    eventBus._on("rotateleft", webViewerRotateLeft);
    eventBus._on("rotateright", webViewerRotateRight);
    eventBus._on("changescale", webViewerChangeScale);

    
  },
  async _initializeViewerComponents() {
    const eventBus = new EventBus();
    this.eventBus = eventBus;
    this.toolbar = new ToolBar(this.viewComponents.toolbar,this.eventBus);
    this.pdfViewer = new PDFViewer();
    this.pdfViewer.initial(this.viewComponents,eventBus);
    if(this.current_mode == mode.EDIT){
      this.edittoolbar = new EditToolbar(this.viewComponents.edittoolbar,this.eventBus);
      this.settingToolbar = new SettingToolbar(null,this.eventBus);
      this.propertiestoolbar = new PropertiesToolbar(this.viewComponents.propertiesToolbar,this.eventBus);
      pdfjsLib.GlobalWorkerOptions.workerSrc = window.origin + "/libs/pdfjs/build/pdf.worker.min.js";
      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.cornerColor = "white";
      fabric.Object.prototype.__corner = 1;
      fabric.Object.prototype.cornerStrokeColor = '#0282e5';
      // fabric.Object.prototype.cornerStyle = 'circle';
      fabric.Object.prototype.borderDashArray = [8,4];
      fabric.Object.prototype.borderColor = "#0282e5";
      fabric.Object.prototype.borderScaleFactor = 1;
      fabric.Object.prototype.cornerSize = 6;
    }
  },
  async save(){
    await PDFViewerApplication.pdfViewer.save(); 
    
  },
  async saveArrageFile(){
    await PDFViewerApplication.pdfViewer.saveArrageFile(); 
    
  },
  
  async reload(){    
    await PDFViewerApplication.pdfViewer.reloadData(PDFViewerApplication.url);
    
  },
  get_form_status(){
    return PDFViewerApplication.pdfViewer.form_status;
  },
  accumulateWheelTicks(ticks){
    if (this._wheelUnusedTicks > 0 && ticks < 0 || this._wheelUnusedTicks < 0 && ticks > 0) {
      this._wheelUnusedTicks = 0;
    }
  
    this._wheelUnusedTicks += ticks;
    const wholeTicks = Math.sign(this._wheelUnusedTicks) * Math.floor(Math.abs(this._wheelUnusedTicks));
    this._wheelUnusedTicks -= wholeTicks;
    return wholeTicks;
  }
}

// edit
function webViewerSelectText(){
  PDFViewerApplication.pdfViewer.select_text(); 
}
function webViewerSelectAnotation(){
  PDFViewerApplication.pdfViewer.select_annotation(); 
}
// start action annotation
function add_text(){
  PDFViewerApplication.pdfViewer.add_text(); 
}
function highlight(){
  PDFViewerApplication.pdfViewer.highlight(); 
}
function strikeout(){
  PDFViewerApplication.pdfViewer.strikeout(); 
}
function underline(){
  PDFViewerApplication.pdfViewer.underline(); 
}
function draw_line(){
  PDFViewerApplication.pdfViewer.draw_line(); 
}
function draw_arrow(){
  PDFViewerApplication.pdfViewer.draw_arrow(); 
}
function draw_ellipse(){
  PDFViewerApplication.pdfViewer.draw_ellipse(); 
}
function draw_rectangle(){
  PDFViewerApplication.pdfViewer.draw_rectangle(); 
}
function mark_x(data){
  PDFViewerApplication.pdfViewer.mark_x(data); 
}
function mark_v(data){
  PDFViewerApplication.pdfViewer.mark_v(data); 
}
function mark_o(data){
  PDFViewerApplication.pdfViewer.mark_o(data); 
}
function form_textbox(){
  PDFViewerApplication.pdfViewer.form_textbox(); 
}
function form_textarea(){
  PDFViewerApplication.pdfViewer.form_textarea(); 
}
function form_radio(){
  PDFViewerApplication.pdfViewer.form_radio(); 
}
function form_checkbox(){
  PDFViewerApplication.pdfViewer.form_checkbox(); 
}
function form_dropdown(){
  PDFViewerApplication.pdfViewer.form_dropdown(); 
}
function add_image(){
  PDFViewerApplication.pdfViewer.add_image(); 
}
function brush(){
  PDFViewerApplication.pdfViewer.pen();
}
function highlightbrush(){
  PDFViewerApplication.pdfViewer.free_draw(); 
}
function erase(){
  PDFViewerApplication.pdfViewer.erase(); 
}
function link(){
  PDFViewerApplication.pdfViewer.link(); 
}
function customize_tool(){
  PDFViewerApplication.pdfViewer.customize_tool(); 
}
function close_tool(){
  PDFViewerApplication.pdfViewer.close_tool(); 
}

function undo(){
  PDFViewerApplication.pdfViewer.undo(); 
}
function redo(){
  PDFViewerApplication.pdfViewer.redo(); 
}
function download(){
  PDFViewerApplication.pdfViewer.download(); 
}

// end action annotation

// start properties
function increaseSizeText(){
  PDFViewerApplication.pdfViewer.increaseSizeText(); 
}
function decreaseSizeText(){
  PDFViewerApplication.pdfViewer.decreaseSizeText(); 
}
function boldtext(){
  PDFViewerApplication.pdfViewer.boldtext(); 
}
function italictext(){
  PDFViewerApplication.pdfViewer.italictext(); 
}

function fontTextChange(data){
  PDFViewerApplication.pdfViewer.fontTextChange(data); 
}

function textColor(data){
  PDFViewerApplication.pdfViewer.textColor(data); 
}
function commonDeleteObject(data){
  PDFViewerApplication.pdfViewer.commonDeleteObject(data); 
}
function selectStamp(data){
  PDFViewerApplication.pdfViewer.selectStamp(data); 
}

function markItemWidth(data){
  PDFViewerApplication.pdfViewer.markItemWidth(data); 
}

function markItemHeight(data){
  PDFViewerApplication.pdfViewer.markItemHeight(data); 
}

function markItemOpacity(data){
  PDFViewerApplication.pdfViewer.markItemOpacity(data); 
}

function updateLineWidth(data){
  PDFViewerApplication.pdfViewer.updateLineWidth(data); 
}

function updateLineOpacity(data){
  PDFViewerApplication.pdfViewer.updateLineOpacity(data); 
}

function updateLineColor(data){
  PDFViewerApplication.pdfViewer.updateLineColor(data); 
}
function close_dialog(data){
  PDFViewerApplication.pdfViewer.close_dialog(data); 
}
function updateBackgroundColor(data){
  PDFViewerApplication.pdfViewer.updateBackgroundColor(data); 
}
function savelink(data){
  PDFViewerApplication.pdfViewer.savelink(data); 
}

// end properties
function mouseDown(data){
  PDFViewerApplication.pdfViewer.mouseDownEvent(data);
}

function object_added(data){
  PDFViewerApplication.pdfViewer.object_added(data);
}
function object_modified(data){
  PDFViewerApplication.pdfViewer.object_modified(data);
}
function path_created(data){
  PDFViewerApplication.pdfViewer.path_created(data);
}
function object_moving(data){
  PDFViewerApplication.pdfViewer.object_moving(data);
}
function before_render(data){
  PDFViewerApplication.pdfViewer.before_render(data);
}
function mouse_move(data){
  PDFViewerApplication.pdfViewer.mouse_move(data);
}
function mouse_down_before(data){
  PDFViewerApplication.pdfViewer.mouse_down_before(data);
}

function mouse_up(data){
  PDFViewerApplication.pdfViewer.mouse_up(data);
}
function object_selected(data){
  PDFViewerApplication.pdfViewer.object_selected(data);
}
function selection_cleared(data){
  PDFViewerApplication.pdfViewer.object_selected(data);
}

function common_select_img(data){
  PDFViewerApplication.pdfViewer.common_select_img(data);
}

function toolButton(data){
  PDFViewerApplication.pdfViewer.toolButton(data);
}
function brushChangeStrokeWidth(data){
  PDFViewerApplication.pdfViewer.brushChangeStrokeWidth(data);
}
function brushChangeStrokeColor(data){
  PDFViewerApplication.pdfViewer.brushChangeStrokeColor(data);
}
function changeformcontrolwidth(data){
  PDFViewerApplication.pdfViewer.changeformcontrolwidth(data);
}

function changeformcontrolborderwidth(data){
  PDFViewerApplication.pdfViewer.changeformcontrolborderwidth(data);
}

function changeformcontrolHeight(data){
  PDFViewerApplication.pdfViewer.changeformcontrolHeight(data);
}

function changeformcontrolColor(data){
  PDFViewerApplication.pdfViewer.changeformcontrolColor(data);
}
function changeformcontrolBorderColor(data){
  PDFViewerApplication.pdfViewer.changeformcontrolBorderColor(data);
}

function selectElement(data){
  PDFViewerApplication.pdfViewer.selectElement(data);
}
function showtoolElement(data){
  PDFViewerApplication.pdfViewer.showtoolElement(data);
}

function updateElementColor(data){
  PDFViewerApplication.pdfViewer.updateElementColor(data);
}
function updateBorderColor(data){
  PDFViewerApplication.pdfViewer.updateBorderColor(data);
}
function updateBoderWidth(data){
  PDFViewerApplication.pdfViewer.updateBoderWidth(data);
}
function updateCenterColorElem(data){
  PDFViewerApplication.pdfViewer.updateCenterColorElem(data);
}

function changeFieldName(data){
  PDFViewerApplication.pdfViewer.changeFieldName(data);
}
function updateOptionSelect(data){
  PDFViewerApplication.pdfViewer.updateOptionSelect(data);
}


function updateBackgroundColorDiv(data){
  PDFViewerApplication.pdfViewer.updateBackgroundColorDiv(data);
}
function delete_element(){
  PDFViewerApplication.pdfViewer.delete_element();
}

function delete_annotation(data){
  PDFViewerApplication.pdfViewer.delete_annotation(data);
}
function itemannotation_click(data){
  PDFViewerApplication.pdfViewer.itemannotation_click(data);
}


//
function webViewerChangeScale(scale){
  PDFViewerApplication.pdfViewer.changescale(scale.scale_value);
 
}
function webViewerRotateLeft(){
  PDFViewerApplication.pdfViewer.rotateleft();
 
}
function webViewerRotateRight(){
  PDFViewerApplication.pdfViewer.rotateright(); 
}

function webViewerPageChanging({pageNumber}) {  
  PDFViewerApplication.viewComponents.viewContainer.querySelector(`#page-${pageNumber}`) .scrollIntoViewIfNeeded();   
  PDFViewerApplication.pdfViewer.updatetoolbar(pageNumber);
  toolBarHelper.hideToolProperties();
  PDFViewerApplication.pdfViewer.page_change(pageNumber);
  return true;
}
function webViewerScroll(){
  PDFViewerApplication.pdfViewer.scroll();
}
function webViewerMouseDown(event){
  PDFViewerApplication.pdfViewer.mousedown(event);
}
function webViewerMouseUp(event){
  PDFViewerApplication.pdfViewer.mouseup(event);
}
function formLayerClick(ev){
  PDFViewerApplication.pdfViewer.formLayerClick(ev);
}

function keyboardDeleteElement(ev){
  PDFViewerApplication.pdfViewer.keyboardDeleteElement(ev);
}
function webViewerWheel(evt) {
  if (evt.ctrlKey){
    evt.preventDefault();
    const deltaMode = evt.deltaMode;
    const delta = (0, normalizeWheelEventDirection)(evt);
    // const previousScale = pdfViewer.currentScale;
    let ticks = 0;
  
    if (deltaMode === WheelEvent.DOM_DELTA_LINE || deltaMode === WheelEvent.DOM_DELTA_PAGE) {
      if (Math.abs(delta) >= 1) {
        ticks = Math.sign(delta);
      } else {
        ticks = PDFViewerApplication.accumulateWheelTicks(delta);
      }
    } else {
      const PIXELS_PER_LINE_SCALE = 30;
      ticks = PDFViewerApplication.accumulateWheelTicks(delta / PIXELS_PER_LINE_SCALE);
    }
  
    if (ticks < 0) {
      PDFViewerApplication.pdfViewer.zoomOut(-ticks);
    } else if (ticks > 0) {
      PDFViewerApplication.pdfViewer.zoomIn(ticks);
    }
  }
 
}

function normalizeWheelEventDirection(evt) {
  let delta = Math.hypot(evt.deltaX, evt.deltaY);
  const angle = Math.atan2(evt.deltaY, evt.deltaX);

  if (-0.25 * Math.PI < angle && angle < 0.75 * Math.PI) {
    delta = -delta;
  }

  return delta;
}
function webViewerFirstPage() {
  PDFViewerApplication.pdfViewer.firstpage();
}
function webViewerEndPage() {
  PDFViewerApplication.pdfViewer.endpage();
}
function webViewerNextPage() {
  PDFViewerApplication.pdfViewer.nextPage();
}

function webViewerPreviousPage() {
  PDFViewerApplication.pdfViewer.previousPage();
}

function webViewerZoomIn() {
  PDFViewerApplication.pdfViewer.zoomIn();
}

function webViewerZoomOut() {
  PDFViewerApplication.pdfViewer.zoomOut();
}