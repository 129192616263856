$(document).on('turbolinks:load', function() {
  // window.zipCodeField; // Declare a global variable

  window.zipCodeField = $('#zip_code_field');
    $("#postcode_field").on("input", function() {
        var inputValue = $(this).val();
        let sanitizedValue = inputValue.replace(/[^0-9-]/g, '').replace(/-+/g, '-');
        let parts = sanitizedValue.split('-');
        
        if (parts.length === 2) {
          parts[0] = parts[0].substring(0, 3);
          parts[1] = parts[1].substring(0, 4);
          sanitizedValue = parts.join('-');
        } else {
            sanitizedValue = sanitizedValue.substring(0, 3);
        }     
        $(this).val(sanitizedValue);
      });

      $("body").delegate('#post_code_search', 'click', function () {
        var inputValue = $("#postcode_field").val();
        $.ajax({
          url: "/check_postcode?format=js",
          method: "GET",
          data: { postcode: inputValue },
          dataType: "script"
      });
  });
});
