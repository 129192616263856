import {PDFContent} from './PDFContent';
import {PDFThumnail} from './PDFThumnail'
import {PDF} from './PDF';
import {_ui_utils} from './ui_ultil';
import { PDFDocument ,PDFDict,PDFArray,PDFString,PDFName,StandardFonts,rgb,grayscale,PDFFont,setFillingRgbColor,PageSizes} from 'pdf-lib';
import { toolBarHelper } from '../edit/toolbar_helper';
import { ANOTATION,FORM_STATUS } from '../edit/annotation';
import {UndoRedoHandler} from '../edit/undo_redo';
const DEFAULT_SCALE_DELTA=1.1;
const MAX_SCALE = 10;
const MIN_SCALE = 0.1;
const scale_mode={
    Normal:0,
    FitHeight: 1,
    FitWidth:2,
}
const PDF_TO_CSS_UNITS =  96.0 / 72.0;
export class PDFBaseViewer{    
    file   
    pdfContent   
    pdfThumbnail
    viewComponents  
    scale   
    scale_mode = scale_mode.Normal
    pageRendering
    pageNumPending   
    eventBus
    _currentPageNumber = 0
    _currentRotate = 0
    _currentScale
    pagesCount    
    pdf
    pdfDoc
    fabricpageAnnotations = []  
    undoRedoHandler
    form_status = FORM_STATUS.VIEWING
    initial(viewComponents,evBus){
        this._currentRotate = 0;
        this._currentScale = 1   
        this.viewComponents = viewComponents ;
        this.eventBus = evBus;
        this.pdfContent = new PDFContent(this);
        this.pdfThumbnail = new PDFThumnail(this.viewComponents.thumnailContainer,evBus);
        this.file = new PDF();  
    }

    async openURL(url){   
        this.file.url = url;          
        var result = await setDataByURL(this);        
        if(!result) return;
        this.openFileWithPDFBytes();      
        this.file.loading = false;  
        this.setDefaultData();
        // hideFormLoading();    
        this.form_status = FORM_STATUS.VIEWING;
    }   
    async reload(url){     
        this.file.url = url;
        var result = await setDataByURL(this);        
        if(!result) return;
        reloadFile(this);    
        this.setDefaultData();
        // hideFormLoading();    
        this.file.loading = false;   
        this.form_status = FORM_STATUS.VIEWING;
        
    }  
    setDefaultData(){       
        if(this.form_status == FORM_STATUS.EDITTING){
            this.fabricpageAnnotations = [];      
            this.undoRedoHandler = new UndoRedoHandler();        
            toolBarHelper.clearListAnotations();
            this.updateStatusUndoRedo();
            this.set_anotation(ANOTATION.NONE);
        }
        
    }    
    updateStatusUndoRedo(){
        toolBarHelper.updateStatusUndoRedo(this);
        toolBarHelper.hideToolProperties();  
      }
    // open file from bytes
   openFileWithPDFBytes(){       
      load(this);   
      if(this.form_status == FORM_STATUS.EDITTING){
         toolBarHelper.clearListAnotations();
      }
    //   hideFormLoading();    
    }      
    setFormStatus(form_status){
        this.form_status = form_status;
    }
    get currentRotate() {
        return this._currentRotate;
    }
    set currentRotate(val) {
        if (!Number.isInteger(val)) {
          throw new Error("Invalid Rotate.");
        }
        if (this._currentRotate === val) {
            return true;
        }
        
        this._currentRotate = val;
        this.setDefaultData();
        createContent(this);
        
        this.pdfContent.renderPage(this.currentPageNumber);    
        this.viewComponents.viewContainer.querySelector(`#page-${this.currentPageNumber}`) .scrollIntoViewIfNeeded();     
        return true;
        
    }
 
    get currentScaleValue() {
        return this._currentScale;
    }
    set currentScaleValue(val) {       
        if (this._currentScale === val) {          
            return true;
        } 
        if(this.form_status == FORM_STATUS.EDITTING || checkStatus(this.viewComponents.viewContainer.querySelectorAll('.formLayer'))){
            let text = I18n.t('pdf.edit_form.middle.pages.content.leave_page')
            if (confirm(text) == true) {               
               this.form_status = FORM_STATUS.VIEWING;
              } else {
                return true;
            }
        }
        this.setDefaultData();
        this._currentScale = val;        
        this.updatescale(val);
        this.pdfContent.updateScale(this);             
        toolBarHelper.hideToolProperties();  
        this.viewComponents.viewContainer.querySelector(`#page-${this.currentPageNumber}`) .scrollIntoViewIfNeeded(); 
        if(this.scale_mode == scale_mode.Normal )
        {
            if($(`#${this.viewComponents.toolbar.select_scale.id} option[value="${val}"]`).length == 0){
                var opt = document.createElement('option');
                opt.value = val;
                opt.innerHTML = `${parseInt(val*100)}%`;
                opt.setAttribute("hidden", true);
                this.viewComponents.toolbar.select_scale.appendChild(opt);
            }
            this.viewComponents.toolbar.select_scale.value = val; 
        }
        document.documentElement.style.setProperty("--scale-factor", val * PDF_TO_CSS_UNITS);        
        return true;
    }

    get currentPageNumber() {
        return this._currentPageNumber;
    }
    set currentPageNumber(val) {
        if (!Number.isInteger(val)) {
          throw new Error("Invalid page number.");
        }
        if (!this._setCurrentPageNumber(val, true)) {
          console.error(`currentPageNumber: "${val}" is not a valid page.`);
        }
    }

    _setCurrentPageNumber(val) {
        if (this._currentPageNumber === val) {          
          return true;
        }
    
        if (!(0 < val && val <= this.pagesCount)) {
          return false;
        }
    
        // const previous = this._currentPageNumber;
        this._currentPageNumber = val;
        this.eventBus.dispatch("pagechanging", {
          source: this,
          pageNumber: val,        
        });                
        return true;
    }
    renderPage(number){        
        this.pdfContent.renderPage(number);
    } 
    scroll(){        
        var pages = this.viewComponents.viewContainer.querySelectorAll(".page");
        var page_in_pointer,i,number_of_page;
        for(i = 0;i< pages.length;i++){
            if(IsPageDisplay(pages[i],this.viewComponents.viewContainer))
            {
                page_in_pointer = pages[i];
                break;
            }
        }
       
        if(page_in_pointer){
            var id = page_in_pointer.id.split("-");
            var number_of_page = parseInt(id[1]);
            this.renderPage(number_of_page-1);
            this._currentPageNumber = number_of_page; 
            this.updatetoolbar(number_of_page);
        }
        this.renderPageInView(pages,this.viewComponents.viewContainer);
    }

    renderPageInView(pages,holder){      
        for(let i = 0;i< pages.length;i++){
          if(isElementVisible(pages[i],holder))
          {
            this.renderPage(i);
          }
        }  
    }

    queueRenderPage(num) {
        if(num<=0 || num > _TOTAL_PAGES) return;
        
        if (this.pageRendering) {
            this.pageNumPending = num;
        } 
        else 
        {
            this.renderPage(num);
        }
    }
    firstpage(){
        const currentPageNumber = this._currentPageNumber;
        if (currentPageNumber == 1) {
            return false;
        }
        this.currentPageNumber =  1;
        return true;   
    }

    endpage(){
        const currentPageNumber = this._currentPageNumber;
        if (currentPageNumber == this.pagesCount) {
            return false;
        }
        this.currentPageNumber =  this.pagesCount;
        return true;   
    }

    previousPage(){
        const currentPageNumber = this._currentPageNumber;
        if (currentPageNumber <= 1) {
            return false;
        }
        this.currentPageNumber = currentPageNumber - 1;
        return true;   
    }

    nextPage(){
        const currentPageNumber = this._currentPageNumber;
        if (currentPageNumber >= this.pagesCount) {
            return false;
        }
        this.currentPageNumber = currentPageNumber + 1;
        return true;   
    }
    rotateleft(){
        if(this.form_status == FORM_STATUS.EDITTING || checkStatus(this.viewComponents.viewContainer.querySelectorAll('.formLayer'))){
            let text = I18n.t('pdf.edit_form.middle.pages.content.leave_page');
            if (confirm(text) == true) {
               this.form_status = FORM_STATUS.VIEWING;
              } else {
                return true;
            }
        }
        this.file.pages[this.currentPageNumber-1].viewRotate = ((this.file.pages[this.currentPageNumber-1].viewRotate - 90)%360);
        this.pdfContent.renderViewPage(this);  
        this.pdfThumbnail.rotate(this.file.pages[this.currentPageNumber-1]);  
        return true;
    }
    rotateright(){
        if(this.form_status == FORM_STATUS.EDITTING || checkStatus(this.viewComponents.viewContainer.querySelectorAll('.formLayer'))){
            let text = I18n.t('pdf.edit_form.middle.pages.content.leave_page');
            if (confirm(text) == true) {
               this.form_status = FORM_STATUS.VIEWING;
              } else {
                return true;
            }
        }
       
        this.file.pages[this.currentPageNumber-1].viewRotate = ((this.file.pages[this.currentPageNumber-1].viewRotate + 90)%360);
        this.pdfContent.renderViewPage(this);  
        this.pdfThumbnail.rotate(this.file.pages[this.currentPageNumber-1]); 
        return true;
    }
    changescale(scaleValue){
        var scale = scaleValue;
        switch (scaleValue){
            case 'page-fit':
                scale = getScalePageFit(this);
                this.scale_mode = scale_mode.FitHeight
                break;
            case 'page-width':                
                scale = getScalePageWidth(this);
                this.scale_mode = scale_mode.FitWidth
                break;
            default:
                this.scale_mode = scale_mode.Normal
                break;

        }
        this.currentScaleValue = parseFloat(scale);
    }

    zoomIn(steps=1){
        let newScale = this._currentScale;

        do {
          newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
          newScale = Math.ceil(newScale * 10) / 10;
          newScale = Math.min(MAX_SCALE, newScale);
        } while (--steps > 0 && newScale < MAX_SCALE);
        this.scale_mode = scale_mode.Normal
        this.currentScaleValue = newScale;
    }
    zoomOut(steps=-1){
        let newScale = this._currentScale;

        do {
          newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2);
          newScale = Math.floor(newScale * 10) / 10;
          newScale = Math.max(MIN_SCALE, newScale);
        } while (--steps > 0 && newScale > MIN_SCALE);
        this.scale_mode = scale_mode.Normal
        this.currentScaleValue = newScale;
    }

    updatetoolbar(pageNumber){        
        this.pdfThumbnail.setPageNumber(pageNumber);
        this.viewComponents.toolbar.current_page.value = pageNumber;
           
        if(pageNumber==1){
            this.viewComponents.toolbar.previous.setAttribute("disabled", true);
            this.viewComponents.toolbar.firstpage.setAttribute("disabled", true);
        }
        else{
            this.viewComponents.toolbar.previous.removeAttribute("disabled");
            this.viewComponents.toolbar.firstpage.removeAttribute("disabled");

        }
        if(pageNumber==this.pagesCount){
            this.viewComponents.toolbar.next.setAttribute("disabled", true);
            this.viewComponents.toolbar.endpage.setAttribute("disabled", true);
        }
        else{
            this.viewComponents.toolbar.next.removeAttribute("disabled");
            this.viewComponents.toolbar.endpage.removeAttribute("disabled");

        }
    }
    updatescale(scale){        
        if(scale == MIN_SCALE){
            this.viewComponents.toolbar.zoomOut.setAttribute("disabled", true);
        }
        else{
            this.viewComponents.toolbar.zoomOut.removeAttribute("disabled");

        }
        if(scale == MAX_SCALE){
            this.viewComponents.toolbar.zoomIn.setAttribute("disabled", true);
        }
        else{
            this.viewComponents.toolbar.zoomIn.removeAttribute("disabled");

        }
    }
}

async function setDataByURL(baseView){
    try
    {    
        var bytes = await getPdfBytes(baseView.file.url);
        baseView.file.bytes = bytes;
        baseView.pdfDoc = await PDFDocument.load(bytes);  
        return true;
    }
    catch(error)
    {
        // hideFormLoading();
        console.log(error);
        alert(error.message);
        return false;
    }
}


async function createComponents(baseView){
    baseView.file.pages = await getPages(baseView.pdf);
    defineRotateCustom(baseView.file.pages);
    baseView._currentScale = getScalePageFit(baseView);
    baseView.file.numPages = baseView.pdf.numPages;
    baseView.pagesCount = baseView.pdf.numPages
    createContent(baseView);
    baseView.pdfContent.renderPage(0);     
    baseView.pdfThumbnail.create(baseView.file.pages); 
    baseView.currentPageNumber = 1;
    baseView.viewComponents.toolbar.total_page.value = baseView.pdf.numPages;
    baseView.viewComponents.toolbar.current_page.setAttribute('max',baseView.pdf.numPages);   
     
}
async function reloadCreateComponents(baseView){
    baseView.file.pages = await getPages(baseView.pdf);
    defineRotateCustom(baseView.file.pages);
    baseView.file.numPages = baseView.pdf.numPages;
    baseView.pagesCount = baseView.pdf.numPages
    createContent(baseView);
    baseView.pdfContent.renderPage(baseView.currentPageNumber-1);     
    baseView.pdfThumbnail.create(baseView.file.pages); 
    baseView.viewComponents.toolbar.total_page.value = baseView.pdf.numPages;
    baseView.viewComponents.toolbar.current_page.setAttribute('max',baseView.pdf.numPages);   
    baseView.updatetoolbar(baseView.currentPageNumber);
    baseView.set_anotation(baseView.current_anotation);

}
function defineRotateCustom(pages){   
    pages.forEach(element => {
     Object.defineProperties(element,{
        viewRotate:{
            value : 0,
            writable: true
        }
        
     })    
   });
}
function createContent(baseView){   
    baseView.pdfContent.create(baseView);  
    //baseView.pdfContent.renderPage(baseView.currentPageNumber);     
}

async function getPages(pdf){
    const pageNumbers = Array.from({length: pdf.numPages}, (_, i) => i + 1)
    const promises = pageNumbers.map(pageNo => pdf.getPage(pageNo));
    return await Promise.all(promises);
}
async function getPdfBytes(url){
    return await fetch(url).then((res) => res.arrayBuffer());
}

function load(baseView){
    var loadingTask  = pdfjsLib.getDocument({
      data: baseView.file.bytes,
      cMapUrl: '/cmaps/',
      cMapPacked: true
    });
   
    loadingTask.promise.then(function(pdf) {    
        baseView.pdf = pdf;  
        createComponents(baseView);        
    })
}    
function reloadFile(baseView){
    var loadingTask  = pdfjsLib.getDocument({
      data: baseView.file.bytes,
      cMapUrl: '/cmaps/',
      cMapPacked: true
    });
   
    loadingTask.promise.then(function(pdf) {    
        baseView.pdf = pdf;  
        reloadCreateComponents(baseView);        
        
    })
}    
function IsPageDisplay(page,container){
    var result = false;
    const containerHeight = container.clientHeight;
    let visiblePageHeight = getVisibleHeight(page,container);
    if(visiblePageHeight >= containerHeight / 2){
        result = true;
    }
    return result;  
}


function getVisibleHeight(element,container){
	let scrollTop = container.scrollTop;
	let scrollBot = scrollTop + container.clientHeight;
	let containerRect = container.getBoundingClientRect();
	let eleRect = element.getBoundingClientRect();
	let rect = {};
	rect.top = eleRect.top - containerRect.top,
	rect.right = eleRect.right - containerRect.right,
	rect.bottom = eleRect.bottom - containerRect.bottom,
	rect.left = eleRect.left - containerRect.left;
	let eleTop = rect.top + scrollTop;
	let eleBot = eleTop + element.offsetHeight;
	let visibleTop = eleTop < scrollTop ? scrollTop : eleTop;
	let visibleBot = eleBot > scrollBot ? scrollBot : eleBot;

	return visibleBot - visibleTop;
}

function isElementVisible (el, holder) {
    holder = holder || document.body
    const { top, bottom, height } = el.getBoundingClientRect()
    const holderRect = holder.getBoundingClientRect()
  
    return top <= holderRect.top
      ? holderRect.top - top <= height
      : bottom - holderRect.bottom <= height
  }

  function getScalePageFit(baseView){
    var height = baseView.viewComponents.viewContainer.offsetHeight - 4;
    return height/baseView.file.pages[0].getViewport({scale:1,rotation:baseView.currentRotate}).height;
  }
  function getScalePageWidth(baseView){
    var width = baseView.viewComponents.viewContainer.offsetWidth - 20;
    return width/baseView.file.pages[0].getViewport({scale:1,rotation:baseView.currentRotate}).width;
  }


  function checkStatus(array){
    var hasChild = false;
    for (let index = 0; index < array.length; index++) {
        var hasChild = false;
        const element = array[index];
        if(element.hasChildNodes()){
            hasChild = true;
        }
    }
    return hasChild;
  }