import {fabric} from 'fabric'; 
import {Config} from '../define/AppConfig';
import { ANOTATION } from './annotation';
import {PdfHistory, TYPE_HISTORY, TYPE_OBJECT} from '../edit/undo_redo'
export function loadFabric(canvas,eventBus,pageNum){
    var fcanvas = new fabric.Canvas(canvas,{
      preserveObjectStacking: true
    });   
    fcanvas.stateful = true;
    fcanvas.pageNum = pageNum;     
    fcanvas.on('mouse:down',function(event){    
        eventBus.dispatch("fabric_mouse_down", {
            canvas: this  , 
            ev :    event
        });    
   });
   fcanvas.on('object:modified',function(event){    
    eventBus.dispatch("object_modified", {
        canvas: this  , 
        ev :    event
    });        
   });
   fcanvas.on('object:added',function(event){   
    eventBus.dispatch("object_added", {
      canvas: this  , 
      ev :    event
  }); 
   });
   fcanvas.on('path:created',function(ob){    
    eventBus.dispatch("path_created", {
        canvas: this  , 
        ob :    ob
    });    
    });
    fcanvas.on('object:moving',function(event){    
    eventBus.dispatch("object_moving", {
        canvas: this  , 
        ev :    event
    });    
    });
    fcanvas.on('before:render',function(event){    
        eventBus.dispatch("before_render", {
            canvas: this  , 
            ev :    event
        });    
    });
    fcanvas.on('mouse:move',function(event){    
      eventBus.dispatch("mouse_move", {
          canvas: this  , 
          ev :    event
      });    
    });

    // fcanvas.on('mouse:over', (evet) => {
    //   const { target } = evet;
    //   if (fcanvas.getActiveObjects().length) {
    //     // skip group hover
    //     return;
    //   }
    
    //   // skip group hover
    //   if (target instanceof fabric.Object && !(target instanceof Array)) {
    //     const bound = target.getBoundingRect();
    //     const ctx = fcanvas.getContext();
    //     // var gradient = ctx.createLinearGradient(0, 0, 170, 0);
    //     // gradient.addColorStop("0", "magenta");
    //     // gradient.addColorStop("0.5" ,"blue");
    //     // gradient.addColorStop("1.0", "red");
    //     ctx.strokeStyle = 'red';//gradient;
    //     ctx.lineWidth = 2;
    //     ctx.setLineDash([5, 3]);
    //     ctx.strokeRect(
    //       bound.left,
    //       bound.top,
    //       bound.width,
    //       bound.height
    //     );
    //   }
    // });
    // fcanvas.on('mouse:out', (evet) => {
    //   const { target } = evet;
    //   if (fcanvas.getActiveObjects().length) {
    //     return;
    //   }
    
    //   // skipp group hover
    //   if (target instanceof fabric.Object && !(target instanceof Array)) {
    //      fcanvas.renderAll(); // render all, will clear bounds box drawed by mouse:over
    //   }
    // });

    fcanvas.on('mouse:down:before',function(event){    
        eventBus.dispatch("mouse_down_before", {
            canvas: this  , 
            ev :    event
        });    
    });
    fcanvas.on('mouse:up', function(event){
        eventBus.dispatch("mouse_up", {
            canvas: this  , 
            ev :    event
        });  
    });
    fcanvas.on('object:selected',function(event){    
    eventBus.dispatch("object_selected", {
        canvas: this  , 
        ev :    event
    });    
    });
    fcanvas.on('selection:cleared',function(event){    
        eventBus.dispatch("selection_cleared", {
            canvas: this  , 
            ev :    event
        });    
    });
   


   return fcanvas;
}

export const fabricUltil = {   
  mouse_down_before(anotation,fcanvas){
   
    if(anotation == ANOTATION.MOVE ){
      setSelectableAll(fcanvas, true);
    }
    else{
      setSelectableAll(fcanvas, false);
    }

    if(anotation == ANOTATION.FREE_DRAW ){
      if(fcanvas.isDrawingMode != true) 
      {
       fcanvas.isDrawingMode = true;
       fcanvas.freeDrawingBrush = new fabric['PencilBrush'](fcanvas);
       fcanvas.freeDrawingBrush.width = Config.freedraw.strokeWidth;
       fcanvas.freeDrawingBrush.strokeDashArray = convertArray(Config.freedraw.dash,Config.freedraw.strokeWidth)
       let color = getPencilColor(anotation);
       fcanvas.freeDrawingBrush.color = color;
      }
    }  
    else if(anotation == ANOTATION.PENCIL) {
      if(fcanvas.isDrawingMode != true) 
      {
       fcanvas.isDrawingMode = true;
       fcanvas.freeDrawingBrush = new fabric['PencilBrush'](fcanvas);
       fcanvas.freeDrawingBrush.width = Config.pencil.strokeWidth;
       fcanvas.freeDrawingBrush.strokeDashArray = convertArray(Config.pencil.dash,Config.pencil.strokeWidth)
       let color = getPencilColor(anotation);
       fcanvas.freeDrawingBrush.color = color;
      
      }
    } 
   else{
    fcanvas.isDrawingMode = false;
   }
  }    ,
  mouseDown(event,canvas,viewer){    
        viewer.mouseDown = true;
        canvas.hoverCursor = 'auto';    
        switch(viewer.current_anotation)
        {
          case ANOTATION.NONE:          
          case ANOTATION.MOVE:            
            break
          case ANOTATION.ADD_STAMP:
            startAddingStamp(event,canvas,viewer);
            break;
          case ANOTATION.ADD_TEXT:
            startAddingLabel(event,canvas,viewer);      
            break;
         case ANOTATION.FREE_DRAW:  
            break;
         case ANOTATION.ADD_LINK:         
             startAddingLink(event,canvas,viewer);
            //  canvas.selection = true;
             break;
         case ANOTATION.ADD_IMAGE:         
             startAddingImage(event,canvas,viewer);
             break;
         case ANOTATION.DRAW_LINE:                     
             startAddingLine(event,canvas,viewer);   
             break;
         case ANOTATION.DRAW_RECTANGLE:  
             startAddingRectangle(event,canvas,viewer);
             break;
         case ANOTATION.DRAW_ELLIPSE:     
             startAddingEllipse(event,canvas,viewer);
             break;  
         case ANOTATION.ADD_TEXTBOX:
             startAddingTextbox(event,viewer,canvas);
             break;
         case ANOTATION.ADD_TEXT_AREA:
             startAddingTextArea(event,viewer,canvas);
             break;
         case ANOTATION.ADD_RADIO:
             startAddingRadio(event,viewer,canvas);
             break;
         case ANOTATION.ADD_CHECKBOX:
             startAddingCheckbox(event,viewer,canvas);
             break;
         case ANOTATION.ADD_DROPDOWN:
             startAddingDropdown(event,viewer,canvas);
             break;
         case ANOTATION.ERASE:
            startAddingErase(event,canvas,viewer);
            break;
         case ANOTATION.ADD_XMARK: 
         case ANOTATION.ADD_CHECKMARK:  
         case ANOTATION.ADD_DOT:         
            if(viewer.image_selected){
                startAddingMark(event,canvas,viewer);
            }   
            break;
         case ANOTATION.DRAW_ARROW:
           startAddingArrow(event,canvas,viewer); 
          
        
        }    
    },   
    mouseMove(viewer,canvas,event){
      if(!viewer.mouseDown) return;
      switch(viewer.current_anotation)
      {    
       case ANOTATION.ADD_LINK:    
          startDrawingLink(canvas,event,viewer);
          break;
        case ANOTATION.ADD_STAMP:         
          break;
        case ANOTATION.DRAW_LINE:         
          startDrawingLine(canvas,event,viewer);
          break;
        case ANOTATION.DRAW_ELLIPSE:     
          startDrawingLineEllipse(canvas,event,viewer);        
          break;
        case ANOTATION.DRAW_RECTANGLE:   
        case ANOTATION.ERASE:    
          startDrawingRectangle(canvas,event,viewer);   
          break;       
       case ANOTATION.DRAW_ARROW:
          startDrawingArrow(canvas,event,viewer);    
          break;
       
       default:  
          break;
      }    
     },      
     mouseUp(viewer){
      viewer.mouseDown = false;     
     },   
    pathCreated(ob,annotation,viewer) {
        switch(annotation)
        {    
         case ANOTATION.PENCIL:      
            ob.path.myOpacity =  Config.pencil.opacity;
            ob.path.name =  'highlighter';
            ob.path.opacity = Config.pencil.opacity;
            break;
          case ANOTATION.FREE_DRAW:      
            ob.path.name =  'brush';
            ob.path.opacity = Config.freedraw.opacity;
            break;
         default:  
          break;
        }
        ob.path.id =  random_string(10);
        ob.path.type_path = annotation;  
        ob.path.annotation = annotation; 
        ob.path.lockRotation = true;
        ob.path.lockScalingX = true;
        ob.path.lockScalingY = true;
        ob.path.hasControls = false;
        viewer.addHistory(new PdfHistory(ob.path.canvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,ob.path,null));
        },
      
    objectModified(event) {
          let modifiedObject = event.target;
        if(modifiedObject.type == "path"){
          let oldState = JSON.parse(JSON.stringify(modifiedObject._stateProperties));
          modifiedObject.setCoords();
           modifiedObject.path.forEach(point => {
            for(let i = 1;i< point.length;i++){
              if(i%2!=0){
                point[i] = point[i] + modifiedObject.left - oldState.left * modifiedObject.scaleX;
              }
              else{
                point[i] = point[i] + modifiedObject.top - oldState.top * modifiedObject.scaleY;
              }
            }  
          })
        }    
      },
      objectMoving(canvas,e,viewer){
        var p = e.target;
        switch(p.annotation)
        {    
         case ANOTATION.DRAW_ARROW:   //https://jsfiddle.net/LordWolfer/2pf0Lm81/99/
            setMovingArrow(p,canvas,viewer);
            break;
         case ANOTATION.DRAW_LINE:   //https://jsfiddle.net/LordWolfer/2pf0Lm81/99/
            setMovingLine(p,canvas,viewer);
            break;
        }
      },
      setMovingArrow(p,canvas,viewer){
        setMovingArrow(p,canvas,viewer)
      },
      
         
       objectSelected(canvas,event,viewer){
        switch(viewer.current_anotation)
        {    
         case ANOTATION.ADD_LINK:         
            // drawingMode = false;
            break;
         default:  
          break;
        }    
       },
       selectionCleared(canvas,event,viewer){
        switch(viewer.current_anotation)
        {    
         case ANOTATION.ADD_LINK:         
            // drawingMode = false;
            break;
         default:  
          break;
        }    
       }
      
       
      
}
function startAddingArrow(o,canvas,view){
    var pointer = canvas.getPointer(o.e);
    view.origX = pointer.x;
    view.origY = pointer.y;
    createArrow(canvas,view);
  }
function createArrow(canvas,view){
    var fromx = view.origX, fromy = view.origY, tox =view.origX, toy=view.origY;
    var circle1 = createCircle(fromx, fromy,1);
    var circle2 = createCircle(tox, toy,2);  
    var points = getLinesArrowBeside(fromx, fromy, tox, toy);  
    var line1 = createLineBeside(points.start,circle1,circle2);
    var line2 = createLineBeside(points.end,circle1,circle2);
    var line3 = null, line4 = null;
    if(Config.shape.arrow.position_arrow == 2){
      var line3 = createLineBeside(points.startLeft,circle1,circle2);
      var line4 = createLineBeside(points.endLeft,circle1,circle2);
      canvas.add(line3);
      canvas.add(line4);
    }
    view.objectDrawing = createLine(view,[ fromx, fromy, tox, toy ],circle1,circle2,line1,line2,line3,line4);
    circle1.line = view.objectDrawing;
    circle2.line = view.objectDrawing;
    canvas.add(view.objectDrawing);
    canvas.add(line1);
    canvas.add(line2);
    canvas.add(circle1,circle2);
   
    view.objectDrawing.setCoords();
    circle1.setCoords();
    circle2.setCoords();
    canvas.bringToFront(circle1);
    canvas.bringToFront(circle2);

    canvas.sendBackwards(circle1.line);
    circle1.on('deselected', function (options) {
      this.visible = this.line.circle2.visible = false;
    });
    circle1.on('selected', function (options) {
      this.visible = this.line.circle2.visible = true;
    });
    circle2.on('deselected', function (options) {
      this.visible = this.line.circle1.visible = false;
    });
    circle2.on('selected', function (options) {
      this.visible = this.line.circle1.visible  = true;
    });
  
    view.objectDrawing.on('deselected', function (options) {
      this.circle1.visible = this.circle2.visible = false;
    });
    view.objectDrawing.on('selected', function (options) {
      this.circle1.visible = this.circle2.visible = true;
    });
    canvas.renderAll();
    view.addHistory(new PdfHistory(canvas.pageNum,view.current_anotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,view.objectDrawing,null));
}
function getLinesArrowBeside(fromx, fromy, tox, toy,headlen = null){
    var angle = Math.atan2(toy - fromy, tox - fromx);
    if(headlen == null){
      headlen = Config.shape.arrow.size*3;
    }
    var fromxTemp = fromx + (headlen) * Math.cos(angle),
    fromyTemp = fromy + (headlen) * Math.sin(angle);
    var toxTemp = tox - (headlen) * Math.cos(angle),
      toyTemp = toy - (headlen) * Math.sin(angle);
  
    return {start:[toxTemp - (headlen) * Math.cos(angle - Math.PI / 2),toyTemp - (headlen) * Math.sin(angle - Math.PI / 2),tox,toy],
           end :  [ toxTemp - (headlen) * Math.cos(angle + Math.PI / 2), toyTemp - (headlen) * Math.sin(angle + Math.PI / 2),
           tox , toy ],
           startLeft:[fromxTemp + (headlen) * Math.cos(angle - Math.PI / 2),fromyTemp + (headlen) * Math.sin(angle - Math.PI / 2),fromx,fromy],
           endLeft:[fromxTemp - (headlen) * Math.cos(angle - Math.PI / 2),fromyTemp - (headlen) * Math.sin(angle - Math.PI / 2),fromx,fromy]
          }
  }
function createLine(viewer,coords,circle1,circle2,line1,line2,line3 = null, line4 =null) { 
    var line = new fabric.Line(coords, {
      fill: Config.shape.arrow.color,
      stroke: Config.shape.arrow.color,
      strokeWidth: Config.shape.arrow.size,
      selectable: true,
      hasControls: false,
      hasBorders: false,
      centeredRotation: false,
      centeredScaling: false,
      perPixelTargetFind: true,
      name: 'arrow',
      annotation: viewer.current_anotation,
      id: random_string(10),
      strokeDashArray: convertArray(Config.shape.arrow.dash,Config.shape.arrow.size)
      //originX: 'center',
      //originY: 'center'
    });
    
    line.circle1 = circle1;
    line.circle2 = circle2;
    line.line1 = line1;
    line.line2 = line2;
    line.line3 = line3;
    line.line4 = line4;
    line.typeObject = ANOTATION.DRAW_ARROW;
    line.element = 0;
    line.position_arrow = Config.shape.arrow.position_arrow;
    return line;
  }
function createLineBeside(coords,circle1,circle2) { 
    var line = new fabric.Line(coords, {
      fill: Config.shape.arrow.color,
      stroke: Config.shape.arrow.color,
      strokeWidth: Config.shape.arrow.size,
      selectable: false,
      hasControls: false,
      hasBorders: false,
      centeredRotation: false,
      centeredScaling: false,
      perPixelTargetFind: true,
      strokeDashArray: convertArray(Config.shape.arrow.dash,Config.shape.arrow.size),
      disableSelect: true

    });    
    line.circle1 = circle1;
    line.circle2 = circle2;
    line.typeObject = ANOTATION.DRAW_ARROW;
    return line;
  }
function createCircle(left, top,index) {
    var radius = Config.shape.arrow.size;
    var leftCircle = left - radius;
    var topCircle = top - radius;
    var c = new fabric.Circle({
      left: leftCircle,
      top: topCircle,
      radius: Config.shape.arrow.size,
      stroke: Config.shape.arrow.color,
      fill:'red',
      hasControls: false,
      hasBorders: false,
      name: 'circle'+ index,
      visible: false,
      annotation: ANOTATION.DRAW_ARROW
    });
    c.startX = c.left;
    c.startY = c.top;
    c.element = index;
    c.typeObject = ANOTATION.DRAW_ARROW;
    return c;
  }
function getPencilColor(anotation){  
    var c =  Config.pencil.color;   
    if(anotation == ANOTATION.FREE_DRAW){
      c =  Config.freedraw.color;      
    }     
    return c;
  }
  function setMovingLine(p,canvas,viewer){
    var circle1,circle2,line ;
    if(p.element == 0){
      circle1 = p.head1,circle2 = p.head2 , line = p;
      var c1Left, c1Top, c2Left, c2Top;
      // CALCULATE THE circle1 AND circle2 POINTS FOR EACH SCENARIO
      if (circle1.top < circle2.top) {
         if (circle1.left < circle2.left) {
            c1Left = p.left  -  circle1.radius/2;
            c1Top = p.top  -  circle1.radius/2;
            c2Left = p.left + p.width - circle2.radius/2;
            c2Top = p.top + p.height - circle2.radius/2;
         }
         else {
            c1Left = p.left + p.width - circle1.radius/2;
            c1Top = p.top - circle1.radius/2;
            c2Left = p.left - circle1.radius ;
            c2Top = p.top + p.height ;// ok
         }
      }
      else {
         if (circle1.left < circle2.left) {
            c1Left = p.left - circle1.radius/2;
            c1Top = p.top + p.height - circle1.radius/2;
            c2Left = p.left + p.width - circle1.radius/2;
            c2Top = p.top - circle1.radius/2;
         }
         else {
            c1Left = p.left + p.width - circle1.radius/2;
            c1Top = p.top + p.height - circle1.radius/2;
            c2Left = p.left - circle1.radius;
            c2Top = p.top - circle1.radius ;
         }
      }
      circle1.set({ left: c1Left, top: c1Top });
      circle2.set({ left: c2Left, top: c2Top });
      circle1.setCoords();
      circle2.setCoords();   
    }
    if(p.element == 1 || p.element == 2){
      circle1 = p.line.head1,circle2 = p.line.head2 ;
      p.line && p.line.set({ 'x1': circle1.getCenterPoint().x - circle1.radius/2, 'y1': circle1.getCenterPoint().y - circle1.radius/2});
      p.line && p.line.set({ 'x2': circle2.getCenterPoint().x - circle1.radius/2, 'y2': circle2.getCenterPoint().y - circle1.radius/2});
      p.line.setCoords();    
    }   
    canvas.renderAll();
  }
  function setMovingArrow(p,canvas,viewer){
    var circle1,circle2, line1, line2,arrow  ;
    if(p.element == 0){
      arrow =  p;
      circle1 = p.circle1,circle2 = p.circle2 , line1 = p.line1, line2 = p.line2;
      var c1Left, c1Top, c2Left, c2Top;
      // CALCULATE THE circle1 AND circle2 POINTS FOR EACH SCENARIO
      if (circle1.top < circle2.top) {
         if (circle1.left < circle2.left) {
            c1Left = p.left  -  circle1.radius/2;
            c1Top = p.top  -  circle1.radius/2;
            c2Left = p.left + p.width - circle2.radius/2;
            c2Top = p.top + p.height - circle2.radius/2;
         }
         else {
            c1Left = p.left + p.width - circle1.radius/2;
            c1Top = p.top - circle1.radius/2;
            c2Left = p.left - circle1.radius ;
            c2Top = p.top + p.height ;// ok
         }
      }
      else {
         if (circle1.left < circle2.left) {
            c1Left = p.left - circle1.radius/2;
            c1Top = p.top + p.height - circle1.radius/2;
            c2Left = p.left + p.width - circle1.radius/2;
            c2Top = p.top - circle1.radius/2;
         }
         else {
            c1Left = p.left + p.width - circle1.radius/2;
            c1Top = p.top + p.height - circle1.radius/2;
            c2Left = p.left - circle1.radius;
            c2Top = p.top - circle1.radius ;
         }
      }
      circle1.set({ left: c1Left, top: c1Top });
      circle2.set({ left: c2Left, top: c2Top });
      circle1.setCoords();
      circle2.setCoords();   
      arrow.setCoords();
    }      
    if(p.element == 1 || p.element == 2){
      circle1 = p.line.circle1,circle2 = p.line.circle2 , line1 = p.line.line1, line2 = p.line.line2;
      p.line && p.line.set({ 'x1': circle1.getCenterPoint().x - circle1.radius/2, 'y1': circle1.getCenterPoint().y - circle1.radius/2});
      p.line && p.line.set({ 'x2': circle2.getCenterPoint().x - circle1.radius/2, 'y2': circle2.getCenterPoint().y - circle1.radius/2});
      p.line.setCoords();   
      arrow =  p.line; 
    }   
    var headlen = line1.strokeWidth*3;
    var points = getLinesArrowBeside(circle1.getCenterPoint().x - circle1.radius/2 ,circle1.getCenterPoint().y - circle1.radius/2
    ,circle2.getCenterPoint().x - circle1.radius/2,    circle2.getCenterPoint().y- circle1.radius/2,headlen);
    line1 && line1.set({ 'x1': points.start[0], 'y1': points.start[1] });
    line1 && line1.set({ 'x2': points.start[2], 'y2': points.start[3] });
    line2 && line2.set({ 'x1': points.end[0], 'y1': points.end[1]});
    line2 && line2.set({ 'x2': points.end[2], 'y2': points.end[3] });
    line1.setCoords();
    line2.setCoords();
    if(arrow.position_arrow == 2){
      var line3 , line4 ;
      if(p.element == 0){
        line3 = p.line3;
        line4 = p.line4;
      }
      if(p.element == 1 || p.element == 2){
        line3 = p.line.line3;
        line4 = p.line.line4;
      }
      line3 && line3.set({ 'x1': points.startLeft[0], 'y1': points.startLeft[1] });
      line3 && line3.set({ 'x2': points.startLeft[2], 'y2': points.startLeft[3] });
      line4 && line4.set({ 'x1': points.endLeft[0], 'y1': points.endLeft[1]});
      line4 && line4.set({ 'x2': points.endLeft[2], 'y2': points.endLeft[3] });
      line3.setCoords();
      line4.setCoords();
    }  
    canvas.calcOffset();
    canvas.renderAll();
  }
  
   function startDrawingLineEllipse(canvas,o,viewer){    
    var pointer = canvas.getPointer(o.e);
    var rx = Math.abs(viewer.origX - pointer.x)/2;
    var ry = Math.abs(viewer.origY - pointer.y)/2;
    if (rx > viewer.objectDrawing.strokeWidth) {
      rx -= viewer.objectDrawing.strokeWidth/2
    }
     if (ry > viewer.objectDrawing.strokeWidth) {
      ry -= viewer.objectDrawing.strokeWidth/2
    }
    viewer.objectDrawing.set({ rx: rx, ry: ry});
    viewer.objectDrawing.set("strokeWidth", parseInt(Config.shape.ellipse.strokeWidth));
    if(viewer.origX>pointer.x){
        viewer.objectDrawing.set({originX: 'right' });
    } else {
        viewer.objectDrawing.set({originX: 'left' });
    }
    if(viewer.origY>pointer.y){
        viewer.objectDrawing.set({originY: 'bottom'  });
    } else {
        viewer.objectDrawing.set({originY: 'top'  });
    }
    canvas.renderAll();
  }
  function startDrawingLink(canvas,event,viewer){    
    var pointer = canvas.getPointer(event.e);
    
    if(viewer.origX>pointer.x){
        viewer.objectDrawing.set({ left: Math.abs(pointer.x) });
    }
    if(viewer.origY>pointer.y){
        viewer.objectDrawing.set({ top: Math.abs(pointer.y) });
    }
    
    viewer.objectDrawing.set({ width: Math.abs(viewer.origX - pointer.x) });
    viewer.objectDrawing.set({ height: Math.abs(viewer.origY - pointer.y) });
    
    
    canvas.renderAll();
   }
   function startDrawingLine(canvas,event,viewer){
    var pointer = canvas.getPointer(event.e);
    var tox = pointer.x;
    var toy = pointer.y;
    var origX = viewer.origX;
    var origY = viewer.origY;
    var line = viewer.objectDrawing;
    line && line.set({ 'x2': tox, 'y2': toy });    

    line.head1 && line.head1.set({ 'left': origX -  line.head1.radius/2, 'top': origY - line.head1.radius/2 });
    line.head2 && line.head2.set({ 'left': tox -  line.head2.radius/2, 'top': toy -  line.head1.radius/2 });    
  
    line.setCoords();
    line.head1.setCoords();
    line.head2.setCoords();

    line.set("strokeWidth", parseInt(Config.shape.line.strokeWidth));
    line.set("opacity", parseFloat(Config.shape.line.opacity/100));

    canvas.renderAll();
  
  }
  function startDrawingArrow(canvas,event,viewer){
    var pointer = canvas.getPointer(event.e);
    var tox = pointer.x;
    var toy = pointer.y;
    var arrow = viewer.objectDrawing
    var origX = viewer.origX;
    var origY = viewer.origY;
    var arrow = viewer.objectDrawing;
    arrow && arrow.set({ 'x2': tox, 'y2': toy });
    var points = getLinesArrowBeside(origX,origY,tox,toy);
  
    arrow.line1 && arrow.line1.set({ 'x1': points.start[0], 'y1': points.start[1] });
    arrow.line2 && arrow.line2.set({ 'x1': points.end[0], 'y1': points.end[1] });
  
    arrow.line1 && arrow.line1.set({ 'x2': tox, 'y2': toy });
    arrow.line2 && arrow.line2.set({ 'x2': tox, 'y2': toy });
  
    if(arrow.position_arrow == 2){
      arrow.line3 && arrow.line3.set({ 'x1': points.startLeft[0], 'y1': points.startLeft[1] });
      arrow.line4 && arrow.line4.set({ 'x1': points.endLeft[0], 'y1': points.endLeft[1] });
    
      arrow.line3 && arrow.line3.set({ 'x2': origX, 'y2': origY });
      arrow.line4 && arrow.line4.set({ 'x2': origX, 'y2': origY });
    }
  
    arrow.circle1 && arrow.circle1.set({ 'left': origX -  Config.shape.arrow.size/2, 'top': origY - Config.shape.arrow.size/2 });
    arrow.circle2 && arrow.circle2.set({ 'left': tox -  Config.shape.arrow.size/2, 'top': toy -  Config.shape.arrow.size/2 });  
  
  
    arrow.setCoords();
    arrow.line1.setCoords();
    arrow.line2.setCoords();
    arrow.circle1.setCoords();
    arrow.circle2.setCoords();
    canvas.renderAll();
  
  }

  function startAddingEllipse(o,canvas,viewer) {    
      var pointer = canvas.getPointer(o.e);
      viewer.origX = pointer.x;
      viewer.origY = pointer.y;
      viewer.objectDrawing = new fabric.Ellipse({
        left: viewer.origX,
        top: viewer.origY,
        originX: 'left',
        originY: 'top',
        rx: pointer.x-viewer.origX,
        ry: pointer.y-viewer.origY,
        angle: 0,
        fill: '',
        stroke: Config.shape.ellipse.stroke,
        strokeWidth:Config.shape.ellipse.strokeWidth,
        opacity:Config.shape.ellipse.opacity/100,
        name: 'ellipse',
        annotation : viewer.current_anotation,
        strokeDashArray: convertArray(Config.shape.ellipse.dash,Config.shape.ellipse.strokeWidth),
        id: random_string(10)
    });
      canvas.add(viewer.objectDrawing);
      canvas.renderAll();
      viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,viewer.objectDrawing,null));
  }
  function startAddingRectangle(o,canvas,viewer) {   
      var pointer = canvas.getPointer(o.e);
      viewer.origX = pointer.x;
      viewer.origY = pointer.y;
      var pointer = canvas.getPointer(o.e);
      viewer.objectDrawing = new fabric.Rect({
        typyObject:"rectangle",
        left: viewer.origX,
        top: viewer.origY,
        originX: 'left',
        originY: 'top',
        stroke: Config.shape.rectangle.stroke,
        width: pointer.x - viewer.origX,
        height: pointer.y - viewer.origY,
        angle: 0,
        strokeWidth: Config.shape.rectangle.strokeWidth,
        fill: '',
        transparentCorners: false,
        hasControls:true,
        opacity:Config.shape.rectangle.opacity/100,
        name: 'rectangle',
        annotation : viewer.current_anotation,
        strokeDashArray: convertArray(Config.shape.rectangle.dash,Config.shape.rectangle.strokeWidth),
        id: random_string(10)
      });
      canvas.add(viewer.objectDrawing);
      canvas.renderAll();
      viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,viewer.objectDrawing,null));
      return viewer.objectDrawing;
  }
  function startAddingErase(o,canvas,viewer) {    
    var pointer = canvas.getPointer(o.e);
    viewer.origX = pointer.x;
    viewer.origY = pointer.y;
    viewer.objectDrawing = new fabric.Rect({
      typyObject:"rectangle",
      left: viewer.origX,
      top: viewer.origY,
      originX: 'left',
      originY: 'top',
      stroke: '#FFFFFF',
      width: pointer.x - viewer.origX,
      height: pointer.y - viewer.origY,
      angle: 0,
      strokeWidth: 0,
      fill: '#FFFFFF',
      transparentCorners: false,
      hasControls:true,
      id: random_string(10),
      name: 'erase'
    });
    canvas.add(viewer.objectDrawing);
    canvas.renderAll();
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,viewer.objectDrawing,null));
}


  function startDrawingRectangle(canvas,o,viewer){
   
      var pointer = canvas.getPointer(o.e);
  
      if (viewer.origX > pointer.x) {
        viewer.objectDrawing.set({
          left: Math.abs(pointer.x)
        });
      }
      if (viewer.origY > pointer.y) {
        viewer.objectDrawing.set({
          top: Math.abs(pointer.y)
        });
      }
  
      viewer.objectDrawing.set({
        width: Math.abs(viewer.origX - pointer.x)
      });
      viewer.objectDrawing.set({
        height: Math.abs(viewer.origY - pointer.y)
      });
      viewer.objectDrawing.set("strokeWidth", parseInt(Config.shape.rectangle.strokeWidth));
      canvas.renderAll();
  }
  function startAddingLabel(event,canvas,viewer){ 
    var boldText = Config.text.addtext.boldText? 'bold' : 'normal';
    var italicText = Config.text.addtext.italicText? 'italic' : 'normal';
    var txt = new fabric.IText(Config.text.addtext.value, { 
        left: event.pointer.x,
        top:   event.pointer.y,
        fontFamily: Config.text.addtext.fontFamily,
        fontWeight: boldText,
        fontStyle: italicText,
        fill: Config.text.addtext.fill,
        fontSize: Config.text.addtext.fontSize,
        name: 'label',
        annotation : viewer.current_anotation,
        id: random_string(10)
        // hasBorders:false,
        // hasControls:true
    })
    txt.setControlsVisibility({
    mt: false, 
    mb: false, 
    ml: false, 
    mr: false, 
    bl: false,
    br: false, 
    tl: false, 
    tr: false,
    // mtr: false, 
    });
    canvas.add(txt);
    canvas.renderAll();   
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,txt,null));
}
Array.prototype.replace = function(t, v) {
  if (this.indexOf(t)!= -1)
      this[this.map((e, i) => [i, e]).filter(e => e[1] == t)[0][0]] = v;
};

function startAddingLine(o,canvas,viewer) {    
    var pointer = canvas.getPointer(o.e);
    var points = [pointer.x, pointer.y, pointer.x, pointer.y];
    viewer.origX = pointer.x;
    viewer.origY = pointer.y;
    
      
    viewer.objectDrawing = new fabric.Line(points, {
      fill: Config.shape.line.backGroundColor,
      stroke: Config.shape.line.backGroundColor,
      strokeWidth: Config.shape.line.strokeWidth,
      selectable: true,
      hasControls: false,
      hasBorders: false,
      centeredRotation: false,
      centeredScaling: false,
      perPixelTargetFind: true,
      name: 'line',
      annotation: viewer.current_anotation,
      id: random_string(10),
      strokeDashArray: convertArray(Config.shape.line.dash,Config.shape.line.strokeWidth),
      element: 0
    });
    canvas.add(viewer.objectDrawing);
    var head1 = createPoint(pointer.x, pointer.y,1,Config.shape.line.strokeWidth,viewer.current_anotation);
    var head2 = createPoint(pointer.x, pointer.y,2,Config.shape.line.strokeWidth,viewer.current_anotation);  
    viewer.objectDrawing.head1 = head1;
    viewer.objectDrawing.head2 = head2;
    head1.line = viewer.objectDrawing;
    head2.line = viewer.objectDrawing;
    canvas.add(head1,head2);    
    
    viewer.objectDrawing.setCoords();
    head1.setCoords();
    head2.setCoords();
    canvas.bringToFront(head1);
    canvas.bringToFront(head2);
    canvas.sendBackwards(head1.line);
    head1.on('deselected', function (options) {
      this.visible = this.line.head2.visible = false;
    });
    head1.on('selected', function (options) {
      this.visible = this.line.head2.visible = true;
    });
    head2.on('deselected', function (options) {
      this.visible = this.line.head1.visible = false;
    });
    head2.on('selected', function (options) {
      this.visible = this.line.head1.visible  = true;
    });
  
    viewer.objectDrawing.on('deselected', function (options) {
      this.head1.visible = this.head2.visible = false;
    });
    viewer.objectDrawing.on('selected', function (options) {
      this.head1.visible = this.head2.visible = true;
    });
    canvas.renderAll();
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,viewer.objectDrawing,null));
}
function startAddingLink(event,canvas,viewer){ 
    var pointer = canvas.getPointer(event.e);
    viewer.origX = pointer.x;
    viewer.origY = pointer.y;
    var default_link = {link_type: Config.link.radio, url: Config.link.url, email:Config.link.mail,phone:Config.link.phone,page:Config.link.page}
    viewer.objectDrawing = new fabric.Rect({
              typyObject:"link",
              link_type: default_link,            
              left:viewer.origX,
              top:viewer.origY,
              width:0,
              height:0,
              stroke:'#0282e5',
              strokeDashArray: [5, 5],
              strokeWidth:1,
              fill:'rgba(8,157,227,.1)',
              name: 'link',
              annotation : viewer.current_anotation,
              id: random_string(10)
          });      
          viewer.objectDrawing.setControlsVisibility({        
          mtr: false
        });    
     canvas.add(viewer.objectDrawing);
     canvas.renderAll(); 
     viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,viewer.objectDrawing,null));
 }

 function startAddingImage(event,fcanvas,viewer){  
  var image_selected = viewer.image_selected;
    if(!image_selected) return;
    const myImage = document.createElement("canvas");
    myImage.width = image_selected.width * viewer.currentScaleValue;
    myImage.height = image_selected.height* viewer.currentScaleValue;
    const ctx = myImage.getContext("2d");
    ctx.drawImage(image_selected, 0, 0,myImage.width,myImage.height);
    
    var image,scale_absolute,real_height,real_width;
    var width_fix = 100;

    if(image_selected.className.includes('doc_image_item')){
      width_fix = 100;
    }
    if(image_selected.className.includes('action-repeatable')){
      width_fix = 20;
    }
    scale_absolute = width_fix/image_selected.width;
      real_height = image_selected.height*scale_absolute;
      real_width = image_selected.width*scale_absolute;
      image = new fabric.Image(myImage, {
        left: event.pointer.x - real_width/2, 
        top: event.pointer.y   - real_height/2,
        name: 'image',
        annotation : viewer.current_anotation,
        id: random_string(10)
        
      });
      image.set({
        scaleY: scale_absolute ,
        scaleX: scale_absolute 
      });
    fcanvas.add(image);
    fcanvas.renderAll();
    viewer.addHistory(new PdfHistory(fcanvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,image,null));
  }

  function startAddingTextbox(event,viewer,canvas){
    var currentPageDiv = document.getElementById("page-"+ canvas.pageNum);
    var formLayer = currentPageDiv.querySelector(".formLayer");
    var divText = document.createElement("div");
  
    // Set id attribute with page-#{pdf_page_number} format
    divText.setAttribute("class", "ff-editable-inner texboxer");
    divText.style.width = Config.form.textfield.width*viewer.currentScaleValue  + "px";
    divText.style.height = Config.form.textfield.height*viewer.currentScaleValue  + "px";
    divText.style.left = event.pointer.x + "px";
    divText.style.top = event.pointer.y + "px";
    divText.style.position =  "absolute";
    var input = document.createElement("input");
    input.className = "f-input"; 
    input.setAttribute("kind","textbox");
    input.setAttribute("annotation",viewer.current_anotation);   
    input.style.borderWidth = Config.form.textfield.borderWidth + "px";
    input.style.color = Config.form.textfield.color;
    input.style.borderColor = Config.form.textfield.borderColor ;
    divText.appendChild(input);  
    divText.id = random_string(10);
    divText.setAttribute('id-object',random_string(10));
    formLayer.appendChild(divText);
    divText.setAttribute('name','textbox');
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,divText));
    input.click();
    $(".texboxer").resizable({handles: "all",
    resize: function( event, ui ) {
      var item = this.querySelector('.f-input'); 
      shrinkToFill(item,16);
    }
  
    });
    $(".texboxer").draggable({
      drag: function( event, ui ) {
        hideTools();
      }
    });
    shrinkToFill(input,16);
    
    formLayer.style.pointerEvents = "auto";
   $('.anotation-buttons .anotation-popup a').removeClass("active");    
   
  }
  function shrinkToFill(input,shrinkValue) {
    input.style.fontSize = parseInt(window.getComputedStyle(input).height) - shrinkValue + "px";
  }

  function defineRadioButtonEvent(name){
    $("body").delegate('input[name='+name+']', 'click', function() {	
       var $radio = $(this);
         
         // if this was previously checked
         if ($radio.data('waschecked') == true)
         {
             $radio.prop('checked', false);
             $radio.data('waschecked', false);
         }
         else
             $radio.data('waschecked', true);
             
         
         // remove was checked from other radios
         $radio.siblings('input[name='+name+']').data('waschecked', false);
    })
  }  
  
  function startAddingRadio(event,viewer,canvas){
    var currentPageDiv = document.getElementById("page-"+ canvas.pageNum);
    var formLayer = currentPageDiv.querySelector(".formLayer");
    var divText = document.createElement("div");
  
    // Set id attribute with page-#{pdf_page_number} format
    divText.setAttribute("class", "ff-editable-inner radio");
    
    divText.style.width = Config.form.radio.width*viewer.currentScaleValue + "px";
    divText.style.height = Config.form.radio.height*viewer.currentScaleValue + "px";
    divText.style.left = event.pointer.x - Config.form.radio.width*viewer.currentScaleValue/2 + "px";
    divText.style.top = event.pointer.y - Config.form.radio.height*viewer.currentScaleValue/2 + "px";
    divText.style.position =  "absolute";
    
    var input = document.createElement("input");
    input.className = "f-input"; 
    input.setAttribute("type","radio");
    input.setAttribute("kind","radio");
    input.setAttribute('checked', "checked" );
    input.setAttribute('data-waschecked', "true" );
    input.setAttribute("annotation",viewer.current_anotation);
    var groundName = "rad_" + random_string(6);
    input.setAttribute('name', groundName);
    input.style.borderColor =  Config.form.radio.borderColor;
    input.style.borderWidth = Config.form.radio.borderWidth*viewer.currentScaleValue + 'px';
    if(Config.form.radio.backGroundColor.length>0){
      input.style.background =  Config.form.radio.backGroundColor;
    }
    defineRadioButtonEvent(groundName);    
    divText.appendChild(input);

    divText.id = random_string(10);
    divText.setAttribute('id-object',random_string(10));
    divText.setAttribute('name','radio');
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,divText));
    formLayer.appendChild(divText);
    $(".radio").resizable({handles: "all"});
    $(".radio").draggable();
    
    formLayer.style.pointerEvents = "auto";
    $(".with-tooltip").css("background-color","white");
   $('.anotation-buttons .anotation-popup a').removeClass("active");
    addRule(`input[name="${input.getAttribute('name')}"]:before`,`background-color: ${Config.form.radio.color} !important`); 
   }
   function startAddingTextArea(event,viewer,canvas){
    var currentPageDiv = document.getElementById("page-"+ canvas.pageNum);
    var formLayer = currentPageDiv.querySelector(".formLayer");
    var divText = document.createElement("div");
  
    // Set id attribute with page-#{pdf_page_number} format
    divText.setAttribute("class", "ff-editable-inner arear");
    
    divText.style.width = (parseInt(Config.form.textarea.width*viewer.currentScaleValue) + 10) + "px";
    divText.style.height = (parseInt(Config.form.textarea.height*viewer.currentScaleValue) + 10) + "px";
    divText.style.left = event.pointer.x + "px";
    divText.style.top = event.pointer.y + "px";
    divText.style.position =  "absolute";
    
    var input = document.createElement("textarea");
    input.className = "f-input"; 
    input.setAttribute("kind","textarea")    ;
    input.setAttribute("annotation",viewer.current_anotation);
    input.setAttribute("placeholder", `This is
    some placeholder text
    as an example`);
  
    input.style.borderWidth = Config.form.textarea.borderWidth + "px";
    input.style.color = Config.form.textarea.color;
    input.style.borderColor = Config.form.textarea.borderColor ;
    divText.appendChild(input);
    divText.setAttribute('name','textarea');
    divText.id = random_string(10);
    divText.setAttribute('id-object',random_string(10));
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,divText));

    formLayer.appendChild(divText);
    $(".arear").resizable({handles: "all",
    resize: function( event, ui ) {
      var item = this.querySelector('.f-input');
      item.style.fontSize = parseInt(window.getComputedStyle(input).height)/3 + "px";
    }
  
    });
    $(".arear").draggable({
      drag: function( event, ui ) {
        hideTools();
      }
    });
    input.style.fontSize = parseInt(window.getComputedStyle(input).height)/3 + "px";
    
    formLayer.style.pointerEvents = "auto";
    $(".with-tooltip").css("background-color","white");
   $('.anotation-buttons .anotation-popup a').removeClass("active");
   }

   function startAddingCheckbox(event,viewer,canvas){
    var currentPageDiv = document.getElementById("page-"+ canvas.pageNum);
    var formLayer = currentPageDiv.querySelector(".formLayer");
    var divText = document.createElement("div");
  
    // Set id attribute with page-#{pdf_page_number} format
    divText.setAttribute("class", "ff-editable-inner checkbox");
    
    divText.style.width = Config.form.checkbox.width*viewer.currentScaleValue + "px";
    divText.style.height = Config.form.checkbox.height*viewer.currentScaleValue + "px";
    divText.style.left = event.pointer.x - Config.form.checkbox.width*viewer.currentScaleValue/2 + "px";
    divText.style.top = event.pointer.y - Config.form.checkbox.height*viewer.currentScaleValue/2 + "px";
    divText.style.position =  "absolute";
    
    var input = document.createElement("input");
    input.className = "f-input"; 
    input.setAttribute("type","checkbox");
    input.setAttribute("kind","checkbox");
    input.setAttribute('name', "checkbox_"+ random_string(3) );
    input.setAttribute("annotation",viewer.current_anotation);
    var groundName = "checkbox_" + random_string(6);
    input.setAttribute('name', groundName);
    input.style.borderColor =  Config.form.checkbox.borderColor;
    input.style.borderWidth = Config.form.checkbox.borderWidth*viewer.currentScaleValue + 'px';
    // defineRadioButtonEvent(groundName);
    divText.appendChild(input);
    divText.setAttribute('name','checkbox');
    divText.id = random_string(10);
    divText.setAttribute('id-object',random_string(10));
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,divText));
    formLayer.appendChild(divText);
    $(".checkbox").resizable({handles: "all"});
    $(".checkbox").draggable();
    addRule(`input[name="${groundName}"]:before`,`background-color: ${Config.form.checkbox.color} !important`);  
    formLayer.style.pointerEvents = "auto";
    $(".with-tooltip").css("background-color","white");
   $('.anotation-buttons .anotation-popup a').removeClass("active");
   }
   function startAddingDropdown(event,viewer,canvas){
    var currentPageDiv = document.getElementById("page-"+ canvas.pageNum);
    var formLayer = currentPageDiv.querySelector(".formLayer");
    var divText = document.createElement("div");
  
    // Set id attribute with page-#{pdf_page_number} format
    divText.setAttribute("class", "ff-editable-inner select_tag");
    
    divText.style.width = Config.form.dropdown.width*viewer.currentScaleValue  + "px";
    divText.style.height = Config.form.dropdown.height*viewer.currentScaleValue  + "px";
    divText.style.left = event.pointer.x - Config.form.dropdown.width/2 + "px";
    divText.style.top = event.pointer.y - Config.form.dropdown.height/2 + "px";
    divText.style.position =  "absolute";
    
    var input = document.createElement("select");
    input.className = "f-input ff-select-tag"; 
    input.setAttribute("kind","dropdown");
    input.setAttribute("annotation",viewer.current_anotation);
    input.setAttribute('name', "dropdown_"+ random_string(3) );
    input.append(new Option("Option 1"));
    input.append(new Option("Option 2"));
    input.append(new Option("Option 3"));
    // var groundName = "rad_" + random_string(6);
    // input.setAttribute('name', groundName);
    input.style.borderColor =   Config.form.dropdown.borderColor; //"rgb(251, 192, 45)";
    input.style.color =   Config.form.dropdown.color; //"rgb(251, 192, 45)";
    input.style.borderWidth = Config.form.dropdown.borderWidth*viewer.currentScaleValue + 'px';
    // defineRadioButtonEvent(groundName);
    divText.appendChild(input);
    divText.setAttribute('name','dropdown');
    divText.id = random_string(10);
    divText.setAttribute('id-object',random_string(10));
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,divText));
    formLayer.appendChild(divText);
    shrinkToFill(input,16);
    $(".select_tag").resizable({handles: "all",
    resize: function( event, ui ) {
      var item = this.querySelector('.f-input');
      shrinkToFill(item,16);
    }
  
    });
    $(".select_tag").draggable({
      drag: function( event, ui ) {
        hideTools();
      }
    });
    
    formLayer.style.pointerEvents = "auto";
    $(".with-tooltip").css("background-color","white");
   $('.anotation-buttons .anotation-popup a').removeClass("active");
   }

   function startAddingStamp(event,fcanvas,viewer){    
    if(!viewer.image_selected) return;  
    var image,scale_absolute,real_height,real_width;
    
    // if(viewer.image_selected.className.includes('canvas_signal')){
    //   const myImage = document.createElement("canvas");
    //   myImage.width = viewer.image_selected.width * viewer.currentScaleValue;
    //   myImage.height = viewer.image_selected.height* viewer.currentScaleValue;
    //   const ctx = myImage.getContext("2d");
    //   ctx.drawImage(viewer.image_selected, 0, 0,myImage.width,myImage.height);
      
    //   var image,scale_absolute,real_height,real_width;
    //   var width_fix = Config.stamp.width;
    //   scale_absolute = width_fix/viewer.image_selected.width;
    //     real_height = viewer.image_selected.height*scale_absolute;
    //     real_width = viewer.image_selected.width*scale_absolute;
    //     image = new fabric.Image(myImage, {
    //       left: event.pointer.x - real_width/2, 
    //       top: event.pointer.y   - real_height/2 ,
    //       name: 'stamp',
    //       annotation : viewer.current_anotation,
    //       id : random_string(10)
    //     });
    //     image.set({
    //       scaleY: scale_absolute ,
    //       scaleX: scale_absolute 
    //     });
    // }
    // else{
    //   real_height = parseFloat( window.getComputedStyle(viewer.image_selected).getPropertyValue('width'))*viewer.currentScaleValue;
    //   real_width = parseFloat( window.getComputedStyle(viewer.image_selected).getPropertyValue('height'))*viewer.currentScaleValue;
    //   if(!real_width || !real_height){
    //     real_height = viewer.image_selected.height;
    //     real_width = viewer.image_selected.width;
    //   }
     
    //   const myImage = document.createElement("canvas");
    //   myImage.width = real_height;
    //   myImage.height = real_width;
    //   const ctx = myImage.getContext("2d");
    //   ctx.drawImage(viewer.image_selected, 0, 0,myImage.width,myImage.height);
      
     
    //     image = new fabric.Image(myImage, {
    //       left: event.pointer.x - real_width/2, 
    //       top: event.pointer.y   - real_height/2 ,
    //       name: 'stamp',
    //       annotation : viewer.current_anotation,
    //       id : random_string(10)
    //     });
    //     image.set({
    //       scaleY: viewer.currentScaleValue ,
    //       scaleX: viewer.currentScaleValue 
    //     });
    // }
    
    const myImage = document.createElement("canvas");
    myImage.width = viewer.image_selected.width * viewer.currentScaleValue;
    myImage.height = viewer.image_selected.height* viewer.currentScaleValue;
    const ctx = myImage.getContext("2d");
    ctx.drawImage(viewer.image_selected, 0, 0,myImage.width,myImage.height);
    
    var image,scale_absolute,real_height,real_width;
    var width_fix = Config.stamp.width;
    scale_absolute = width_fix/viewer.image_selected.width;      
      if(!viewer.image_selected.className.includes('canvas_signal')){
        scale_absolute = viewer.currentScaleValue/3;
      }

      real_height = viewer.image_selected.height*scale_absolute;
      real_width = viewer.image_selected.width*scale_absolute;
      image = new fabric.Image(myImage, {
        left: event.pointer.x - real_width/2, 
        top: event.pointer.y   - real_height/2 ,
        name: 'stamp',
        annotation : viewer.current_anotation,
        id : random_string(10)
      });
      image.set({
        scaleY: scale_absolute ,
        scaleX: scale_absolute 
      });
    fcanvas.add(image);
    fcanvas.renderAll();
    viewer.addHistory(new PdfHistory(fcanvas.pageNum,viewer.current_anotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,image,null));
   }

   function startAddingMark(event,fcanvas,viewer){  
    const myImage = document.createElement("canvas");
    myImage.width = viewer.image_selected.width * viewer.currentScaleValue;
    myImage.height = viewer.image_selected.height* viewer.currentScaleValue;
    const ctx = myImage.getContext("2d");
    ctx.drawImage(viewer.image_selected, 0, 0,myImage.width,myImage.height);
    
    var image,width_fix,height_fix,opacity,name;

    if(viewer.current_anotation==ANOTATION.ADD_XMARK){
      width_fix = Config.form.xmark.width;
      height_fix = Config.form.xmark.height;
      opacity = Config.form.xmark.opacity/100;
      name = 'cross';
    }
    if(viewer.current_anotation==ANOTATION.ADD_CHECKMARK){
      width_fix = Config.form.checkmark.width;
      height_fix = Config.form.checkmark.height;
      opacity = Config.form.checkmark.opacity/100;
      name = 'check';
    }
    if(viewer.current_anotation==ANOTATION.ADD_DOT){
      width_fix = Config.form.dot.width;
      height_fix = Config.form.dot.height;
      opacity = Config.form.dot.opacity/100;
      name = 'circle';
    }

    image = new fabric.Image(myImage, {
      left: event.pointer.x - width_fix/2, 
      top: event.pointer.y   - height_fix/2 ,
      annotation : viewer.current_anotation,
      id: random_string(10),
      name: name,
      // hasControls: false
      // hasBorders: false
    });
    image.set({
      scaleX: parseFloat(width_fix)/parseFloat(viewer.image_selected.width ),
      scaleY: parseFloat(height_fix)/parseFloat(viewer.image_selected.height),
      opacity: opacity,
      borderColor: '#0282e5',
      cornerColor: 'white',
      cornerStrokeColor: '#0282e5',
      cornerSize: 6,
      transparentCorners: false
    });

    fcanvas.add(image);
    fcanvas.renderAll();    
    viewer.addHistory(new PdfHistory(viewer.currentPageNumber,viewer.current_anotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,image,null)); 
  
  }



  function hideTools(){
    let children = document.querySelectorAll(".toolbars  .dropdown-toolbar");
    children.forEach(child => {
        child.style.display = 'none';
    })  
  }

  function updateModifications(savehistory,canvas) {
    if (savehistory === true) {
        var myjson = JSON.stringify(canvas);
        canvas.state.push(myjson);
    }
}
function setSelectableAll(canvas,status){  
  canvas.getObjects().forEach(o => {
    if(o.disableSelect == true) {
       o.set({selectable: false})
      }
    else
    { o.set({selectable: status})}
   
  })
  // canvas.selection = status;
}
export function convertArray(arr,divide){
  var output = []
  if(arr){ 
    for (let index = 0; index < arr.length; index++) {
      output.push(arr[index]*divide);      
    }
  }
  return output;
}

function createPoint(left,top,index,radius,annotation) { 
  var leftCircle = left - radius;
  var topCircle = top - radius;
  var c = new fabric.Circle({
    left: leftCircle,
    top: topCircle,
    radius: radius,
    stroke: 'black',
    fill:'red',
    hasControls: false,
    hasBorders: false,
    name: 'point-'+ index,
    visible: false,
    annotation: annotation
  });
  c.startX = c.left;
  c.startY = c.top;
  c.element = index;  
  return c;
}