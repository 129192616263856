import {PDFViewerApplication } from '../pdf-editor/define/PDFViewerApplication';
$(document).on('turbo:render', function() {
	window.$(".modal").on("shown.bs.modal", function () {
		if ($(".modal-backdrop").length > 1) {
			$(".modal-backdrop").not(':first').remove();
		}
	})
 });

$(document).on('turbolinks:load', function() { 
	window.$('#modal_preview_file_pdf').on('hidden.bs.modal', function () {
		$(this).find('form').trigger('reset');
	})
	window.$('#modal_preview_file_pdf').on('shown.bs.modal', function () {
		var path_file = document.getElementById('path_file').value;
		var versionUpdate = (new Date()).getTime();  
    	var formUrl = path_file + "?v=" + versionUpdate; 
		setTimeout(function(){
			Show_file_pdf(formUrl);
		}, 100);
		// $("body").delegate('#btnFullScreen', 'click', function(ev) {
		// 	var isFullScreen = this.getAttribute('full-screen');
		// 	var el = document.querySelector('#content_modal_file_preview_pdf'); 
		// 	if(isFullScreen == "true"){
		// 		this.setAttribute("full-screen",false);
		// 		closeFullscreen();
		// 	}
		// 	else{
			   
		// 		el.webkitRequestFullscreen();
		// 		this.setAttribute("full-screen",true);
		// 	}
		   
		// })   
	})
	
	
	async function Show_file_pdf(url){
		await open(url);	
	}
	
	async function open(url){

		var viewComponent = getViewComponents();
		await PDFViewerApplication.initialize(null,viewComponent);
		await PDFViewerApplication.open(url);
	}
	
	function getViewComponents(){
		var viewContainer = document.getElementById('viewer');
		var thumnailContainer = document.getElementById('list-doc');
		return {
			toolbar:{
				firstpage:document.getElementById('btnFirstPage'),
				endpage:document.getElementById('btnEndPage'),
				previous: document.getElementById('btnPrevPage'),				
				next: document.getElementById('btnNextPage'),
				rotateleft: document.getElementById('btnRotateLeft'),
				rotateright: document.getElementById('btnRotateRight'),
				zoomIn: document.getElementById('btnZoomIn'),
				zoomOut: document.getElementById('btnZoomOut'),
				current_page: document.getElementById('current_page_value'),
				total_page: document.getElementById('total_page_value'),
				select_scale: document.getElementById('scaleSelect')
			},
			viewContainer:viewContainer,
			thumnailContainer:thumnailContainer
		}
	}
})