const Convert = {
    rgbToString: function(rgb){
        return 'rgb(' + rgb.r + ',' + rgb.g + ',' +  rgb.b +')';
    },

    rGBAToRGB: function(colorRgba){
        var rgb = colorRgba.replace(/^(rgb|rgba)\(/,'').replace(/\)$/,'').replace(/\s/g,'').split(',');
        var color = {r:parseInt(rgb[0]) ,g: parseInt(rgb[1]) ,b:  parseInt(rgb[2]) };
        return color;
    },

    hexToRgb: function(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
    },

    rgbToHex: function(color) {
        color = ""+ color;
        if (!color || color.indexOf("rgb") < 0) {
            return;
        }
      
        if (color.charAt(0) == "#") {
            return color;
        }
      
        var nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color),
            r = parseInt(nums[2], 10).toString(16),
            g = parseInt(nums[3], 10).toString(16),
            b = parseInt(nums[4], 10).toString(16);
      
        return "#"+ (
            (r.length == 1 ? "0"+ r : r) +
            (g.length == 1 ? "0"+ g : g) +
            (b.length == 1 ? "0"+ b : b)
        );
    },
    toRGB(color){
        color = ""+ color;
        if (!color || color.indexOf("rgba") >=0) {
            return this.rGBAToRGB(color);
        }
        if (!color || color.charAt(0) == "#") {
            return this.hexToRgb(color);
        }
        return this.hexToRgb(this.rgbToHex(color));
    },
    
    toRGBString(color){
        color = ""+ color;
        if (!color || color.indexOf("rgba") >=0) {
            return this.rgbToString(this.rGBAToRGB(color));
        }
        if (!color || color.charAt(0) == "#") {
            return this.rgbToString(this.hexToRgb(color));
        }
        if (!color || color.charAt(0) == "rgb(") {
            return this.rgbToString(color);
        }
    },
    RGBvalues(rgb) {

        var _hex2dec = function(v) {
            return parseInt(v, 16)
        };
      
        var _splitHEX = function(hex) {
            var c;
            if (hex.length === 4) {
                c = (hex.replace('#','')).split('');
                return {
                    r: _hex2dec((c[0] + c[0])),
                    g: _hex2dec((c[1] + c[1])),
                    b: _hex2dec((c[2] + c[2]))
                };
            } else {
                 return {
                    r: _hex2dec(hex.slice(1,3)),
                    g: _hex2dec(hex.slice(3,5)),
                    b: _hex2dec(hex.slice(5))
                };
            }
        };
      
        var _splitRGB = function(rgb) {
            var c = (rgb.slice(rgb.indexOf('(')+1, rgb.indexOf(')'))).split(',');
            var flag = false, obj;
            c = c.map(function(n,i) {
                return (i !== 3) ? parseInt(n, 10) : flag = true, parseFloat(n);
            });
            obj = {
                r: c[0],
                g: c[1],
                b: c[2]
            };
            if (flag) obj.a = c[3];
            return obj;
        };
      
        var color = function(col) {
            var slc = col.slice(0,1);
            if (slc === '#') {
                return _splitHEX(col);
            } else if (slc.toLowerCase() === 'r') {
                return _splitRGB(col);
            } else {
                console.log('!Ooops! RGBvalues.color('+col+') : HEX, RGB, or RGBa strings only');
            }
        };
      
        return {
            color: color
        };
      }
}
export {Convert};