import 'bootstrap-datepicker';
$.fn.datepicker.dates['vi'] = {
    days: ["Chủ nhật", "Thứ 2", "Thứ 2", "Thứ 2", "Thứ 2", "Thứ 2", "Thứ 7"],
    daysShort: ["CN", "T.2", "T.3", "T.4", "T.5", "T.6", "T.7"],
    daysMin: ["CN", "T.2", "T.3", "T.4", "T.5", "T.6", "T.7"],
    months: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
    monthsShort: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
    today: "Hôm nay",
    clear: "Xóa",
    format: "dd/MM/yyyy",
    titleFormat: "MM, yyyy", /* Leverages same syntax as 'format' */
    weekStart: 0
};
$.fn.datepicker.dates['en'] = {
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    today: "Today",
    clear: "Clear",
    format: "mm/dd/yyyy",
    titleFormat: "MM, yyyy", /* Leverages same syntax as 'format' */
    weekStart: 0
};
$.fn.datepicker.dates['jp'] = {
    days: ['日曜日','月曜日','火曜日','水曜日','木曜日','金曜日','土曜日'],
    daysShort: ['日','月','火','水','木','金','土'],
    daysMin: ['日','月','火','水','木','金','土'],
    months: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
    monthsShort: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
    today: "今日",
    clear: "Clear",
    format: "yyyy年MMdd日火曜日",
    titleFormat: "yyyy年MM", /* Leverages same syntax as 'format' */
    weekStart: 0
}
// $(document).on('turbolinks:load', function () {
//     $('.datepicker').datepicker({
//         language: 'jp',
//         autoclose: true,
//         sideBySide: true,
//         // icons: {
//         //     up: "fa fa-chevron-circle-up",
//         //     down: "fa fa-chevron-circle-down",
//         //     next: 'fa fa-chevron-circle-right',
//         //     previous: 'fa fa-chevron-circle-left'
//         // }
//     })
//     .on('changeDate', function(e) {
//         console.log('do task');
//     });
//     var today = new Date();
//     $('.datepicker').datepicker('setDate', today);
// })
