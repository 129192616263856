$(document).on('turbolinks:load', function() {
    format_datepicker('#supplier_end_datepicker', '.supplier_end_datepicker_value');
    format_datepicker('#supplier_cancellation_datepicker', '.supplier_cancellation_datepicker_value');
    // $('#text_supplier_end_date').attr('readonly', true);

    $('body').delegate('#supplier_payment_method_dropdown_menu_select_type li.item-container', 'click', function(e) {
        var labelText = $(this).find('span').text();
        var checkboxValue = $(this).data('item-id');
        $(this).closest('.custom-dropdown').find('#supplier_payment_method_text').text(labelText);
        $('#payment_method_id').val(checkboxValue);
    });
    
    $("body").delegate('#supplier_payment_method_dropdown_menu_select_type .icon-container button', 'click', function(e) {
        e.stopPropagation();
    });
    
    $("body").delegate('#add_new_payment_method', 'click', function(e) {
        e.stopPropagation();
        open_extension_modal('', '/new_payment_method_supplier');
    });
    
    $("body").delegate('.supplier-payment-method-delete','click', function(e) {
        e.stopPropagation();
        var selectedValues = $(this).data('item-id');
        if (selectedValues.length === 0) {
            return;
        } else {
            open_extension_modal(selectedValues, '/confirm_to_remove_payment_method_supplier');
        }
    });
    
    $("body").delegate('.supplier-payment-method-edit','click', function(e) {
        e.stopPropagation();
        var selectedValues = $(this).data('item-id');
        if (selectedValues.length === 0) {
            return;
        } else {
            open_extension_modal(selectedValues, '/edit_payment_method_supplier');
        }
    });


    $("#supplier_postcode").on("input", function() {
        var inputValue = $(this).val();
        let sanitizedValue = inputValue.replace(/[^0-9-]/g, '').replace(/-+/g, '-');
        let parts = sanitizedValue.split('-');
        
        if (parts.length === 2) {
          parts[0] = parts[0].substring(0, 3);
          parts[1] = parts[1].substring(0, 4);
          sanitizedValue = parts.join('-');
        } else {
            sanitizedValue = sanitizedValue.substring(0, 3);
        }
      
        $(this).val(sanitizedValue);
        var postcode = sanitizedValue;
        
        $.ajax({
            url: "/check_postcode_supplier?format=js",
            method: "GET",
            data: { postcode: postcode },
            dataType: "script"
        });
      });

      var paymentMethodTextElement = $('#supplier_payment_method_text');
      var paymentMethodItemName = paymentMethodTextElement.data('item-name');
      
      if (paymentMethodItemName) {
          paymentMethodTextElement.text(paymentMethodItemName);
      }
      

    $("#supplier_phone").on("input", function() {
        phone_fax_format(this);
    });

    $("#supplier_fax").on("input", function() {
        phone_fax_format(this);
    });

    
    
});

function phone_fax_format(element){
    // const phoneInput = $(element).val();
    // const phonePattern = /^(\d{2,3})\-?(\d{3,4})\-?(\d{4})$/;
    // const noAlpha = phoneInput.replace(/[a-zA-Z]/g, '');
    // $(element).val(noAlpha);

    // if (!phonePattern.test(phoneInput)) {
    //   $(element).addClass("is-invalid");
    //   if (!$(element).next(".show-message").length) {
    //     $(element).after('<div class="text-danger show-message">Please enter a valid phone number in the format 12-345-6789 or 123-4567-8901</div>');
    //   }
    //   // setTimeout(() => {
    //   //   $(element).removeClass("is-invalid");
    //   //   $(element).next(".show-message").remove();
    //   // }, 5000);
    // } else {
    //   $(element).removeClass("is-invalid");
    //   $(element).next(".show-message").remove();
    // }
}
