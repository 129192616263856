import { move_top_item } from './commons';
import { move_up_item } from './commons';
import { move_down_item } from './commons';
import { move_bottom_item } from './commons';
import { call_sortable_list } from './commons';
import { selected_item } from './commons';
import { update_index_item } from './commons';

$(document).on('turbolinks:load', function() {

  const myModal = document.getElementById('modal-reorder-locations');
  if (myModal) {
    myModal.addEventListener('shown.bs.modal', function () {
      call_sortable_list("#list_sort_location");
    });
  }

  $("body").delegate('#btnMoveTopSortLocation', 'click', function () {
    move_top_item("#list_sort_location", '.item-location');
  });

  $("body").delegate('#btnMoveUpSortLocation', 'click', function () {
    move_up_item("#list_sort_location");
  });

  $("body").delegate('#btnMoveDownSortLocation', 'click', function () {
    move_down_item("#list_sort_location")
  });

  $("body").delegate('#btnMoveBottomSortLocation', 'click', function () { 
    move_bottom_item("#list_sort_location", '.item-location');
  });

  $("body").delegate('.item-location', 'click', function () {
    selected_item('.item-location', this);
  });

  $("body").delegate('#btnUpdateIndexLocations', 'click', function () {
    update_index_item("#list_sort_location", "/update_index_locations");
  });
  

  const modal_confirm_remove_location = document.getElementById('modal-confirm-remove-location');
  if (modal_confirm_remove_location) {
    modal_confirm_remove_location.addEventListener('shown.bs.modal', function () {
      $("#modal-edit-location").addClass("modal-blur");
    });
    modal_confirm_remove_location.addEventListener('hidden.bs.modal', function () {
      $("#modal-edit-location").removeClass("modal-blur");
    });
  }

  const modal_confirm_change_location = document.getElementById('modal-confirm-change-location');
  if (modal_confirm_change_location) {
    modal_confirm_change_location.addEventListener('shown.bs.modal', function () {
      $("#modal-edit-location").addClass("modal-blur");
    });
    modal_confirm_change_location.addEventListener('hidden.bs.modal', function () {
      $("#modal-edit-location").removeClass("modal-blur");
    });
  }

});
