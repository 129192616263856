import { move_top_item } from './commons';
import { move_up_item } from './commons';
import { move_down_item } from './commons';
import { move_bottom_item } from './commons';
import { call_sortable_list } from './commons';
import { selected_item } from './commons';
import { update_index_item } from './commons';

$(document).on('turbolinks:load', function() {

    const myModal = document.getElementById('modal-reorder-units');
    if (myModal) {
      myModal.addEventListener('shown.bs.modal', function () {
        call_sortable_list("#list_sort_units");
      });
    }
    
    $("body").delegate('#btnMoveTopSortUnit', 'click', function () {
      move_top_item("#list_sort_units", '.item-unit');
    });
    
    $("body").delegate('#btnMoveUpSortUnit', 'click', function () {
      move_up_item("#list_sort_units");
    });
    
    $("body").delegate('#btnMoveDownSortUnit', 'click', function () {
      move_down_item("#list_sort_units")
    });
    
    $("body").delegate('#btnMoveBottomSortUnit', 'click', function () { 
      move_bottom_item("#list_sort_units", '.item-unit');
    });
    
    $("body").delegate('.item-unit', 'click', function () {
      selected_item('.item-unit', this);
    });
    
    $("body").delegate('#btnUpdateIndexUnits', 'click', function () {
      update_index_item("#list_sort_units", "/update_index_units");
    });
    
    const modal_confirm_remove_unit = document.getElementById('modal-confirm-remove-unit');
    if (modal_confirm_remove_unit) {
      modal_confirm_remove_unit.addEventListener('shown.bs.modal', function () {
        $("#modal-edit-unit").addClass("modal-blur");
      });
      modal_confirm_remove_unit.addEventListener('hidden.bs.modal', function () {
        $("#modal-edit-unit").removeClass("modal-blur");
      });
    }
    
    const modal_confirm_change_unit = document.getElementById('modal-confirm-change-unit');
    if (modal_confirm_change_unit) {
      modal_confirm_change_unit.addEventListener('shown.bs.modal', function () {
        $("#modal-edit-unit").addClass("modal-blur");
      });
      modal_confirm_change_unit.addEventListener('hidden.bs.modal', function () {
        $("#modal-edit-unit").removeClass("modal-blur");
      });
    }
      
});
