$(document).on('turbolinks:load', function() {
    handle_onsite_status_table();
    handleOnsiteModal2();
    handleOnsiteModal1();
    handleNewOnsiteStatusModal();
    handleEditOnsiteStatusModal();
    const modal_confirm_remove_onsite = document.getElementById('modal-confirm-remove-onsite');
    if (modal_confirm_remove_onsite) {
        modal_confirm_remove_onsite.addEventListener('shown.bs.modal', function () {
            $("#modal-edit-onsite").addClass("modal-blur");
        });
        modal_confirm_remove_onsite.addEventListener('hidden.bs.modal', function () {
            $("#modal-edit-onsite").removeClass("modal-blur");
        });
    }

    $("body").delegate("#open_onsite_filter_modal", "click", function (event) {
		window.$('#modal_search_onsite').modal('show');
	});
    
    
});

window.handleOnsiteModal1 = function() {
    window.$('#modal-add-onsite').on('show.bs.modal', function (e) {
        format_datepicker('#onsite_completion_datepicker', '.onsite_completion_datepicker_value');
        $("#customer_name_select_field_add").select2({
            dropdownParent: $('#modal-add-onsite'),
        });
        bindItemDropdownEvents('#onsite_status_modal_dropdown_menu_select_type', 
        '#onsite_status_modal_text', '#onsite_status_modal_id', '#add_new_onsite_status', 
        '.onsite-status-delete', '.onsite-status-edit', 
        '/confirm_to_remove_onsite_status_modal', '/edit_onsite_status_modal', '/new_onsite_status_modal');
        $('.site-code-input').on('input', function() {
            var value = this.value.replace(/[^0-9]/g, '');
            $(this).val(value);
        });
    });

};

window.handleOnsiteModal2 = function() {
    window.$('#modal-edit-onsite').on('show.bs.modal', function (e) {
        format_datepicker('#edit_onsite_completion_datepicker', '.edit_onsite_completion_datepicker_value');
        $("#customer_name_select_field_edit").select2({
            dropdownParent: $('#modal-edit-onsite'),
        });

        bindItemDropdownEvents('#onsite_status_modal_dropdown_menu_select_type', 
        '#onsite_status_modal_text', '#onsite_status_modal_id', '#add_new_onsite_status', 
        '.onsite-status-delete', '.onsite-status-edit', 
        '/confirm_to_remove_onsite_status_modal', '/edit_onsite_status_modal', '/new_onsite_status_modal');

        var onsite_status_text = $('#onsite_status_modal_text');
        var onsite_status_name = onsite_status_text.data('item-name');
        
        if (onsite_status_name) {
            onsite_status_text.text(onsite_status_name);
        }

        $('.site-code-input').on('input', function() {
            var value = this.value.replace(/[^0-9]/g, '').replace(/^0+/,'');
            $(this).val(value);
        });
    });

};


window.handle_onsite_status_table = function(){
	$('.onsite-status-text').each(function() {
		var onsite_status_text_class = $(this);
		var onsite_status_name_class = onsite_status_text_class.data('item-name');
	
		if (onsite_status_name_class) {
			onsite_status_text_class.text(onsite_status_name_class);
		}
	});

    bindItemDropdownEvents('.onsite-status-dropdown_menu_select_type', 
    '.onsite-status-text', '#onsite_status_id', '#add_new_onsite_status', 
    '.onsite-status-delete', '.onsite-status-edit', 
    '/confirm_to_remove_onsite_status', '/edit_onsite_status', '/new_onsite_status');

	$('body').delegate('.onsite-status-dropdown-menu-select-type li.item-container', 'click', function(e) {
		var labelText = $(this).find('span').text();
		var checkboxValue = $(this).data('item-id');
		$(this).closest('.custom-dropdown').find(".onsite-status-text").text(labelText);
		$(this).closest(".status-select").find(".onsite-status-id").val(checkboxValue);
		var onsite_id = $(this).closest("tr").data("item-id");
		$.ajax({
			url: '/update_onsite_status_table',
			method: 'GET',
			data: {
				option: checkboxValue,
				onsite_id: onsite_id 
			},
			dataType: "script",
			success: function (response) {
			},
			error: function (error) {
			}
		  });
	});
};

window.handleNewOnsiteStatusModal = function() {
	window.$('#modal-add-onsite').on('show.bs.modal', function (e) {
    show_modal_blur_other_modal("modal-add-onsite-status", "#modal-add-onsite");
    show_modal_blur_other_modal("modal-edit-onsite-status", "#modal-add-onsite");
    show_modal_blur_other_modal("modal-delete-onsite-status", "#modal-add-onsite");
  });
};
window.handleEditOnsiteStatusModal = function() {
	window.$('#modal-edit-onsite').on('show.bs.modal', function (e) {
    show_modal_blur_other_modal("modal-add-onsite-status", "#modal-edit-onsite");
    show_modal_blur_other_modal("modal-edit-onsite-status", "#modal-edit-onsite");
    show_modal_blur_other_modal("modal-delete-onsite-status", "#modal-edit-onsite");
  
  });
};
