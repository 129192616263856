const app = {}
Object.defineProperties(app, {
    pageStart: {value:0},
    scaleStart: {value: 1},
    verticalScrollTop: {value: 0},
    horizontalScrollPosition: {value: 0},
    currentScale:{value: 1},
    url:{value: ""},
    data: {value: null}
})


const config_addtext = {}
Object.defineProperties(config_addtext, {
    fontSize:{
        value:20,
        writable: true
    },
    fontFamily: {
        value: "Times New Roman",
        writable: true
    },
    value:{
        value: "Text"
    },
   
    fill:{
        value: "#000000",
        writable: true
    },
    fontWeight:
    {
        value:"normal",
        writable: true
    } ,
    boldText:
    {
        value:false,
        writable: true
    } ,
    italicText:
    {
        value:false,
        writable: true
    }
})


const config_highlight = {}
Object.defineProperties(config_highlight, {
    color:{
        value:"rgb(255, 213, 0)",
        writable: true
    }   
})

const config_strokeout = {}
Object.defineProperties(config_strokeout, {
    color:{
        value:"rgb(255, 213, 0)",
        writable: true
    }   
})

const config_underline = {}
Object.defineProperties(config_underline, {
    color:{
        value:"rgb(255, 213, 0)",
        writable: true
    }   
})

const config_Text= {}
Object.defineProperties(config_Text, {
    addtext:{
        value: config_addtext,
        writable: true
    },
    highlight:{
        value: config_highlight,
        writable: true
    }  ,
    strokeout:{
        value: config_strokeout,
        writable: true
    }   ,
    underline:{
        value: config_underline,
        writable: true
    }      
})

const config_Stamp = {}
Object.defineProperties(config_Stamp, {
    width: {value:100},
    height: {value: 100}
})

const config_link = {}
Object.defineProperties(config_link, {
    url:{
        value: 'https://example.com',
        writable: true
    }  ,
    mail:{
        value: '',
        writable: true
    }  ,
    phone:{
        value: '',
        writable: true
    }  ,
    page:{
        value: 2,
        writable: true
    }  ,
    radio:{
        value: 0,
        writable: true
    }  
})
const config_pencil = {}
Object.defineProperties(config_pencil, {
    color:{
        value: 'rgb(255, 213, 0)',
        writable: true
    }  ,
    opacity:{
        value: 0.3,
        writable: true
    }  ,
    strokeWidth:{
        value: 12,
        writable: true
    },
    dash:{
        value: null,
        writable:true
    },
})
const config_freedraw = {}
Object.defineProperties(config_freedraw, {
    color:{
        value: 'rgb(0, 0, 0)',
        writable: true
    }  ,
    opacity:{
        value: 1,
        writable: true
    }  ,
    strokeWidth:{
        value: 1,
        writable: true
    },
    dash:{
        value: null,
        writable:true
    },
})
// form
const config_xmark = {}
Object.defineProperties(config_xmark, {
    width:{
        value: 20,
        writable: true
    }  ,
    height:{
        value: 20,
        writable: true
    }  ,
    origin_width:{
        value: 100,
        writable: true
    }  ,
    origin_height:{
        value: 100,
        writable: true
    }  ,
    opacity:{
        value: 100,
        writable: true
    }
})
const config_checkmark = {}
Object.defineProperties(config_checkmark, {
    width:{
        value: 20,
        writable: true
    }  ,
    height:{
        value: 20,
        writable: true
    }  ,
    origin_width:{ //origin_width = width
        value: 100,
        writable: true
    }  ,
    origin_height:{ // origin_height = height
        value: 100,
        writable: true
    }  ,
    opacity:{
        value: 100,
        writable: true
    }
})

const config_dot = {}
Object.defineProperties(config_dot, {
    width:{
        value: 20,
        writable: true
    }  ,
    height:{
        value: 20,
        writable: true
    }  ,
    origin_width:{
        value: 100,
        writable: true
    }  ,
    origin_height:{
        value: 100,
        writable: true
    }  ,
    opacity:{
        value: 100,
        writable: true
    }
})

const config_textfield = {}
Object.defineProperties(config_textfield, {
    width:{
        value: 200,
        writable: true
    },
    height:{
        value: 40,
        writable: true
    },
    color:{
        value: '#000000',
        writable: true
    },
    borderColor:{
        value: '#000000',
        writable: true
    },
    borderWidth: {
        value: 1,
        writable: true
    }
})
const config_textarea = {}
Object.defineProperties(config_textarea, {
    width:{
        value: 200,
        writable: true
    }  ,
    height:{
        value: 50,
        writable: true
    }  ,
    color:{
        value: '#000000',
        writable: true
    },
    borderColor:{
        value: '#000000',
        writable: true
    },
    borderWidth: {
        value: 1,
        writable: true
    }
})
const config_radio = {}
Object.defineProperties(config_radio, {
    width:{
        value: 40,
        writable: true
    }  ,
    height:{
        value: 40,
        writable: true
    }  ,
    backGroundColor:{
        value: '',
        writable: true
    },
    color:{
        value: '#000000',
        writable: true
    },
    borderColor:{
        value: '#000000',
        writable: true
    },
    borderWidth: {
        value: 1,
        writable: true
    }
})

const config_checkbox = {}
Object.defineProperties(config_checkbox, {
    width:{
        value: 40,
        writable: true
    }  ,
    height:{
        value: 40,
        writable: true
    }  ,
    backGroundColor:{
        value: '',
        writable: true
    },
    color:{
        value: '#000000',
        writable: true
    },
    borderColor:{
        value: '#000000',
        writable: true
    },
    borderWidth: {
        value: 1,
        writable: true
    }
})
const config_dropdown = {}
Object.defineProperties(config_dropdown, {
    width:{
        value: 200,
        writable: true
    }  ,
    height:{
        value: 40,
        writable: true
    }  ,
    backGroundColor:{
        value: '',
        writable: true
    },
    color:{
        value: '#000000',
        writable: true
    },
    borderColor:{
        value: '#000000',
        writable: true
    },
    borderWidth: {
        value: 1,
        writable: true
    }
})

const config_form = {}
Object.defineProperties(config_form, {
    xmark:{
        value: config_xmark
    },
    checkmark:{
        value: config_checkmark
    },
    dot:{
        value: config_dot
    },
    textfield:{
        value: config_textfield
    },
    textarea:{
        value: config_textarea
    },
    radio:{
        value: config_radio
    },
    checkbox:{
        value: config_checkbox
    },
    dropdown:{
        value: config_dropdown
    },
})


const config_line = {}
Object.defineProperties(config_line, {
    dash:{
        value: null,
        writable:true
    },
    strokeWidth:{
        value: 4,
        writable: true
    }  ,
    backGroundColor:{
        value: "#000000",
        writable: true
    }  ,
    opacity:{
        value: 100,
        writable: true
    }
})

const config_arrow = {}
Object.defineProperties(config_arrow, {
    dash:{
        value: null,
        writable:true
    },
    size:{
        value: 4,
        writable: true
    },
    line_width:{
        value: 1,
        writable: true
    },
    style:{
        value: 1, //2:dashed
        writable: true
    },
    position_arrow:{
        value: 1, // 1 right, 2: left and right
        writable: true
    },
    color:{
        value: "#000000",
        writable: true
    }  ,
    opacity:{
        value: 1,
        writable: true
    }
})
const config_ellipse = {}

Object.defineProperties(config_ellipse, {    
    dash:{
        value: null,
        writable:true
    },
    stroke:{
        value: "#000000",
        writable: true
    }  ,
    strokeWidth:{
        value: 1,
        writable: true
    } ,
    opacity:{
        value: 100,
        writable: true
    }
})
const config_rectangle = {}
Object.defineProperties(config_rectangle, {
    dash:{
        value: null,
        writable:true
    },
    stroke:{
        value: "#000000",
        writable: true
    }  ,
    strokeWidth:{
        value: 1,
        writable: true
    } ,
    opacity:{
        value: 100,
        writable: true
    }
})

const config_shapes = {}
Object.defineProperties(config_shapes, {
    line:{
        value: config_line
    },
    arrow:{
        value: config_arrow
    },
    ellipse:{
        value: config_ellipse
    },
    rectangle:{
        value: config_rectangle
    }
})


const Config = {app:    app, text: config_Text,stamp: config_Stamp,link: config_link,pencil:config_pencil,
    form:config_form,shape:config_shapes,freedraw:config_freedraw}
export {Config};