import { move_top_item } from './commons';
import { move_up_item } from './commons';
import { move_down_item } from './commons';
import { move_bottom_item } from './commons';
import { call_sortable_list } from './commons';
import { selected_item } from './commons';
import { update_index_item } from './commons';

$(document).on('turbolinks:load', function() {

    const myModal = document.getElementById('modal-reorder-payment-methods');
    if (myModal) {
      myModal.addEventListener('shown.bs.modal', function () {
        call_sortable_list("#list_sort_payment_method");
      });
    }
    
    $("body").delegate('#btnMoveTopSortPaymentMethod', 'click', function () {
      move_top_item("#list_sort_payment_method", '.item-payment-method');
    });
    
    $("body").delegate('#btnMoveUpSortPaymentMethod', 'click', function () {
      move_up_item("#list_sort_payment_method");
    });
    
    $("body").delegate('#btnMoveDownSortPaymentMethod', 'click', function () {
      move_down_item("#list_sort_payment_method")
    });
    
    $("body").delegate('#btnMoveBottomSortPaymentMethod', 'click', function () {
      move_bottom_item("#list_sort_payment_method", '.item-payment-method');
    });
    
    $("body").delegate('.item-payment-method', 'click', function () {
      selected_item('.item-payment-method', this);
    });
    
    $("body").delegate('#btnUpdateIndexPaymentMethods', 'click', function () {
      update_index_item("#list_sort_payment_method", "/update_index_payment_methods");
    });
    
    const modal_confirm_remove_payment_method = document.getElementById('modal-confirm-remove-payment-method');
    if (modal_confirm_remove_payment_method) {
      modal_confirm_remove_payment_method.addEventListener('shown.bs.modal', function () {
        $("#modal-edit-payment-method").addClass("modal-blur");
      });
      modal_confirm_remove_payment_method.addEventListener('hidden.bs.modal', function () {
        $("#modal-edit-payment-method").removeClass("modal-blur");
      });
    }
    
    const modal_confirm_change_payment_method = document.getElementById('modal-confirm-change-payment-method');
    if (modal_confirm_change_payment_method) {
      modal_confirm_change_payment_method.addEventListener('shown.bs.modal', function () {
        $("#modal-edit-payment-method").addClass("modal-blur");
      });
      modal_confirm_change_payment_method.addEventListener('hidden.bs.modal', function () {
        $("#modal-edit-payment-method").removeClass("modal-blur");
      });
    }
    


});
